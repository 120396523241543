import { FC, ReactNode, useEffect } from 'react';
import { Breadcrumb, Container } from 'reactstrap';
import ButtonContainer from './ButtonContainer';
import { RootState } from '../Store/store';
import { useSelector } from 'react-redux';
import BreadcrumbTrail, { ActiveBreadcrumbEntry, BreadcrumbEntry } from './Navigation/Breadcrumb';
import Tabs from './Navigation/Tabs';

interface PageProps {
    title?: string;
    breadcrumbTrail?: (BreadcrumbEntry|ActiveBreadcrumbEntry)[];
    buttons?: ReactNode[];
    children: ReactNode;
    loading?: boolean;
    refreshing?: boolean;
    wide?: boolean;
}

const Page : FC<PageProps> = (props) => {
    const canUpdate = useSelector<RootState>(state => state.defaultModel.canUpdate)

    useEffect(() => {
        if (typeof document !== undefined) {
            document.querySelectorAll<HTMLAnchorElement>('.signin').forEach(el => {
                const url = new URL(el.href, window.location.href);
                url.searchParams.set('returnUrl', window.location.href);
                el.href = url.toString();
            });
        }
    }, [ ]);
    
    useEffect(() => {
        if (typeof document !== undefined) {
            document.title = `${props.title ? `${props.title} | ` : ''}Dataset Library`
        }
    }, [ props.title ]);

    return (
        <>
            <Tabs />
            {props.breadcrumbTrail && !props.loading ? <BreadcrumbTrail isLoading={props.loading} trail={props.breadcrumbTrail} /> : null }
            <Container fluid={props.wide} className={`pb-5${(props.refreshing&&!props.loading)?' refreshing':''}`}>
                {props.buttons && canUpdate ? <ButtonContainer children={props.buttons} /> : null }
                {props.loading ? <div className="loading" /> : props.children}
            </Container>
        </>
    )
}

export default Page;