import {
    DetailedTableFormRow,
    DetailedTableFormRowProps,
} from './DetailedTable';
import { SingleValue } from 'react-select';
import { useCallback } from 'react';
import DropdownSelector, { DropdownSelectorProps } from './DropdownSelector';
import { Paths } from './UtilityTypes';

export type HookedDropdownSelectorFieldProps<T extends Record<string, any>, TKey extends Paths<T>, TOption, TListing> =
    Omit<DetailedTableFormRowProps<T, TKey>, 'inputField'|'onChange'>
    &Omit<DropdownSelectorProps<TOption, TListing>, 'onChange'|'onBlur'>&({
    onChange?: (item: TOption) => void;
    onAnyChange?: (item: SingleValue<TOption>) => void; 
});


const HookedDropdownSelectorField = <T extends Record<string, any>, TKey extends Paths<T>, TOption, TListing>(
    { 
        placeholder, isClearable, hookResult, 
        formatLabel, isDisabled = false,
        getDataFromListing, onChange = () => {},
        onAnyChange = () => {},
        ...props 
    }: HookedDropdownSelectorFieldProps<T, TKey, TOption, TListing>
) => {
    
    
    const onChangeHandler = useCallback((value: SingleValue<TOption>, onDone?: () =>void) => {
        if (value) {
            onChange(value); 
        }
        onAnyChange(value);
        if (!props.alwaysEditing) {
            onDone?.();   
        }
    }, [ onChange, props.alwaysEditing ]);
    
    
    
    return (
        <DetailedTableFormRow
            {...props}            
            inputField={inputProps => 
                <DropdownSelector
                    isDisabled={isDisabled}
                    isSelectedOption={props.isSelectedOption}
                    onChange={val => onChangeHandler(val, inputProps.onDoneEditing)}
                    getDataFromListing={getDataFromListing}
                    hookResult={hookResult}
                    onBlur={inputProps.onDoneEditing}
                    formatLabel={formatLabel}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    error={!!props.error}                    
                />
            }
        />
    );

};

export default HookedDropdownSelectorField;