import { createApi } from '@reduxjs/toolkit/query/react'
import {
    Collection, CollectionAddModel,
    CollectionModel, CollectionUpdateModel
} from './Types';
import { AddResult, NewDetailsApiModel, UpdateResult } from '../Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const collectionApi = createApi({
    reducerPath: 'collectionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/collection/'
    }),
    tagTypes: ['Collection','List'],
    endpoints: (builder) => ({
        getAddPage: builder.query<NewDetailsApiModel<Collection, 'Source'|'SourceId'>, number|undefined>({
            query: (source?: number) => ({
                url: `add`,
                params: {
                    source
                }
            }),
            providesTags: [{ type: 'Collection', id: 'Add' }]
        }),
        getDetails: builder.query<CollectionModel, number>({
            query: (collection) => `details/${collection}`,
            providesTags: (result?: CollectionModel) => result ? [{ type: 'Collection', id: result.Item.Number }] : []
        }),
        updateDetails: builder.mutation<UpdateResult, CollectionUpdateModel>({
            query: (data) => ({
                url: `update`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, params) => result && result.success ? [{ type: 'Collection', id: params.Number }] : []
        }),
        add: builder.mutation<AddResult, CollectionAddModel>({
            query: (data) => ({
                url: 'add',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result) => result && result.success ? [{type: 'List', id: 'Collection' }] : []
        }),
        refreshCollection: builder.mutation<UpdateResult, number>({
            query: (data) => ({
                url: `refresh/${data}`,
                method: 'POST',
            }),
        })
    }),
});


export const {
    useGetDetailsQuery,
    useGetAddPageQuery,
    useUpdateDetailsMutation,
    useAddMutation,
    useRefreshCollectionMutation
} = collectionApi;

export default collectionApi;
