import { FC, useMemo } from 'react';
import Page from '../Common/Page';
import { useParams } from 'react-router';
import { useChangeDatasetMutation, useGetMetricTypeDetailsQuery } from '../Store/Indicators/indicatorApi';
import { Formik, Form } from 'formik';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import { ChangeDatasetFormModel } from '../Store/Indicators/Types';
import HookedDropdownSelectorField from '../Common/DetailsForm/HookedDropdownSelectorField';
import { useGetDatasetListQuery, useGetDatasetMeasuresQuery } from '../Store/Lists/listApi';
import { DatasetListing, DatasetListingEntry } from '../Store/Dataset/Types';
import { Measure } from '../Store/Measure/Types';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import SubmitButton from '../Common/SubmitButton';
import FormikDetailsForm from '../Common/DetailsForm/DetailsForm';
import { useNavigateIf, useNavigateOnError } from '../hooks';

const ChangeDataset: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading } = useGetMetricTypeDetailsQuery(Number(id));
    const changeDatasetModel = useMemo(() => {
        return {
            metricType: data?.Item,
        } as Partial<ChangeDatasetFormModel>;
    }, [data]);
    const [change] = useChangeDatasetMutation();
    useNavigateIf('/', !id || Number.isNaN(Number(id)) || Number(id) === 0, 'A metric type needs to be selected');
    useNavigateOnError('/', error, 'Error loading metric type');

    return (
        <Page wide loading={isLoading}
              title="Change dataset"
              breadcrumbTrail={[
                  { 
                      name: 'Home', 
                      url: '/' 
                  },
                  {
                      name: data?.Item?.Measure?.Dataset?.Collection?.Source?.Label ?? '',
                      url: `/Source/${data?.Item?.Measure?.Dataset?.Collection?.Source?.Number}`
                  },
                  {
                      name: data?.Item?.Measure?.Dataset?.Collection?.Label ?? '',
                      url: `/Collection/${data?.Item?.Measure?.Dataset?.Collection?.Number}`
                  },
                  {
                      name: data?.Item?.Measure?.Dataset?.Label ?? '',
                      url: `/Dataset/${data?.Item?.Measure?.Dataset?.Number}`
                  },
                  { name: data?.Item?.Measure?.Label ?? '', url: `/Measure/${data?.Item?.Measure?.Identifier}` },
                  { name: data?.Item?.Label ?? '', url: `/MetricType/Details/${data?.Item?.Number}` },
                  { name: 'Change dataset', active: true },
              ]}
        >
            <FormikDetailsForm hoverHelp={data?.HoverHelp} initialValues={changeDatasetModel} onSubmit={model => change(model as ChangeDatasetFormModel)} isNew={false}>
                {formProps => {
                    const measures = useGetDatasetMeasuresQuery({
                        datasetNumber: formProps.values.newDataset?.DatasetNumber??0,
                        inputPrecision: formProps.values.metricType?.Measure?.InputPrecision??0
                    }, {
                        skip: formProps.values.newDataset === undefined || formProps.values.metricType === undefined
                    });
                    
                    return (
                        <Form>
                            <DetailedTableHeader
                                label="Metric type change dataset"
                                hoverProps={{ id: 'metric-type-change-dataset-heading' }}
                            />
                            <Container>
                                <DetailedTableFormRow
                                    context={formProps}
                                    label="Metric type"
                                    name="metricType.Label"
                                    hoverProps={{ id: 'label-heading' }}
                                    editable={false}
                                />
                                <DetailedTableFormRow
                                    context={formProps}
                                    label="Dataset"
                                    name="metricType.Measure.Dataset.Label"
                                    hoverProps={{ id: 'dataset-label-heading' }}
                                    editable={false}
                                />
                                <HookedDropdownSelectorField<Partial<ChangeDatasetFormModel>, 'newDataset', DatasetListingEntry, DatasetListing>
                                    alwaysEditing
                                    hoverProps={{ id: 'new-dataset-heading' }}
                                    label="New dataset"
                                    context={formProps}
                                    hookResult={useGetDatasetListQuery()}
                                    getDataFromListing={listing => listing.Datasets}
                                    name="newDataset"
                                    formatLabel={entry => `${entry.DatasetLabel} (id: ${entry.DatasetNumber})`}
                                    isSelectedOption={opt => opt.DatasetId === formProps.values.newDataset?.DatasetId}
                                    placeholder="Select a new dataset"
                                    onChange={newEntry => formProps.setFieldValue('newDataset', newEntry)}
                                />
                                <HookedDropdownSelectorField<Partial<ChangeDatasetFormModel>, 'newMeasure', Measure, Measure[]>
                                    alwaysEditing
                                    hoverProps={{ id: 'new-measure-heading' }}
                                    label="New measure"
                                    context={formProps}
                                    hookResult={measures}                                    
                                    getDataFromListing={listing => listing}
                                    name="newMeasure"
                                    formatLabel={entry => entry.Label}
                                    isSelectedOption={opt => opt.Id === formProps.values.newMeasure?.Id}
                                    placeholder="-- Copy existing --"
                                    isClearable
                                    onAnyChange={newEntry => formProps.setFieldValue('newMeasure', newEntry)}
                                />
                            </Container>
                            <FormToolkit>
                                <SubmitButton formProps={formProps} />
                            </FormToolkit>
                        </Form>
                    )
                }}
            </FormikDetailsForm>
        </Page>
    );
}

export default ChangeDataset;
