import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery, useUpdateSourceMutation } from '../Store/Source/sourceApi';
import SourceEditForm from './SourceEditForm';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import SubmitButton from '../Common/SubmitButton';
import { Source, SourceUpdateModel } from '../Store/Source/Types';
import { toast } from 'react-toastify';
import { UploadButton } from '../Widgets/Buttons';


export const AddCollectionButton: FC<{ source: number }> = ({ source }) => (
    <Link className="btn btn-secondary" to={`/collection/add?source=${source}`}>
        Add collection
    </Link>
);

const Details: FC = () => {
    const { id } = useParams<'id'>();
    const nav = useNavigate();
    const { data, error, isLoading, isFetching } = useGetDetailsQuery(Number(id));
    const [
        updateSource
    ] = useUpdateSourceMutation();

    if (error) {
        toast.error('Failed to load source details');
        nav('/source');
    }

    const handleUpdate = async (source: Source) => {
        const id = toast.loading('Updating source');
        try {
            const result = await updateSource({
                Number: source.Number,
                Label: source.Label
            } as SourceUpdateModel);
            if (result.error) {
                toast.error('Failed to update');
            } else {
                toast.success('Updated source');
            }
        } catch (e) {
            toast.error('Failed to update');
        } finally {
            toast.done(id);
        }
    }

    return (
        <Page buttons={data ?
            [
                <UploadButton type="source" number={data.Item.Number}/>,
                <AddCollectionButton key="collection-button" source={Number(id)}/>,
            ] : []}
              title="Source details"
              loading={isLoading}
              refreshing={isFetching}
              breadcrumbTrail={data ? [
            {
                name: 'Home',
                url: '/'
            },
            {
                name: data.Item.Label,
                url: `/Source/${data.Item.Number}`
            },
            {
                name: 'Source details',
                active: true
            }] : []}
        >
            {data
                ? <SourceEditForm isNew={false} source={data} onSubmit={handleUpdate}>
                    {(formProps) => <SubmitButton formProps={formProps}/>}
                </SourceEditForm>
                : null}
        </Page>
    );
}


export default Details;
