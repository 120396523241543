import { FC } from 'react';
import QueueSection from './QueueSection';
import { useGetAllItemsQuery } from '../Store/Queue/queueApi';


const ValuesTransfer : FC = () => {
    const { data, error, isLoading } = useGetAllItemsQuery()

    return (
        <QueueSection
            heading="Metric values transfer to LG Inform web services queue"
            subheading="Items, queued and running, which have been flagged for immediate transfer via Dataset Library"
            headers={['Description','Triggered by','Created']}
            mapper={v => [ v.description, v.triggeredBy, v.created ]}
            loading={isLoading}
            values={data ? data.syncDataItems : undefined}
            error={error}
        />
    );
}


export default ValuesTransfer;