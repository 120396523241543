import { FC, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { InfoCircle } from './Icons';

interface Props {
    id?: string;
    alt: string;
}



const InfoLink: FC<Props> = ({ alt, id }) => {
    const idref = useRef<string>(
        id??(`info-${window.crypto.randomUUID()}`)
    );

    return (
        <>
            <UncontrolledTooltip target={idref.current}>{alt}</UncontrolledTooltip>
            <InfoCircle id={idref.current} />
        </>
    );
}


export default InfoLink;