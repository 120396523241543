import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import LastLoadedBy, { TaskLastLoadedBy } from './LastLoadedBy';
import { FormikProps, Form } from 'formik';
import { Task } from '../Store/Tasks/Types';
import { FC, ReactNode } from 'react';
import { Dataset, DatasetListing } from '../Store/Dataset/Types';
import EditableDate from '../Common/DetailsForm/EditableDate';
import NextToBeLoadedDetail from '../Common/Tasks/NextToBeLoadedDetail';
import Attachments from './Attachments';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import HookedDropdownSelectorField from '../Common/DetailsForm/HookedDropdownSelectorField';
import { noAreaType, noDataset, noIndicator } from '../Types/Constants';
import {
    useGetAllAreaTypesQuery,
    useGetCollectionDatasetsListQuery,
    useGetIndicatorForDatasetQuery
} from '../Store/Lists/listApi';
import { GeoAreaLevel } from '../Store/GeoArea/Types';
import { Indicator } from '../Store/Indicators/Types';
import { TypedUseQueryHookResult } from '@reduxjs/toolkit/src/query/react/buildHooks';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';
import DetailsForm from '../Common/DetailsForm/DetailsForm';



interface TaskEditFormPropsBase {
    onSubmit: (values: Task) => void|Promise<void>;
    children: (props: FormikProps<Task>) => ReactNode;
}


interface NewTaskEdit extends TaskEditFormPropsBase {
    task: Task;
    isNew: true;
}

interface ExistingTaskEdit extends TaskEditFormPropsBase  {
    task: Task;    
    isNew: false;
}




const TaskForm: FC<NewTaskEdit|ExistingTaskEdit> = (props) => {
    const values = props.isNew ? {
        ...props.task,
        Id: 0,
        Published: null,
        NextCollected: null,
        TaskAttachments: []
    } as Task : props.task;

    
    return (
        <DetailsForm
            isNew={props.isNew}
            enableReinitialize
            initialValues={values}
            onSubmit={props.onSubmit}
            validateOnMount={!props.isNew}
        >
            {formProps => {
                
                return (
                    <Form>
                        <DetailedTableHeader
                            label="Task details"
                        />
                        <Container className="pb-3">
                            { !props.isNew ? <DetailedTableFormRow
                                context={formProps}
                                name="Id"
                                label="Identifier"
                                editable={false}                               
                            /> : null }
                            <DetailedTableRow label="Source">
                                {
                                    formProps.values.Collection?.Source
                                        ? <Link
                                            to={`/source/${formProps.values.Collection.Source.Number}`}>{formProps.values.Collection.Source.Label}</Link>
                                        : '-'
                                }
                            </DetailedTableRow>
                            <DetailedTableRow label="Collection">
                                {
                                    formProps.values.Collection
                                        ? <Link
                                            to={`/collection/${formProps.values.Collection.Number}`}>{formProps.values.Collection.Label}</Link>
                                        : '-'
                                }
                            </DetailedTableRow>
                            
                            <HookedDropdownSelectorField
                                context={formProps}
                                name="Dataset"
                                label="Dataset"
                                editable={props.isNew}
                                placeholder="-- None --"
                                isClearable
                                getDataFromListing={(v: DatasetListing) => v.Datasets}
                                hookResult={useGetCollectionDatasetsListQuery(formProps.values.Collection.Number)}                                
                                isSelectedOption={val => val.DatasetId === formProps.values.DatasetId}
                                formatLabel={v => (
                                    v?.DatasetLabel??'-'
                                )}
                                formatValue={v => (
                                    v ? (
                                        <Link to={`/dataset/${v.Number}`}>
                                            {v.Label}
                                        </Link>
                                    ) : '-'
                                )}
                                onAnyChange={newVal =>
                                    formProps.setValues(values => ({
                                        ...values,
                                        DatasetId: newVal ? newVal.DatasetId : null,
                                        Dataset: newVal  ? {
                                            Id: newVal.DatasetId,
                                            Number: newVal.DatasetNumber,
                                            Label: newVal.DatasetLabel
                                        } as Dataset : null
                                    } as Task))
                                }
                                
                            />
                            <HookedDropdownSelectorField
                                context={formProps}
                                name="Indicator"
                                label="Metric type"
                                alwaysEditing={props.isNew}
                                editable={props.isNew}
                                placeholder="-- None --"
                                isClearable
                                getDataFromListing={(v: Indicator[]) => v}
                                hookResult={useGetIndicatorForDatasetQuery(formProps.values.Dataset?.Number??0, { skip: formProps.values.Dataset === null || !props.isNew })}
                                formatLabel={v => v?.Label ?? '-'}
                                isSelectedOption={v => v.Id === formProps.values.IndicatorId}
                                onAnyChange={newVal => 
                                    formProps.setValues(values => ({
                                        ...values,
                                        IndicatorId: newVal?.Id ?? null,
                                        Indicator: newVal ?? null
                                    } as Task)) 
                                }
                            />
                            <HookedDropdownSelectorField<Task, 'GeoAreaLevel', GeoAreaLevel, GeoAreaLevel[]>
                                context={formProps}
                                placeholder="-- None --"
                                isClearable
                                hookResult={useGetAllAreaTypesQuery(undefined,{ 
                                    skip: !props.isNew,                                    
                                }) as TypedUseQueryHookResult<GeoAreaLevel[], any, any>}
                                getDataFromListing={(v: GeoAreaLevel[]) => v.filter(at => !at.IsAbolishedAreaLevel)}
                                isSelectedOption={opt => (formProps.values.GeoAreaLevel === null && opt === noAreaType) || (formProps.values.GeoAreaLevel?.Identifier === opt.Identifier)}
                                name="GeoAreaLevel"
                                label="Area type"
                                alwaysEditing={props.isNew}
                                editable={props.isNew}
                                formatLabel={opt => opt.Name}
                                onChange={newVal => {
                                    formProps.setValues(values => ({
                                        ...values,
                                        GeoAreaLevelId: newVal?.Id??null,
                                        GeoAreaLevel: newVal??null
                                    } as Task)).then()
                                }}                                
                                                               
                            />                                         
                            <DetailedTableFormRow
                                context={formProps}
                                label="Update method"
                                name="UpdateMethod"
                                editable={false}
                            />
                            <DetailedTableRow label="Last time period">
                              {formProps.values.LastTimePeriod?.DisplayName ?? '-'}
                            </DetailedTableRow>
                            <DetailedTableRow label="Last time period identifier">
                                {formProps.values.LastTimePeriod?.Identifier ?? '-'}
                            </DetailedTableRow>
                            <DetailedTableRow label="Last loaded">
                                {formProps.values.LastCollected ? (new Date(formProps.values.LastCollected)).toLocaleDateString() : '-'}
                            </DetailedTableRow>
                            {!props.isNew ?
                                <DetailedTableRow label="Last loaded by" align="center">
                                    {<TaskLastLoadedBy taskId={(formProps.values as Task).Id}/>}
                                </DetailedTableRow> : null}
                            <DetailedTableRow label="Next time period">
                                {formProps.values.NextTimePeriod?.DisplayName ?? '-'}
                            </DetailedTableRow>
                            <DetailedTableRow label="Next time period identifier">
                                {formProps.values.NextTimePeriod?.Identifier ?? '-'}
                            </DetailedTableRow>
                            <DetailedTableRow label="Published">
                                <EditableDate
                                    isEditing={props.isNew}
                                    value={formProps.values.Published?new Date(formProps.values.Published):undefined}
                                    onChange={date => formProps.setFieldValue('Published', date?.toISOString()??null)}
                                />
                            </DetailedTableRow>

                            <DetailedTableRow
                                label="Next to be loaded"
                            >
                                <NextToBeLoadedDetail
                                    isEditing={props.isNew}

                                />
                            </DetailedTableRow>
                                                        
                            <DetailedTableFormRow
                                context={formProps}
                                label="Notes"
                                name="Notes"
                                editable
                                alwaysEditing={props.isNew}
                            />
                        </Container>
                        <Attachments attachments={formProps.values.TaskAttachments}/>
                        <FormToolkit>
                            {props.children(formProps)}
                        </FormToolkit>
                    </Form>
                );
            }}
        </DetailsForm>
    );
}




export default TaskForm;