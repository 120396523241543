import { FC } from 'react';
import QueueSection from './QueueSection';
import { useGetAllItemsQuery } from '../Store/Queue/queueApi';


const StatXplore : FC = () => {

    const { data, error, isLoading } = useGetAllItemsQuery()


    return (
        <QueueSection
            heading="Stat Xplore Import"
            subheading="Stat-Xplore imports triggered from the Dataset Library"
            headers={['Description','Created','Triggered by','Current status']}
            mapper={v => [ v.description, v.created, v.triggeredBy, v.isRunning?'Importing':'Queued' ]}
            error={error}
            loading={isLoading}
            values={data?.statXploreItems}
        />
    );
}


export default StatXplore;