import { FC, ReactNode } from 'react';
import { NewDetailsApiModel } from '../Store/Types';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import ChangeDetails from '../Common/DetailsForm/ChangeDetails';
import { Form, FormikErrors, FormikProps } from 'formik';
import HookedDropdownSelectorField from '../Common/DetailsForm/HookedDropdownSelectorField';
import {
    useGetBasicMeasurementUnitsListQuery
} from '../Store/Lists/listApi';
import { Measure, MeasureApiModel } from '../Store/Measure/Types';
import { GroupBase } from 'react-select';
import { MeasurementUnit } from '../Store/MeasurementUnit/Types';
import DetailsForm from '../Common/DetailsForm/DetailsForm';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';
import { Link } from 'react-router-dom';

interface MeasureEditFormPropsBase {
    onSubmit: (values: Measure) => void | Promise<void>;
    children: (props: FormikProps<Measure>) => ReactNode;
}

interface NewMeasureEditFormProps extends MeasureEditFormPropsBase {
    isNew: true;
    measure: NewDetailsApiModel<Measure, 'DatasetId' | 'Dataset'>;
}

interface ExistingMeasureEditFormProps extends MeasureEditFormPropsBase {
    isNew: false;
    measure: MeasureApiModel;
}

type MeasureEditFormProps = NewMeasureEditFormProps | ExistingMeasureEditFormProps;

const measureValidator = (measure: Measure): FormikErrors<Measure> | undefined => {
    const errors: FormikErrors<Measure> = {};
    if (!measure.Label) {
        errors.Label = 'Label must not be empty';
    }
    if (!isFinite(measure.InputPrecision)) {
        errors.InputPrecision = 'Input precision must be a number';
    }
    if (!isFinite(measure.OutputPrecision)) {
        errors.OutputPrecision = 'Output precision must be a number';
    }
    if (!measure.MeasurementUnit) {
        errors.MeasurementUnitId = 'You must select a measurement unit';
    }
    return Object.entries(errors).length > 0 ? errors : undefined;
}



const MeasureEditForm: FC<MeasureEditFormProps> = (props) => {
    

    return (
        <DetailsForm
            initialValues={props.measure.Item as Measure}
            onSubmit={props.onSubmit}
            validate={measureValidator}
            validateOnMount={!props.isNew}
            isNew={props.isNew}
            hoverHelp={props.measure.HoverHelp}
        >
            {formProps => (
                <Form>
                    <DetailedTableHeader
                        label="Measure details"
                        hoverProps={{ id: 'measure-heading' }}
                    />
                    <Container>
                        {!props.isNew ? <ChangeDetails {...props.measure} /> : null}
                        <DetailedTableRow
                            label="Dataset"
                            hoverProps={{ id: 'dataset-heading' }}
                        >
                            {formProps.values.Dataset.Label}
                        </DetailedTableRow>
                        {
                            !props.isNew
                                ? <DetailedTableFormRow
                                    context={formProps} name="Identifier" label="Identifier"
                                    editable={false}
                                    />
                                : null
                        }
                        <HookedDropdownSelectorField<Measure, 'MeasurementUnit', MeasurementUnit, GroupBase<MeasurementUnit>[]>
                            context={formProps}
                            editable={props.isNew}
                            hoverProps={{ id: 'measurement-unit-heading' }}
                            name="MeasurementUnit"
                            label="Measurement unit"
                            hookResult={useGetBasicMeasurementUnitsListQuery()}
                            getDataFromListing={listing => listing}
                            formatLabel={opt => `${opt.Label} (id: ${opt.Identifier})`}
                            isSelectedOption={opt => opt.Id === formProps.values.MeasurementUnit?.Id}
                            onChange={val => formProps.setValues(prev => ({
                                ...prev,
                                MeasurementUnit: val,
                                MeasurementUnitId: val.Id
                            }))}
                            formatValue={val => val ? <Link to={`/measurementUnit/details/${val.Identifier}`}>{val.Label}</Link> : '-'}
                        />
                    
                        <DetailedTableFormRow
                            context={formProps}                                 
                            name="Label"
                            label="Label"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            name="InputPrecision"
                            label="Input precision"
                            useNumberForNumericField
                            editable={props.isNew}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            name="OutputPrecision"
                            label="Output precision"
                            useNumberForNumericField
                        />

                        <DetailedTableFormRow
                            context={formProps}
                            name="IsAggregatable_AreaOrDimension"
                            label="Aggregate area / dimension"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            name="IsAggregatable_Time"
                            label="Aggregate time"
                        />
    
                       
                    </Container>
                    <FormToolkit>
                        {props.children(formProps)}
                    </FormToolkit>
                </Form>
            )
            }
        </DetailsForm>
    );
};

export default MeasureEditForm;