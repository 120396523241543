import { Alert, Button } from 'reactstrap';
import { FC, useEffect, useState } from 'react';
import { useLazyGetLinkSuggestionsQuery } from '../../Store/Dataset/datasetApi';
import { formatUpdateMethod, NoAggregation } from './AreaTypeUpdateMethod';
import {  PlusLg } from '../../Common/Icons';
import {
    DatasetApplicableGeoLevel,
    DatasetApplicableGeoLevelSuggestion,
    DatasetFormModel
} from '../../Store/Dataset/Types';

interface SuggestionsTabProps {
    isActive: boolean;
    currentLinks: DatasetFormModel['ApplicableGeoLevel'];
    canUpdate: boolean;
    onRemove: (existing: DatasetApplicableGeoLevel[]) => void;
    onAdd: (existing: DatasetApplicableGeoLevel[]) => void;
}

const SuggestionsTab: FC<SuggestionsTabProps> = (props) => {

    const [trigger, result] = useLazyGetLinkSuggestionsQuery();
    const [dirty, setDirty] = useState(true);
    const [ acted, setActed ] = useState<DatasetApplicableGeoLevelSuggestion[]>([]); 

        let loader = null;
    if (result.isUninitialized) {
        loader = (
            <Alert color="info">
                Get suggested aggregations based on existing uploaded or imported area types by clicking
                <Button color="link" onClick={() => trigger(props.currentLinks)}>here</Button>
            </Alert>
        );
    }
    
    useEffect(() => {
        setDirty(true);
    }, [ props.currentLinks ]);

    useEffect(() => {
        if (result.data && dirty) {
            setDirty(false);
        }
    }, [ result.data ]);
    
    if (result.isLoading || result.isFetching) {
        return (
            <Alert color="info">
                Suggestions loading ... <div className="loading"/>
            </Alert>
        );
    }

    const nonKeepResult = result.data?.filter(
        s => !acted.includes(s) && s.Action !== 'Keep'
    )??[];
    

    const onClick = (suggestion: DatasetApplicableGeoLevelSuggestion) => {
        props.onAdd([ suggestion.SuggestedLink ]);  
        setActed(prev => [
            ...prev,
            suggestion
        ])
    };
    
    const onRemoveClick = (suggestion: DatasetApplicableGeoLevelSuggestion) => {
        props.onRemove([ suggestion.SuggestedLink ]);
        setActed(prev => [
            ...prev,
            suggestion
        ])
    };
    
    const actAll = () => {
        const add = nonKeepResult.filter(r => r.Action === 'New');
        const remove = nonKeepResult.filter(r => r.Action === 'Delete');
        if (add.length > 0) {
            props.onAdd(add.map(r => r.SuggestedLink));
        }
        if (remove.length > 0) {
            props.onRemove(remove.map(r => r.SuggestedLink));
        }
        setActed(nonKeepResult);
    }
    
    return (
        <>
            {loader}

            {!result.isUninitialized && result.data && nonKeepResult.length > 0 ?
                <ul className="added control-group long">
                    <li>
                        <Button onClick={actAll}>Act on all suggestions</Button>
                    </li>
                    {nonKeepResult.map(suggestion => (
                        <li>
                            <strong>
                                {suggestion.SuggestedLink.GeoAreaLevel.Name}
                            </strong>{' '}
                            {formatUpdateMethod({
                                Discontinued: false,
                                AreaType: suggestion.SuggestedLink.GeoAreaLevel,
                                InputType: suggestion.SuggestedLink.IndicatorInputMethod!,
                                AggregatedFromAreaType: suggestion.SuggestedLink.AggregatedFromLevel
                            })}
                            <strong>
                                {suggestion.SuggestedLink.AggregatedFromLevel?.Name??NoAggregation}
                            </strong>
                            {suggestion.Action === 'Delete' && props.canUpdate ?
                                <>
                                    <Button color="link" className="text-danger" onClick={() => onRemoveClick(suggestion)}>
                                        Remove invalid option
                                    </Button>
                                </>
                                : null}
                            {suggestion.Action === 'New' && props.canUpdate ?
                                <>
                                    <Button color="link" className="text-success" onClick={() => onClick(suggestion)}>
                                        <PlusLg/>
                                    </Button>
                                </>
                                : null}
                        </li>
                    ))}
                </ul>
                : (!result.isUninitialized && !result.error 
                    ? <Alert color="info">No suggestions found.</Alert> 
                    : (
                        result.error ? <Alert color="danger">
                            Error loading suggestions.
                            {' '}<Button color="link" onClick={() => trigger(props.currentLinks)}>Retry</Button>
                        </Alert> : null
                    )
                )
            }
        </>
    )
}

export default SuggestionsTab;