import { Alert, Table } from 'reactstrap';
import { FC } from 'react';
import { QueueTableProps } from './QueueSection';

const QueueTable: FC<QueueTableProps> = (props) => {

    if (props.error) {
        return <Alert color="danger">Failed to retrieve items</Alert>
    }

    if (!props.loading && (props.values??[]).length === 0) {
        return <Alert color="info">{props.noDataMessage ?? 'No items'}</Alert>
    }

    if (props.loading) {
        return <div className="loading"></div>
    }


    return (
        <Table className="tasks">
            <thead>
            <tr>
                {props.headers.map(h => <th key={h}>{h}</th>)}
            </tr>
            </thead>
            <tbody>
            {
                (props.values??[]).map(((v, i) => (
                    <tr key={i}>
                        {props.mapper(v).map(value => <td key={value}>{value}</td>)}
                    </tr>
                )))
            }
            </tbody>
        </Table>
    );
}


export default QueueTable;