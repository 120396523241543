import { FC, useCallback } from 'react';
import {
    DetailedTableFormRow,
} from '../../../Common/DetailsForm/DetailedTable';
import SimpleSelector from '../../../Common/DetailsForm/SimpleSelector';
import {
    CalculationFieldParameters,
    CalculationJsonModel,
    IndicatorCalculationMethod, IndicatorCalculationMethodField,
    MetricTypeJsonModel, ValueWithMetadata
} from '../../../Store/Indicators/Types';
import { FormikContextType } from 'formik';
import { useGetCalculationMethodWithFieldsQuery } from '../../../Store/Lists/listApi';
import CalculationField from './CalculationField';
import { SingleValue } from 'react-select';
import { DetailedTableRow } from '../../../Common/DetailsForm/BasicInput';

interface Props {
    context: FormikContextType<MetricTypeJsonModel>;
}

const initializeCalculationMethodModel = (method: IndicatorCalculationMethod|null) => {
    if (method=== null) {
        return null;
    }
    return {
        IndicatorCalculationMethod: method,
        SelectedOptionByValue: (method.Fields?.map(f => ({
            FieldIdentifier: f.Identifier,
            CalculationValues: []
        } as CalculationFieldParameters)))??[]       
    } as CalculationJsonModel;
}


const CalculationMethod: FC<Props> = (props) => {
    const { data, error, isLoading } = useGetCalculationMethodWithFieldsQuery();
    const ctx = props.context;


    const calculationParameters = ctx.values.CalculationParameters;
    const onAdd = (field: IndicatorCalculationMethodField) => (newVal: Omit<ValueWithMetadata, 'UniqueIdentifier'>) => {
        const current = calculationParameters!.SelectedOptionByValue.find(f => f.FieldIdentifier === field.Identifier)
            ?? {
                FieldIdentifier: field.Identifier,
                CalculationValues: []
            } as CalculationFieldParameters;
        return ctx.setFieldValue('CalculationParameters.SelectedOptionByValue',
            calculationParameters!.SelectedOptionByValue.map(
                p => p.FieldIdentifier !== field.Identifier ? p : {
                    ...current,
                    CalculationValues: [
                        ...current.CalculationValues,
                        {
                            ...newVal,
                            UniqueIdentifier: crypto.randomUUID()
                        }
                    ]
                })
        );
    }
    const onRemove = (field: IndicatorCalculationMethodField) => (removeId: string) => {
        return ctx.setFieldValue('CalculationParameters.SelectedOptionByValue',
            calculationParameters!.SelectedOptionByValue
                .map(f => f.FieldIdentifier !== field.Identifier ? f : {
                    ...f,
                    CalculationValues: f.CalculationValues.filter(v => v.UniqueIdentifier !== removeId)
                })
        );
    }
    
    const onUpdated = (field: IndicatorCalculationMethodField) => (updated: ValueWithMetadata) => {
        return ctx.setFieldValue('CalculationParameters.SelectedOptionByValue',
            calculationParameters!.SelectedOptionByValue
                .map(p => p.FieldIdentifier !== field.Identifier ? p : {
                    ...p,
                    CalculationValues: field.AllowMultiple
                        ? p.CalculationValues.map(v => v.UniqueIdentifier !== updated.UniqueIdentifier ? v : updated)
                        : [updated]
                }),
        );
    }
    
    return (
        <>
            <DetailedTableFormRow
                className="calculation-fields-row"
                context={ctx}
                label="Calculation method"
                name="CalculationParameters.IndicatorCalculationMethod.FullLabel"
                formatValue={() => calculationParameters?.IndicatorCalculationMethod?.FullLabel??'No automatic calculation'}
                inputField={innerProps => {
                    const onMethodChange = useCallback((val: SingleValue<IndicatorCalculationMethod>) => {
                        ctx.setFieldValue('CalculationParameters', initializeCalculationMethodModel(val??null)).then();
                        innerProps.onDoneEditing();
                    }, [innerProps.onDoneEditing]);
                    return (
                        <SimpleSelector<IndicatorCalculationMethod, IndicatorCalculationMethod[]>
                            isLoading={isLoading}
                            listing={data}
                            isSelectedOption={opt => calculationParameters?.IndicatorCalculationMethod ? opt.CalculationMethod === calculationParameters.IndicatorCalculationMethod.CalculationMethod : false}
                            onChange={onMethodChange}
                            isClearable
                            placeholder="No automatic calculation"
                            formatLabel={val => val.FullLabel}
                            getDataFromListing={val => val}
                        />
                    );
                }}
            />
            
            
            {
                calculationParameters !== null ?
                    <>
                        <DetailedTableRow className="calculation-fields-row" label="">
                            {calculationParameters.IndicatorCalculationMethod.Description}
                        </DetailedTableRow>
                        {calculationParameters.IndicatorCalculationMethod.Fields!.map(field => (
                            <CalculationField
                                key={field.Identifier}    
                                method={calculationParameters.IndicatorCalculationMethod}
                                field={field}                                
                                onUpdate={onUpdated(field)}
                                onAdd={onAdd(field)}
                                onRemove={onRemove(field)}
                                values={calculationParameters.SelectedOptionByValue
                                    .find(v => v.FieldIdentifier === field.Identifier)?.CalculationValues??[]}
                            />
                        ))??null}
                    </>
                    : null
            }
        </>
    )
}

export default CalculationMethod;