import { FC, ReactNode } from 'react';
import { DetailsApiModel, NewDetailsApiModel, NumberedEntity } from '../Store/Types';
import { Collection, CollectionListing, CollectionListingEntry } from '../Store/Collection/Types';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Button, Container } from 'reactstrap';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import ChangeDetails from '../Common/DetailsForm/ChangeDetails';
import { FormikProps, FormikErrors, Form, FormikConfig } from 'formik';
import { useGetCollectionListQuery, useGetSourceListQuery } from '../Store/Lists/listApi';
import DetailsForm from '../Common/DetailsForm/DetailsForm';
import ListingSelectorFormField from '../Common/DetailsForm/ListingSelectorFormField';
import HookedDropdownSelectorField from '../Common/DetailsForm/HookedDropdownSelectorField';
import { Source, SourceListing, SourceListingEntry } from '../Store/Source/Types';


export interface CollectionFormProps extends Omit<Collection, 'ReplacedBy'|'Replaces'>{
    ReplacedBy: NumberedEntity[]
}

interface CollectionEditFormPropsBase {
    onSubmit: FormikConfig<CollectionFormProps>['onSubmit'];
    children: (props: FormikProps<CollectionFormProps>) => ReactNode;
}


interface NewCollectionEditFormProps extends CollectionEditFormPropsBase {
    isNew: true;
    collection: NewDetailsApiModel<Collection, 'Source'|'SourceId'>;
}

interface ExistingCollectionEditFormProps extends CollectionEditFormPropsBase {
    isNew: false;
    collection: DetailsApiModel<Collection>;
}

type CollectionEditFormProps = NewCollectionEditFormProps|ExistingCollectionEditFormProps;

const collectionValidator = (collection: CollectionFormProps): FormikErrors<CollectionFormProps> | undefined => {
    const errors: FormikErrors<CollectionFormProps> = {};
    if (!collection.Label) {
        errors.Label = 'Label must not be empty';
    }
    if (!collection.Url) {
        errors.Url = 'URL must not be empty';
    } else {
        try {
            new URL(collection.Url);
        } catch (e) {
            errors.Url = 'URL is not valid';
        }
    }
    return Object.entries(errors).length > 0 ? errors : undefined;
}

const CollectionEditForm: FC<CollectionEditFormProps> = (props) => {

    const values: CollectionFormProps = props.isNew ? {
        Id: '<determined after adding>' as any,
        Source: props.collection.Item.Source,
        SourceId: props.collection.Item.Source?.Id,
        Label: '',
        ContributorId: null,
        CreatorId: null,
        Number: '<determined after adding>' as any,
        Modified: '',
        Created: '',
        Url: '',
        Discontinued: false,
        IsLocalCollectionRequired: false,
        ReplacedBy: [],
    } : {
        ...props.collection.Item,
        ReplacedBy: props.collection.Item.ReplacedBy?.map(rpl => rpl.ReplacedBy)??[],
    };

    return (
        <DetailsForm
            initialValues={values}
            onSubmit={props.onSubmit}
            validate={collectionValidator}
            validateOnMount={!props.isNew}
            isNew={props.isNew}
            hoverHelp={props.collection.HoverHelp}
        >
            {formProps => (
                <Form>
                    <DetailedTableHeader
                        label="Collection details"
                        hoverProps={{ id: 'collection-heading' }}
                    />
                    <Container>
                        {!props.isNew ? <ChangeDetails {...props.collection} /> : null}
                        {!props.isNew ? <DetailedTableFormRow
                            context={formProps}
                            name="Number"
                            label="Identifier"
                            editable={false}
                        /> : null}
                        <HookedDropdownSelectorField<CollectionFormProps,'Source',SourceListingEntry,SourceListing>
                            context={formProps}
                            name="Source"
                            label="Source"
                            hoverProps={{ id: 'source-heading' }}
                            hookResult={useGetSourceListQuery()}
                            getDataFromListing={data => data.Sources}
                            formatLabel={source => `${source.Label} (id: ${source.Number})`}
                            isSelectedOption={opt => formProps.values.Source?.Number === opt.Number}
                            required
                            onChange={s => {
                                formProps.setFieldValue('Source', {
                                    Id: s.Id,
                                    Number: s.Number,
                                    Label: s.Label,
                                    Created: '',
                                    Modified: '',
                                    ContributorId: null,
                                    CreatorId: null
                                } as Source);
                                formProps.setFieldValue('SourceId', s.Id);
                            }}
                        />                        
                        <DetailedTableFormRow
                            context={formProps}
                            label="Label"
                            name="Label"
                            
                            editable
                            alwaysEditing={props.isNew}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Url"
                            name="Url"
                            formatValue={val => val && formProps.errors.Url === undefined 
                                ? <Button color="link" href={val} target="_blank">{val}</Button>
                                : '-'}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Collected locally"
                            name="IsLocalCollectionRequired"
                            editable
                            alwaysEditing={props.isNew}
                        />
                        <ListingSelectorFormField<CollectionFormProps,'ReplacedBy',NumberedEntity,CollectionListing>
                            context={formProps}
                            identify={a => a.Number.toString()}
                            label="Replaced by"
                            name="ReplacedBy"
                            hoverProps={{ name: 'ReplacedByID' }}
                            hookResult={useGetCollectionListQuery()}
                            getDataFromListing={(listing: CollectionListing) => listing.Collections.map(l => ({
                                Id: l.CollectionId,
                                Number: l.CollectionNumber,
                                Label: l.CollectionLabel
                            } as NumberedEntity))}
                            formatLabel={item => `${item.Label} (id: ${item.Number})`}
                        />
                        {!props.isNew ? <DetailedTableFormRow
                            context={formProps}
                            label="Discontinued"
                            name="Discontinued"
                            
                            editable
                        /> : null}
                    </Container>
                    <FormToolkit>
                        {props.children(formProps)}
                    </FormToolkit>
                </Form>
            )
            }
        </DetailsForm>
    );
};

export default CollectionEditForm;