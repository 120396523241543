import { FC, ReactNode } from 'react';
import {
    DetailedTableEnumRow,
    DetailedTableFormRow, DetailedTableHeader
} from '../../Common/DetailsForm/DetailedTable';
import { Container, Input } from 'reactstrap';
import { FormikProps, Form } from 'formik';
import {
    DetailsApiModel,
    IdentifiableEntity,
    NumberedEntity
} from '../../Store/Types';
import {    
    IndicatorDetailsApiModel,
    LastUpdateDetails,
    MetricTypeJsonModel
} from '../../Store/Indicators/Types';
import ChangeDetails from '../../Common/DetailsForm/ChangeDetails';
import FormattedTextEditor from './FormattedTextEditor';
import { AnyListingSelectorField } from '../../Common/DetailsForm/AnyListingSelectorField';
import PaginatedMetricSelector from '../../Common/PaginatedMetricSelector';
import FormikDetailsForm from '../../Common/DetailsForm/DetailsForm';
import { Measure } from '../../Store/Measure/Types';
import { Dataset } from '../../Store/Dataset/Types';
import SimpleSelector from '../../Common/DetailsForm/SimpleSelector';
import SubmissionLag from './SubmissionLag';
import { useGetWebserviceClientApplicationsQuery } from '../../Store/Lists/listApi';
import ListingSelectorFormField from '../../Common/DetailsForm/ListingSelectorFormField';
import Services from './Subjects';
import FormToolkit from '../../Common/DetailsForm/FormToolkit';
import SubmitButton from '../../Common/SubmitButton';
import CalculationMethod from './CalculationMethod';
import { DetailedTableRow } from '../../Common/DetailsForm/BasicInput';
import ImportConfiguration from './ImportConfiguration';
import metricTypeValidator from './validation';
import DimensionMemberDropdown from './DimensionMemberDropdown';
import { Link } from 'react-router-dom';

interface BaseMetricTypeDetailsFormProps {
    children: (props: FormikProps<MetricTypeJsonModel>) => ReactNode;
    onSubmit: (props: MetricTypeJsonModel) => void;
    canEditSecurityType: boolean;
    dataset: Dataset;
}

type NewMetricTypeDetailsFormProps = BaseMetricTypeDetailsFormProps&Pick<IndicatorDetailsApiModel, 'Polarity'|'SecurityTypeInput'>&{
    isNew: true;
    indicator: Omit<DetailsApiModel<MetricTypeJsonModel>, keyof ChangeDetails>;
    measures?: Measure[];
}

type ExitingMetricTypeDetailsFormProps = BaseMetricTypeDetailsFormProps&Pick<IndicatorDetailsApiModel, 'Polarity'|'SecurityTypeInput'|'CalculationValuesMessage'>&{
    isNew: false;
    indicator: DetailsApiModel<MetricTypeJsonModel>;
    lastUpdatedDetails?: LastUpdateDetails;
}

type MetricTypeDetailsFormProps = NewMetricTypeDetailsFormProps|ExitingMetricTypeDetailsFormProps;

const DetailsForm: FC<MetricTypeDetailsFormProps> = (props) => {
    return (
        <FormikDetailsForm 
            hoverHelp={props.indicator.HoverHelp}
            isNew={props.isNew}
            initialValues={props.indicator.Item}
            onSubmit={props.onSubmit}
            validate={metricTypeValidator}
        >
            {formProps => (
                <Form>
                    <DetailedTableHeader
                        label="Metric type details"
                        hoverProps={{ id: 'metric-type-heading' }}
                    />
                    <Container>
                        {!props.isNew ? (
                            <ChangeDetails {...props.indicator} />
                        ): null}
                        {!props.isNew ? (
                            <DetailedTableRow
                                label="Last uploaded"
                                
                            >
                                {props.lastUpdatedDetails && props.lastUpdatedDetails.hasData
                                    ?
                                    <>
                                        Latest period label {props.lastUpdatedDetails.period.DisplayName} uploaded
                                        by {props.lastUpdatedDetails.user} on {new Date(props.lastUpdatedDetails.date).toLocaleDateString()} 
                                    </>
                                    : (props.lastUpdatedDetails && !props.lastUpdatedDetails.hasData ? 'Never. No data available' : 'Loading...') }
                                
                            </DetailedTableRow>
                        ) : null}
                        { !props.isNew ? <DetailedTableFormRow
                            context={formProps}
                            editable={false}
                            label="Identifier"
                            name="Number"
                                                        
                        /> : null }
                        <DetailedTableRow 
                            label="Dataset"
                            
                        >
                            {props.isNew ? props.dataset.Label : props.indicator.Item.Measure.Dataset.Label }
                        </DetailedTableRow>        
                        <DetailedTableFormRow 
                            context={formProps}
                            editable={props.isNew && !!props.measures}
                            label="Measure"
                            name="Measure.Label"
                            required                            
                            inputField={innerProps => (
                                <SimpleSelector<Measure, Measure[]>
                                    listing={(props as NewMetricTypeDetailsFormProps).measures}
                                    isSelectedOption={opt => formProps.values.Measure ? opt.Identifier === formProps.values.Measure.Identifier : false}
                                    onChange={val => val ? formProps.setFieldValue('Measure', val) : undefined}
                                    formatLabel={val => `${val.Label} (id: ${val.Identifier})`}
                                    getDataFromListing={val => val}                                    
                                />        
                            )}
                        />
                            
                        <DetailedTableFormRow
                            context={formProps}
                            label="Label"
                            name="Label"
                            required                            
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Short label"
                            name="ShortLabel"
                            required
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Original label"
                            name="OriginalLabel"
                            
                        />
                        <AnyListingSelectorField<string>                            
                            label="Alternate labels"                            
                            
                            listingSelector={innerProps => (
                                <Input value={innerProps.value} onChange={e => innerProps.onChange(e.currentTarget.value)} />
                            )}
                            onItemAdded={newLabel => formProps.setFieldValue('AlternateLabels', [
                                ...(formProps.values.AlternateLabels??[]), newLabel 
                            ])}
                            onRemoveItem={index => formProps.setFieldValue('AlternateLabels', formProps.values.AlternateLabels!.filter((l,i)=> i !== index))}
                        >
                            {(formProps.values.AlternateLabels??[])}
                        </AnyListingSelectorField>
                        <FormattedTextEditor />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Discontinued"
                            name="Discontinued"
                            
                        />
                        <AnyListingSelectorField<NumberedEntity>
                            label="Replaced by"
                            hoverProps={{ name: 'ReplacedByID' }}
                            onRemoveItem={idx => formProps.setFieldValue('Replacements', formProps.values.Replacements.filter((r,i) => i !== idx))}
                            onItemAdded={e => !formProps.values.Replacements.find(r => r.Number === e.Number) 
                                ? formProps.setFieldValue('Replacements', [ ...formProps.values.Replacements, e ])  
                            : undefined}
                            listingSelector={innerProps => (
                                <PaginatedMetricSelector
                                    onChange={innerProps.onChange}
                                    value={innerProps.value}
                                />
                            )}                            
                        >
                            {formProps.values.Replacements?.map(v => (
                                `${v.Label} (id: ${v.Number})`
                            )) ?? []}
                        </AnyListingSelectorField>
                        <DetailedTableEnumRow
                            context={formProps}
                            label="Polarity"
                            name="Polarity"
                            
                            enumValues={props.Polarity}
                            onChange={newVal => formProps.setFieldValue('Polarity', newVal ? Number(newVal) : null)}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Comment"
                            name="Comment"                                                        
                            onChange={newVal => formProps.setFieldValue('Comment', newVal)}
                        />
                       
                       <CalculationMethod context={formProps} />                       
                        { !props.isNew ? 
                            <DetailedTableRow label="Calculation time period message">
                                {props.CalculationValuesMessage??'No checking required'}
                            </DetailedTableRow>
                            : null }
                        <DetailedTableFormRow
                            context={formProps}
                            label="Calculation notes"
                            name="CalculationNotes"
                        />
                        <SubmissionLag />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Upload or Calculate"
                            name="UploadOrCalculate"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Is draft"
                            name="IsDraft"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Is excluded from breakdown list"
                            name="IsExcludedFromBreakdownList"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Treat null as zero"
                            name="NullAsZero"
                        />
                        <DetailedTableEnumRow
                            context={formProps}
                            label="Security type"
                            name="SecurityType"
                            editable={props.canEditSecurityType}
                            enumValues={props.SecurityTypeInput}
                            append={formProps.values.RequiredLicenceID ? <>{' '}<Link to={`/licence/details/${formProps.values.RequiredLicenceID}`}>See licenced organisations</Link></> : null}
                        />
                        <DetailedTableEnumRow
                            context={formProps}
                            label="Deflatable"
                            name="Deflatable"
                            enumValues={[ { Id: 0, Name: 'No' },{ Id: 1, Name: 'Adjust for present' }, { Id: 2, Name: 'Adjust for future'} ]}
                        />
                        <ListingSelectorFormField<MetricTypeJsonModel, 'ClientApplications', IdentifiableEntity, IdentifiableEntity[]>
                            context={formProps}
                            label="Client applications"
                            name="ClientApplications"
                            formatLabel={app => app.Label}
                            getDataFromListing={res => res}
                            hookResult={useGetWebserviceClientApplicationsQuery()}
                            identify={a => a.Identifier}                            
                        />
                        <DimensionMemberDropdown />
                        <Services />
                        <ImportConfiguration
                            context={formProps}
                            datasetNumber={props.dataset.Number}
                        />
                    </Container>
                    <FormToolkit>
                        <SubmitButton formProps={formProps} />
                    </FormToolkit>
                </Form>
            )}
        </FormikDetailsForm>
    );
};

export default DetailsForm;