import { FC } from 'react';
import { DetailedTableFormRow } from '../../Common/DetailsForm/DetailedTable';
import { useFormikContext } from 'formik';
import { MetricTypeJsonModel, TimeIntervalType } from '../../Store/Indicators/Types';
import { Col, Input } from 'reactstrap';
import DropdownSelector from '../../Common/DetailsForm/DropdownSelector';
import { useGetTimeIntervalTypesQuery } from '../../Store/Lists/listApi';
import { InputField } from '../../Common/DetailsForm/BasicInput';
import { SubmissionLag as  SubmissionLagJson } from '../../Store/Indicators/Types';


const SubmissionLagInput : InputField<SubmissionLagJson|undefined> = (props) => {
    const { value } = props;
    const emptyVal : SubmissionLagJson = {
        Identifier: '',    
        Value: 0,
        Label: 'None'
    };
    return (
        <>
            <Col>
                <Input type="number" value={value?.Value??''} onChange={e => props.onChange?.({
                    ...(value??emptyVal),
                    Value: Number(e.currentTarget.value)
                })} />
            </Col>
            <Col>
                <DropdownSelector<TimeIntervalType, TimeIntervalType[]>
                    hookResult={useGetTimeIntervalTypesQuery()}
                    getDataFromListing={res => res}
                    onChange={val => {
                        if (val) {
                            props.onChange?.({
                                ...(value??emptyVal),
                                Identifier: val.Identifier,
                                Label: val.Name
                            })
                        }
                        props.onDoneEditing();
                    }}
                    isSelectedOption={opt => opt.Identifier === value?.Identifier}
                    formatLabel={opt => opt.Name}
                />
            </Col>
        </>
    );
}

const SubmissionLag : FC = () => {
    
    const ctx = useFormikContext<MetricTypeJsonModel>()
    
    return (
        <DetailedTableFormRow<MetricTypeJsonModel, 'SubmissionLag'>
            id="metric-type-submission-lag"    
            context={ctx}
            label="Submission lag"
            name="SubmissionLag"
            row
            inputField={SubmissionLagInput}
            inputFieldChangePropagates
            formatValue={val => val ? `${val.Value} ${val.Label}${val.Value!==1?'s':''}` : 'None'}
        />
    );
}

export default SubmissionLag;