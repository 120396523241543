import { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import InfoLink from '../Common/InfoLink';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { queryable, resourcesLink } from '../Utils';
import { Task } from '../Store/Tasks/Types';
import { ToolbarProps } from './Toolbar';
import { useAppSelector } from '../Store/store';
import ButtonWithTooltip from '../Common/ButtonWithTooltip';
import classNames from 'classnames';
import { ArrowCounterclockwise, Upload } from '../Common/Icons';
import { useUploadMutation } from '../Store/Indicators/indicatorApi';
import { useSubmitHandler } from '../hooks';
import { UploadEndpointParams } from '../Store/Indicators/Types';

const getAppIcon = (app: keyof Task['ReportLinks'], resources: string): string => {
    const root = 'images/icons';
    switch (app) {
        case 'Inform':
            return resourcesLink(`${root}/LG_inform.png`, resources);
        case 'Toolkit':
        case 'Standards':
            return resourcesLink(`${root}/informplus.png`, resources);
        case 'VfM':
            return resourcesLink(`${root}/vfm.png`, resources);
        case 'DeveloperTools':
            return resourcesLink(`${root}/dev_tools.png`, resources);
    }
}


const reportName = (app: keyof Task['ReportLinks']) => {
    switch (app) {
        case 'Inform':
            return "LG Inform standard report";
        case 'Toolkit':
            return "LG Inform Plus standard report";
        case 'VfM':
            return "VfM standard report";
        case 'Standards':
            return 'Link to standards'
        case 'DeveloperTools':
            return 'Developer Tools data query';

    }
}

const NonTaskToolbar: FC<ToolbarProps> = (props) => {
    const resources = useAppSelector(state => state.defaultModel.commonResourceLocation);
    const canUpdate = useAppSelector(state => state.defaultModel.canUpdate);
    const task = props.row.original;
    const [ upload, {
        isLoading
    } ] = useUploadMutation();
    const uploadTaskClick = useSubmitHandler({
        init: 'Adding metric type to upload queue',
        success: 'Metric type added to upload queue',
        error: 'Failed to add metric type to upload queue'
    }, (payload: UploadEndpointParams)=> upload(payload));

    return (
        <div className="d-flex">

            {props.scope !== 'Task' && task.Indicator ? (
                <Link className="btn btn-link px-0" to={`/MetricType/Details/${task.Indicator.Number}`}>
                    <InfoLink alt="View metric type details"/>
                </Link>
            ) : null}
            {Object.entries(task.ReportLinks)
                .sort((a,b) => a[0].localeCompare(b[0]))
                .map(([app, link]) => (
                <Fragment key={`${task.Id}-${app}`}>
                    <UncontrolledTooltip
                        placement="right"
                        target={queryable(`task-${props.row.id}-${app}-link`)}
                    >
                        {reportName(app as keyof Task['ReportLinks'])}
                    </UncontrolledTooltip>
                    <Button className="px-0" color="link" href={link}
                            id={queryable(`task-${props.row.id}-${app}-link`)}>
                        <img className="button-icon" alt={reportName(app as keyof Task['ReportLinks'])}
                             src={getAppIcon(app as keyof Task['ReportLinks'], resources)}/>
                    </Button>
                </Fragment>
            ))}
            <ButtonWithTooltip
                disabled={isLoading}
                onClick={() => task.Indicator ? uploadTaskClick({
                    type: 'metricType',
                    number: task.Indicator.Number
                }) : null}
                color="link"
                className={classNames('text-dark', { 'd-none': !canUpdate || !task.Indicator })}
                id={`upload-metric-type-${task.Indicator?.Number}`}
                tooltip={{ children: 'Upload metric type' }}
            >
                <Upload/>
            </ButtonWithTooltip>
        </div>
    );

}
export default NonTaskToolbar;