import { useFormikContext } from 'formik';
import { FC, useState } from 'react';

import { MetricTypeJsonModel } from '../../Store/Indicators/Types';
import { Dimension, DimensionMember } from '../../Store/Dimension/Types';
import { useGetDimensionsQuery } from '../../Store/Lists/listApi';
import SimpleSelector from '../../Common/DetailsForm/SimpleSelector';
import { AnyListingSelectorField, ListingSelectorProps } from '../../Common/DetailsForm/AnyListingSelectorField';
import { Link } from 'react-router-dom';
import { SingleValue } from 'react-select';


interface DimensionMemberSelectorsProps extends ListingSelectorProps<DimensionMember> {
    selectedDimension: SingleValue<Dimension>;
    onDimensionChange: (dimension: SingleValue<Dimension>) => void;
    isOptionDisabled: (option: SingleValue<Dimension>) => boolean;
}


const DimensionMemberSelectors : FC<DimensionMemberSelectorsProps> = (props) => {
    const { data, error, isLoading, isFetching } = useGetDimensionsQuery(true);
    const { onDimensionChange, selectedDimension ,onChange, value } = props;
    return (
        <div className="d-flex dimension-selectors">
            <SimpleSelector<Dimension, Dimension[]>
                isOptionDisabled={props.isOptionDisabled}
                isLoading={isLoading || isFetching}
                listing={data}
                placeholder="Select a dimension"
                formatLabel={opt => opt.Label}
                getDataFromListing={opt => opt}
                isSelectedOption={opt => selectedDimension ? opt.Identifier === selectedDimension.Identifier : false} 
                onChange={onDimensionChange}
            />
            <SimpleSelector<DimensionMember, DimensionMember[]>
                isDisabled={!selectedDimension}
                listing={selectedDimension?.DimensionMembers??undefined}
                placeholder={!selectedDimension ? 'Select a dimension first' : 'Select a dimension member'}
                formatLabel={opt => opt.Label}
                getDataFromListing={opt => opt}
                isSelectedOption={opt => value ? opt.Identifier === value.Identifier : false}
                onChange={val => onChange(val && selectedDimension ? {
                    ...val,
                    Dimension: selectedDimension
                } : val)}
            />
        </div>
    )
    
    
    
}

const DimensionMemberDropdown : FC = () => {
    const formProps = useFormikContext<MetricTypeJsonModel>();
    const [ selectedDimension, setSelectedDimension ] = useState<SingleValue<Dimension>>(null);


    const onItemAdded = async (item: DimensionMember) => {
        await formProps.setFieldValue('DimensionMembers', [
            ...formProps.values.DimensionMembers.filter(m => m.Identifier !== item.Identifier),
            item
        ]);
        setSelectedDimension(null);
    };
    return (
        <AnyListingSelectorField<DimensionMember>            
            listingSelector={props => (
                <DimensionMemberSelectors 
                    {...props} 
                    isOptionDisabled={opt => opt ? formProps.values.DimensionMembers.some(dm => dm.Dimension.Identifier === opt.Identifier) : false}
                    selectedDimension={selectedDimension}
                    onDimensionChange={val => setSelectedDimension(val)} 
                />
            )}
            onRemoveItem={index => formProps.setFieldValue('DimensionMembers', formProps.values.DimensionMembers.filter((m,i) => i !== index))}
            onItemAdded={onItemAdded}
            label="Dimension members"            
        >
            {formProps.values.DimensionMembers.map(
                m => <Link key={m.Identifier} to={`/dimension/details/${m.Identifier}`}>{m.Dimension ? `${m.Dimension.Label} - ` : ''} {m.Label}</Link>
            )}
        </AnyListingSelectorField>
    );
}

export default DimensionMemberDropdown;