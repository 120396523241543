import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery, useUpdateDetailsMutation } from '../Store/Measure/measureApi';
import { useParams } from 'react-router';

import SubmitButton from '../Common/SubmitButton';
import MeasureEditForm from './MeasureEditForm';
import { Link } from 'react-router-dom';
import { useNavigateIf, useNavigateOnError, useSubmitHandler } from '../hooks';
import { Measure } from '../Store/Measure/Types';
import { AddResult, UpdateResult } from '../Store/Types';

export const AddMetricTypeButton: FC<{ dataset: number; measure: string }> = ({ dataset, measure }) => (
    <Link className="btn btn-secondary" to={`/metricType/add?dataset=${dataset}&measure=${measure}`}>
        Add metric type
    </Link>
);

export const EditDimensions: FC<{ measure: string }> = ({ measure }) => (
    <Link className="btn btn-secondary" to={`/metricType/dimension?measureIdentifier=${measure}`}>
        Edit metric type dimensions
    </Link>
);

const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading, isFetching } = useGetDetailsQuery(id!);
    const [
        update
    ] = useUpdateDetailsMutation();
    useNavigateIf('/dataset', !id, 'A measure identifier is required');
    useNavigateOnError('/dataset',error,'Error loading measure add page');

    const handler = useSubmitHandler<Measure, UpdateResult>({
        success: 'Measure updated',
        error: 'An error occured while updating the measure',
        init: 'Updating measure'
    }, update);
    
    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: data.Item.Dataset.Collection!.Source.Label, url: `/source/${data.Item.Dataset.Collection!.Source.Number}` },
                { name: data.Item.Dataset.Collection!.Label, url: `/collection/${data.Item.Dataset.Collection!.Number}` },
                { name: data.Item.Dataset.Label, url: `/dataset/${data.Item.Dataset.Number}` },                
                { name: 'Measure details', url: `/measure/details/${data.Item.Identifier}`, active: true },
            ] : undefined }
            title="Measure details"
            refreshing={isFetching}
            loading={isLoading}
            buttons={data ? [ 
                <AddMetricTypeButton dataset={data.Item.Dataset.Number} measure={data.Item.Identifier} />,
                <EditDimensions measure={data.Item.Identifier} />
            ] : []}
        >
            {data
                ? <MeasureEditForm isNew={false} measure={data} onSubmit={handler}>
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </MeasureEditForm>
                : null}
        </Page>
    );
}


export default Details;
