import { FC, HTMLAttributes, useCallback, useMemo } from 'react';
import Table from '../Common/Table';
import useColumns from './Columns';
import { IndicatorTableModel } from '../Store/TaskTable/Types';
import { Row, VisibilityState } from '@tanstack/react-table';
import useGroupedTasks, { isParentTask } from './GroupTasks';
import { TaskGroup } from '../Store/Tasks/Types';


interface TaskTableProps {
    data: IndicatorTableModel;
}


const rowDecorator = (row: Row<TaskGroup>): HTMLAttributes<HTMLTableRowElement> => {
    if (row.original.UpdateMethod === '-') {
        return {};
    }
    return {};
    //
    // const nextCollected = new Date(row.original.NextCollected);
    // const currentDate = new Date();
    // currentDate.setHours(0, 0, 0, 0);
    // const nextWeek = new Date();
    // nextWeek.setDate(currentDate.getDate() + 7);
    // const classes = [];
    //
    // if (row.original.NextCollected) {
    //     if (nextCollected >= currentDate && nextCollected <= nextWeek) {
    //         classes.push('pending');
    //     } else if (nextCollected < currentDate) {
    //         classes.push('overdue');
    //     }
    // }
    // if (row.original.UpdateMethod !== 'Uploaded') {
    //     classes.push('auto');
    // }
    //
    // return {
    //     ...(classes.length > 0 ? { className: classes.join(' ') } : {})
    // };
};

const defaultInitialSearchByScope : Record<IndicatorTableModel['scope'], Record<string, string>> = {
    Task: {
        sort: 'NextToBeLoaded asc'
    },
    Dataset: {
        sort: 'UpdateMethod desc,Dataset'
    },
    Collection: {
        sort: 'UpdateMethod desc'
    },
    Source: {
        sort: 'UpdateMethod desc'
    },
    MetricReportTag: {
        sort: 'MetricType asc'  
    }
};

const TaskTable: FC<TaskTableProps> = (props) => {
    const columnDef = useColumns(props.data);
    const visibilityState = props.data.columns.reduce<VisibilityState>((acc, c) => ({
        ...acc,
        ...(c.enableHiding ? { [c.id]: c.initiallyVisible } : {})
    }), {});
    const tasks = useGroupedTasks(props.data);
    return (
        <Table
            className="tasks"
            getRowProps={rowDecorator}
            data={tasks}
            columns={columnDef}
            initialVisibilityState={visibilityState}
            initialFilters={[
                {
                    id: 'NextToBeLoaded',
                    value: [ null, null, false ]
                }
            ]}
            defaultInitialSearch={defaultInitialSearchByScope[props.data.scope]}
            getSubRows={task => isParentTask(task) ? task.subtasks : undefined}
        />
    );
}

export default TaskTable;
