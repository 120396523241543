import { FC } from 'react';
import { Task, TaskGroup } from '../../Store/Tasks/Types';
import { CalendarEventFill, Clock, Gear } from '../Icons';
import { UncontrolledTooltip } from 'reactstrap';
import EditableDate from '../DetailsForm/EditableDate';

export interface NextToBeLoadedProps {
    id: string;
    task: TaskGroup|Task;
    isEditing: boolean;
    onChange: (date: Date|null) => void;
}

const NextToBeLoadedValue: FC<NextToBeLoadedProps> = ({ id, task, isEditing, onChange }) => {
    const value = task.NextCollected;
    const nextPeriodStart = task.LastTimePeriod && task.LastTimePeriod.EndDate 
        ? new Date(task.LastTimePeriod.EndDate) 
        : undefined;

    if (nextPeriodStart) {
        nextPeriodStart.setSeconds(nextPeriodStart.getSeconds() + 1);
    }
    
    let icon = null;
    let color = null;
    let tooltip = null;
    let nextCollected: Date|null = null;

    if (value && !task.Discontinued) {
        nextCollected = new Date(value);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const nextWeek = new Date();
        nextWeek.setDate(currentDate.getDate() + 7);
        if (nextCollected >= currentDate && nextCollected <= nextWeek && task.UpdateMethod === 'Uploaded') {
            tooltip = 'This tasks is upcoming soon';
            color = '#ff4d4d';
            icon = <CalendarEventFill />;
        } else if (nextCollected < currentDate) {
            tooltip = 'This tasks is overdue';
            color = '#e50000';
            icon = <Clock />;
        }
        if (nextCollected < currentDate && task.UpdateMethod !== 'Uploaded') {
            tooltip = 'This tasks is overdue but is completed automatically';
            color = '#007ff9';
            icon = <Gear />;
        }
    }



    return (
        <>
            <EditableDate isEditing={isEditing} value={nextCollected??undefined} onChange={onChange}>
                { tooltip ? <UncontrolledTooltip target={id}>
                    {tooltip}
                </UncontrolledTooltip> : null }
                <span style={color ? { color } : undefined} id={id}>
                    {icon ?? null}
                    {' '}
                    {
                        task.Discontinued
                            ? 'Discontinued'
                            : (nextCollected === null || isNaN(nextCollected.getTime()) ? '-' : nextCollected.toLocaleDateString())
                    }
                </span>
            </EditableDate>

        </>
    );
}


export default NextToBeLoadedValue;