import { FC, useEffect } from 'react';
import Page from '../Common/Page';
import { useNavigate } from 'react-router';
import { useCreateTaskMutation, useGetTaskDetailsQuery } from '../Store/Tasks/tasksApi';
import { useSearchParams } from 'react-router-dom';
import { Task } from '../Store/Tasks/Types';
import TaskForm from './TaskForm';
import { toTaskJsonModel } from '../TaskTable/TaskToolbar';
import SubmitButton from '../Common/SubmitButton';
import { toast } from 'react-toastify';
import { useAppSelector } from '../Store/store';

const Create: FC = () => {
    const canUpdate = useAppSelector(state => state.defaultModel.canUpdate);
    const [ searchParams ] = useSearchParams();
    const navigate = useNavigate();
    const parentTask = searchParams.get('parentTask');
    const parentTaskNumber = parentTask ? Number(parentTask) : null;
    if (!parentTaskNumber && !Number.isFinite(parentTaskNumber)) {
        toast.error('New tasks can only be created as overrides of existing tasks');
        navigate('/');        
    }
    useEffect(() => {
        if (!canUpdate) {
            toast.error('You need to be signed in and have the appropriate permissions to add task overrides');
            navigate('/');
        }    
    }, []);
    
    const { data, isLoading, isFetching } = useGetTaskDetailsQuery(parentTaskNumber!, {
        skip: !parentTaskNumber
    });    
    
    const [ createTask ] = useCreateTaskMutation();

    const submitHandler = async (task: Task) => {
        const id = toast.loading('Creating task override');
        const res = await createTask(toTaskJsonModel(task));
        if (res.data && !res.error && res.data.success) {
            navigate(`/task/details/${res.data.id}`);
            toast.done(id);
        } else {
            toast.error('Error saving task');
            toast.done(id);
        }
    }
    
    
    
    return (
        <Page
            title="Create task"
            breadcrumbTrail={[
                { name: 'Home', url: '/' },
                { name: `Create task`, url: `/task/add`, active: true },
            ]}
            loading={isLoading || isFetching || !data}
        >
            { data ? <TaskForm 
                task={data.Task} 
                isNew 
                onSubmit={submitHandler}>
                {(formProps) =>  <SubmitButton formProps={formProps} />}
            </TaskForm> : null }
        </Page>
    );
}


export default Create;
