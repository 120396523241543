import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery } from '../Store/Dimension/dimensionApi';
import { useParams } from 'react-router';

import SubmitButton from '../Common/SubmitButton';
import DimensionEditForm from './DimensionEditForm';



const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading } = useGetDetailsQuery(id!);
    

    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: 'Dimension member details', active: true },
            ] : undefined }
            title="Dimension member details"
            loading={isLoading}
        >
            {data
                ? <DimensionEditForm dimension={data}>
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </DimensionEditForm>
                : null}
        </Page>
    );
}


export default Details;
