import { PropsWithChildren, ReactNode, useState } from 'react';
import { Button } from 'reactstrap';
import { DashLg } from '../Icons';
import DropdownSelector, { DropdownSelectorProps } from './DropdownSelector';
import { AnyListingSelectorField, AnyListingSelectorFieldProp } from './AnyListingSelectorField';
import { GroupBase } from 'react-select';


export interface ListingWrapperProps {
    onRemoveItem: () => void;
    canUpdate: boolean;
    className?: string;
    busy?: boolean;
}




export interface ListingSelectorFieldProp<T, TListing, TGroup extends GroupBase<T> = GroupBase<T>> extends Omit<AnyListingSelectorFieldProp<T>, 'listingSelector'|'disabled'|'error'>, Omit<DropdownSelectorProps<T, TListing, TGroup>, 'onChange' | 'value' | 'isSelectedOption'> {
    label: ReactNode;
    children: ReactNode[];
    onRemoveItem: (index: number) => void;
    onItemAdded: (item: T) => void;
    areEqual?: (a: T, b: T) => boolean;
}

export const ListingWrapper = <T, >(
    { 
        children, onRemoveItem, canUpdate, className, busy 
    }: PropsWithChildren<ListingWrapperProps>
) => (
    <li className={className}>
        {children}
        {canUpdate ? <Button disabled={busy} color="link" className="text-danger" onClick={onRemoveItem}>
            <DashLg/>
        </Button> : null}
    </li>
)

export const ListingSelectorField = <T extends Record<string, any>, TListing, TGroup extends GroupBase<T> = GroupBase<T>>(
    { 
        areEqual = ((a: T, b: T) => a===b),
        onItemAdded,
        isDisabled,
        ...props 
    }: ListingSelectorFieldProp<T, TListing, TGroup>
) => {
    const [selectedItem, setSelectedItem] = useState<T>();
    const myOnItemAdded = (item: T) => {
        onItemAdded(item);
        setSelectedItem(undefined);        
    }
    return (
        <AnyListingSelectorField listingSelector={innerProps => (
            <DropdownSelector
                onChange={item => {
                    if (item) {
                        setSelectedItem(item);
                        innerProps.onChange(item);
                    }
                }}
                isSelectedOption={item => !!selectedItem && areEqual(item, selectedItem!)}
                hookResult={props.hookResult}
                getDataFromListing={props.getDataFromListing}
                formatLabel={props.formatLabel}
                formatLabelNode={props.formatLabelNode}
                formatGroupLabel={props.formatGroupLabel}
                error={!!props.error}
                isDisabled={isDisabled}
            />
        )} onItemAdded={myOnItemAdded} {...props} disabled={() => !!isDisabled}>
            {props.children}
        </AnyListingSelectorField>
    )
}