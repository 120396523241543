import { CSSProperties, FC } from 'react';
import Page from '../../Common/Page';
import {
    useGetDimensionConfigurationQuery,
    useUpdateDimensionConfigurationMutation
} from '../../Store/Dimension/dimensionApi';
import { useSearchParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import useMetricTypeDimensionColumns from './columns';
import { MetricTypeDimensionIndicator, MetricTypeDimensionsModel } from '../../Store/Dimension/Types';
import { Measure } from '../../Store/Measure/Types';
import {
    Column,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table';
import { Table } from 'reactstrap';
import PaginationControl from '../../Common/Table/PaginationControl';
import SortableHeader from '../../Common/Table/SortableHeader';
import FormToolkit from '../../Common/DetailsForm/FormToolkit';
import SubmitButton from '../../Common/SubmitButton';
import { useNavigateIf, useNavigateOnError, useSubmitHandler } from '../../hooks';
import { UpdateResult } from '../../Store/Types';


const getCommonPinningStyles = (column: Column<MetricTypeDimensionIndicator>): CSSProperties => {
    const isSticky = column.columnDef.meta?.sticky;
    const stickyLeft = column.columnDef.meta?.stickyStart;

    return {
        boxShadow: column.columnDef.meta?.stickyShadow
            ? '-4px 0 4px -4px gray inset'
            : undefined,
        left: isSticky ? `${stickyLeft ?? 0}px` : undefined,
        opacity: isSticky ? 0.95 : 1,
        position: isSticky ? 'sticky' : 'relative',
        width: column.getSize(),
        minWidth: column.columnDef.minSize,
        zIndex: isSticky ? 1 : 0,
    }
}

const Dimensions: FC = () => {
    const [query] = useSearchParams();
    const { data, error, isLoading, isFetching } = useGetDimensionConfigurationQuery(query.get('measureIdentifier')!, {
        skip: !query.has('measureIdentifier')
    });
    const [ update ] = useUpdateDimensionConfigurationMutation();    
    useNavigateIf('/', !query.has('measureIdentifier'), 'The "measureIdentifier" parameter is required')
    useNavigateOnError(`/measure/details/${query.get('measureIdentifier')}`, error, 'Error loading dimension configuration');
    const initialValues: MetricTypeDimensionsModel = {
        Measure: {} as Measure,
        Dimensions: [],
        Indicators: [],
        TotalsOverride: {}
    }
    const handler = useSubmitHandler<MetricTypeDimensionsModel, UpdateResult>({
        success: 'Metric type dimensions updated',
        error: 'An error occurred while updating the metric type dimensions',
        init: 'Updating metric type dimensions'
    }, update);
    

    return (
        <Page
            loading={isLoading}
            refreshing={isFetching}
            wide
            title="Metric type dimensions"
            breadcrumbTrail={[
                {
                    name: 'Home',
                    url: '/'
                },
                {
                    name: data?.Measure?.Dataset?.Collection?.Source?.Label ?? '',
                    url: `/Source/${data?.Measure?.Dataset?.Collection?.Source?.Number}`
                },
                {
                    name: data?.Measure?.Dataset?.Collection?.Label ?? '',
                    url: `/Collection/${data?.Measure?.Dataset?.Collection?.Number}`
                },
                {
                    name: data?.Measure?.Dataset?.Label ?? '',
                    url: `/Dataset/${data?.Measure?.Dataset?.Number}`
                },
                { name: data?.Measure?.Label ?? '', url: `/Measure/Details/${data?.Measure?.Identifier}` },
                { name: 'Metric type dimensions', active: true },
            ]}
        >
            <Formik initialValues={data ?? initialValues} enableReinitialize onSubmit={handler}>
                {formProps => {
                    const data = formProps.values;
                    const columns = useMetricTypeDimensionColumns(data);
                    const table = useReactTable({
                        data: data.Indicators,
                        columns,
                        getCoreRowModel: getCoreRowModel(),
                        getPaginationRowModel: getPaginationRowModel(),
                        getSortedRowModel: getSortedRowModel(),
                    });

                    return (
                        <Form>
                            <div className={`${(isLoading ? 'loading ' : '')}dimension-table-container`}>
                                <PaginationControl table={table} isTop/>
                                <Table className="metric-dimension-table">
                                    <thead>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        <tr key={headerGroup.id}>
                                            {headerGroup.headers.map(header => (
                                                <th key={header.id} colSpan={header.colSpan}
                                                    style={{ ...getCommonPinningStyles(header.column) }}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : (header === headerGroup.headers[headerGroup.headers.length - 1] ?
                                                            <SortableHeader header={header}/> : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            ))}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                    </thead>
                                    <tbody>
                                    {table.getRowModel().rows.map(row => (
                                        <tr key={row.id}>
                                            {row.getVisibleCells().map(cell => (
                                                <td
                                                    key={cell.id}
                                                    style={{ ...getCommonPinningStyles(cell.column) }}
                                                >
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                                <PaginationControl table={table} isTop={false}/>
                            </div>
                            <FormToolkit>
                                <SubmitButton formProps={formProps}/>
                            </FormToolkit>
                        </Form>
                    )
                }}
            </Formik>
        </Page>
    );
}

export default Dimensions;