import { ChangeEventHandler, FC, useState } from 'react';
import { DatasetApplicableGeoLevel, DatasetFormApplicableGeoLevel } from '../../Store/Dataset/Types';
import { GeoAreaLevel } from '../../Store/GeoArea/Types';
import { Button, Input } from 'reactstrap';
import { DashLg } from '../../Common/Icons';
import { EditButton } from '../../Common/DetailsForm/BasicInput';

interface AreaTypeUpdateMethodProps {
    canUpdate: boolean;
    datasetAreaLevel: DatasetFormApplicableGeoLevel;
    childAreaTypes: GeoAreaLevel[];
    onDiscontinueToggle: () => void;
    onRemoveOption: () => void;
    onEditOption: (newAggregatedSource: GeoAreaLevel|null) => void;
}

export const formatUpdateMethod = (inputMethod: DatasetFormApplicableGeoLevel) => {
    if (inputMethod.InputType == null) {
        return "";
    }
    if (inputMethod.InputType.Label === 'Uploaded' && inputMethod.Discontinued) {
        return "discontinued";
    }
    return inputMethod.InputType.Label.toLowerCase() + (
        inputMethod.InputType.IsAggregated ? " from" : ""
    );
}
export const NoAggregation = '(nothing - do not aggregate)';


const EditableAggregationSource: FC<AreaTypeUpdateMethodProps> = (props) => {
    const editable = props.datasetAreaLevel.InputType?.IsAggregated;
    const [ editing, setEditing ] = useState(false);
    const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const option = props.childAreaTypes.find(opt => e.currentTarget.value === opt.Identifier)??null; 
        props.onEditOption(option);
        setEditing(false);
    }

    return (
        !editing ? <>
            <strong>{props.datasetAreaLevel.AggregatedFromAreaType?.Name ?? NoAggregation}</strong>                
            <EditButton canUpdate={props.canUpdate} editable={editable} onSetEditing={() => setEditing(true)} />
        </> : (
            <Input type="select" value={props.datasetAreaLevel.AggregatedFromAreaType?.Identifier??''} onChange={onChange} onBlur={() => setEditing(false)}>
                <option value="">{NoAggregation}</option>
                {props.childAreaTypes.map(at => (
                    <option value={at.Identifier}>{at.Name}</option>
                ))}
            </Input>
        )
    );
}

const AreaTypeUpdateMethod: FC<AreaTypeUpdateMethodProps> = (props) => {
    const removable = !props.datasetAreaLevel.InputType?.IsAggregated;
    
    return (
        <li>
            <strong>
                {props.datasetAreaLevel.AreaType.Name}
            </strong>{' '}
            {formatUpdateMethod(props.datasetAreaLevel)}
            { props.datasetAreaLevel.InputType?.IsAggregated 
                ? <EditableAggregationSource {...props} /> 
                : null }
            { removable && props.canUpdate ? 
                <>
                    <Button style={{ textTransform: 'none' }} color="link" onClick={props.onDiscontinueToggle}>{ props.datasetAreaLevel.Discontinued ? 'recontinue' : 'discontinue' }</Button>
                    <Button color="link" className="text-danger" onClick={props.onRemoveOption}>
                        <DashLg/>
                    </Button>
                </>
            : null }
        </li>
    );
}

export default AreaTypeUpdateMethod;