import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery } from '../Store/MeasurementUnit/measurementUnitApi';
import { useParams } from 'react-router';

import SubmitButton from '../Common/SubmitButton';
import MeasurementUnitEditForm from './MeasurementUnitEditForm';



const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading } = useGetDetailsQuery(id!);
    

    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: 'Measurement unit details', url: `/measurementUnit/details/${data.Item.Identifier}`, active: true },
            ] : undefined }
            title="Measure details"
            loading={isLoading}
        >
            {data
                ? <MeasurementUnitEditForm measurementUnit={data}>
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </MeasurementUnitEditForm>
                : null}
        </Page>
    );
}


export default Details;
