import { createApi } from '@reduxjs/toolkit/query/react'
import { Source, SourceAddModel, SourceModel, SourceUpdateModel } from './Types';
import { AddResult, NewDetailsApiModel, UpdateResult } from '../Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const sourceApi = createApi({
    reducerPath: 'sourceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/source/'
    }),
    tagTypes: ['Source'],
    endpoints: (builder) => ({
        getAddPage: builder.query<NewDetailsApiModel<Source>, void>({
            query: () => `addSource`,
            providesTags: [{ type: 'Source', id: 'Add' }]
        }),
        getDetails: builder.query<SourceModel, number>({
            query: (source) => `details/${source}`,
            providesTags: (result?: SourceModel) => result ? [{ type: 'Source', id: result.Item.Number }] : []
        }),

        updateSource: builder.mutation<UpdateResult, SourceUpdateModel>({
            query: (data) => ({
                url: `update`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, param) => result ? [{ type: 'Source', id: param.Number }] : []
        }),
        addSource: builder.mutation<AddResult, SourceAddModel>({
            query: (data) => ({
                url: 'add',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, param) => result ? [{type: 'Source', id: 'List' }] : []
        })
    }),
})

export const {
    useGetDetailsQuery, useGetAddPageQuery,
    useUpdateSourceMutation, useAddSourceMutation
} = sourceApi;

export default sourceApi;