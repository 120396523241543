import { FC } from 'react';
import { Task, TaskGroup } from '../Store/Tasks/Types';
import { useAppDispatch, useAppSelector } from '../Store/store';
import { tasksSlice } from '../Store/Tasks/tasksSlice';
import EditableDate from '../Common/DetailsForm/EditableDate';
import { Button, Row } from 'reactstrap';

type PublishedValueProps = {
    task: TaskGroup|Task;
};

const PublishedValue: FC<PublishedValueProps> = ({ task }) => {
    const dispatch = useAppDispatch();
    const editingTask = useAppSelector(state => state.taskSlice?.task);
    const isEditing = editingTask?.Id === task.Id;

    const value = editingTask?.Published ?? task.Published;
    const published = value ? new Date(value) : undefined;
    const changeHandler = (date: Date|null) => {
        dispatch(tasksSlice.actions.changeDateProperty({
            property: 'Published',
            newValue: date?.toISOString()??null
        }))
    }

    return (
        <EditableDate
            onChange={changeHandler}
            isEditing={isEditing}
            value={!published || Number.isNaN(published.valueOf()) ? undefined : published}
            toolbar={<Button onClick={() => changeHandler(new Date())}>Today</Button>}
        />        
    );
}


export default PublishedValue;