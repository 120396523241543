import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { DetailsFormAdditionalContext } from './DetailsForm';
import classNames from 'classnames';
import { DetailedTableBasicInput } from './Inputs';
import { Button, Col, Row } from 'reactstrap';
import { HoverHelpPopup, HoverProps } from '../HelpPopup';

export interface BaseProps {
    id?: string;
    align?: 'start' | 'center' | 'end';
    row?: boolean;
    label: ReactNode;
    className?: string;
    hoverProps?: HoverProps;
    required?: boolean;
    error?: ReactNode;
}
export type DetailedTableRowProps =  BaseProps & {    
    children: ReactNode;
};

export type InputFieldProps<T> = {
    value: T | null | undefined;
    onChange?: (newValue: T | null | undefined) => void;
    onDoneEditing: () => void;
};
export type InputField<T> = FC<InputFieldProps<T>>;


export const detailedTableTextGetter = (value: any): string => {
    switch (typeof value) {
        case 'boolean':
            return value ? 'Yes' : 'No';
        case 'string':
        case 'number':
            return (value as string|number).toString();
        case 'object':
            return (value as any)?.Label??(value as any)?.Name??(value as any)?.Identifier??(value as any)?.Id??'';
        default:
            return ((value??'') as any).toString();
    }
}


export interface DetailedTableEditableRowProps<TValue> extends BaseProps {
    id?: string;
    name?: string;
    value?: TValue;
    formatValue?: (value: TValue|null|undefined) => ReactNode;
    editable?: boolean;
    alwaysEditing?: boolean;
    onChange?: (newVal: TValue|null|undefined) => void;
    inputField?: InputField<TValue>;
    useNumberForNumericField?: boolean;
    append?: ReactNode;
}



export const EditButton : FC<{ editable: boolean; canUpdate: boolean; onSetEditing: () => void }> = (props) => (
    props.editable && props.canUpdate ? <Button color="link" onClick={props.onSetEditing}>edit</Button> : null
);


export const DetailedTableRow: FC<DetailedTableRowProps> = (props) => {
    const { hoverHelp } = useContext(DetailsFormAdditionalContext);
    return (
        <Row id={props.id} className={classNames(`align-items-${props.align ?? 'start'} py-2`,props.className)}>
            <Col xs={6} sm={4} md={3} className={classNames('font-weight-bold d-flex align-items-center detailed-table-row-label', {
                required: props.required,
                error: props.error
            } )}>
                {props.label} {props.hoverProps && hoverHelp
                ? <HoverHelpPopup
                    hoverHelp={hoverHelp}
                    id={props.hoverProps.id??props.id}
                    name={props.hoverProps.name}
                    text={props.hoverProps.text}

                /> : null}                
            </Col>
            <Col xs={6} sm={8} md={9} className={`detailed-table-row-value ${props.row ? ' row' : ''}`}>
                {props.children}
            </Col>
        </Row>
    );
}




export const DetailedTableEditableRow = <TValue,>(props: DetailedTableEditableRowProps<TValue>) => {
    const { canUpdate, isNew } = useContext(DetailsFormAdditionalContext);
    const {
        alwaysEditing = isNew,
        editable = true,
        formatValue = detailedTableTextGetter,
        value
    } = props;
    const [ editing, setEditingInner ] = useState<boolean>(editable && alwaysEditing);
    const setEditing = (val: boolean) => {
        if (alwaysEditing) {
            setEditingInner(true);
        } else if (editable) {
            setEditingInner(val);
        } else {
            setEditingInner(false);
        }
    }

    useEffect(() => {
        props.onChange?.(value);
    }, [ value ]);


    const onDoneEditing = () => setEditing(false);

    return (
        <DetailedTableRow
            id={props.id}
            label={props.label}
            hoverProps={props.hoverProps ? { ...props.hoverProps, name: props.name, id: props.id } : undefined}
            align={props.align}
            row={props.row}
            className={classNames(props.className, { editing })}
            required={props.required}
            error={props.error}
        >
            {
                editable && editing
                    ? (props.inputField ? <props.inputField onDoneEditing={onDoneEditing} onChange={props.onChange} value={value} /> : (
                        <>
                            <DetailedTableBasicInput
                                id={props.id}
                                onChange={props.onChange}
                                value={value}
                                name={props.name}
                                onDoneEditing={onDoneEditing}
                                useNumberForNumericField={props.useNumberForNumericField}
                                error={props.error}                                
                           />                            
                        </> 
                    ))
                    : <>
                        {formatValue(props.value)}
                        <EditButton
                            editable={editable}
                            canUpdate={canUpdate}
                            onSetEditing={() => setEditing(true)}
                        />
                    </>
            }{
                props.append ? props.append : null
            }
        </DetailedTableRow>
    );
}