import { useRouteError } from 'react-router';
import Page from './Common/Page';
import { formatErrorMessage } from './Utils';

export function ErrorBoundary() {
    const error = useRouteError();
    if (error) {
        return (
            <Page>
                <h1>Error</h1>                
                <p>{formatErrorMessage(error)}</p>
                { process.env.NODE_ENV !== 'production' && error instanceof Error ? (
                    <>
                        <p>{error.message}</p>
                        <pre>
                            {error.stack}
                        </pre>
                    </>
                ) : null }
            </Page>
        );
    }
    return null;
}