import { FC, useContext, useState } from 'react';
import { TabContent, TabPane, Nav, NavLink, Button, Row, Col, Alert } from 'reactstrap';
import { DetailedTableRow } from '../../Common/DetailsForm/BasicInput';
import DropdownSelector from '../../Common/DetailsForm/DropdownSelector';
import { useGetSubjectHierarchyQuery, useGetSubjectQuery } from '../../Store/Lists/listApi';
import { MetricTypeJsonModel, Subject } from '../../Store/Indicators/Types';
import { PlusLg } from '../../Common/Icons';
import { useFormikContext } from 'formik';
import { DetailsFormAdditionalContext } from '../../Common/DetailsForm/DetailsForm';
import LongControlGroup from '../../Common/DetailsForm/LongControlGroup';
import { Link } from 'react-router-dom';
import { SubjectHierarchy } from '../../Store/Lists/Types';
import { SingleValue } from 'react-select';

type Tabs = 'functions' | 'circumstances' | 'tags' | 'services';


const typeToTab: Record<Tabs, [number, string]> = {
    circumstances: [1, 'Circumstances'],
    functions: [2, 'Functions'],
    // No themes?
    tags: [4, 'Report tags'],
    services: [5, 'Services'],
}

const SubjectLink: FC<Pick<Subject, 'Number'|'Name'|'IndicatorSubjectType'|'Uri'>> = (props) => {
    switch (props.IndicatorSubjectType) {
        case 4:
            return <Link to={`/metricType/reportTags/${props.Number}`}>{props.Name}</Link>
        default:
            return <Button color="link" target="_blank" href={props.Uri}>{props.Name}</Button>
    }
}



interface SubjectHierarchyDropdownSelectorProps { 
    type: number;
    selected?: SingleValue<Subject>;
    onChange: (selected: SingleValue<Subject>) => void;
    disabledValues: number[];
}
const SubjectHierarchyDropdownSelector: FC<SubjectHierarchyDropdownSelectorProps> = (props) => {
    const { type, selected, onChange, disabledValues } = props;
    const { filteredHierarchy, ...rest } = useGetSubjectHierarchyQuery(undefined, {
        selectFromResult: ({ data })=> ({
            filteredHierarchy: data?.find(h => h.Parent.IndicatorSubjectType === type)
        })
    });
    const flattenHierarchy = (hierarchy: SubjectHierarchy, level = -1): (Subject&{Level: number})[] => {
        if (level === -1) {
            return hierarchy.Children.flatMap(c => flattenHierarchy(c, level+1));
        }
        return [{ ...hierarchy.Parent, Level: level }].concat(
            hierarchy.Children.flatMap(c => flattenHierarchy(c, level+1))
        );
    }
    
    
    return (
        <DropdownSelector<Subject&{Level: number}, SubjectHierarchy>
            hookResult={{ data: filteredHierarchy, ...rest }}
            formatLabel={opt => opt.Name}
            formatLabelNode={opt => <span style={{ marginLeft: `${opt.Level*0.5}rem` }}>{opt.Name}</span>}
            getDataFromListing={flattenHierarchy}
            isSelectedOption={opt => selected?.Number === opt.Number}
            onChange={onChange}
            isOptionDisabled={opt => disabledValues.includes(opt.Number)}
        />
    );
}

const ServiceTab: FC<{ type: Tabs }> = (props) => {
    const typeNumber = typeToTab[props.type];
    const [selected, setSelected] = useState<Subject>();
    const ctx = useFormikContext<MetricTypeJsonModel>();
    const { canUpdate } = useContext(DetailsFormAdditionalContext);
    const filteredItems = ctx.values.IndicatorSubjects.reduce<Record<number, MetricTypeJsonModel['IndicatorSubjects'][number]>>((acc, item, idx) => ({
        ...acc,
        ...(item.IndicatorSubjectType === typeNumber[0] ? { [idx]: item } : {})
    }), {});

    return (
        <>
            {canUpdate ? (
                <Row className="pt-3">
                    <Col>
                        <SubjectHierarchyDropdownSelector
                            type={typeNumber[0]}
                            selected={selected}
                            onChange={val => setSelected(val??undefined)}
                            disabledValues={ctx.values.IndicatorSubjects.map(s => s.Number)}
                        />
                    </Col>
                    <Col xs="auto">
                        <Button disabled={!selected} color="link" className="text-success"
                                onClick={() => {
                                    if (selected) {
                                        ctx.setFieldValue('IndicatorSubjects', [
                                            ...ctx.values.IndicatorSubjects,
                                            {
                                                Name: selected.Name,
                                                Number: selected.Number,
                                                IndicatorSubjectType: selected.IndicatorSubjectType
                                            } as MetricTypeJsonModel['IndicatorSubjects'][number]
                                        ]).then();
                                        setSelected(undefined);
                                    }
                                }}>
                            <PlusLg/>
                        </Button>
                    </Col>
                </Row>
            ) : null
            }
            <Row>
                <Col style={{ minHeight: '10rem' }}>
                    <LongControlGroup
                        canUpdate={canUpdate}
                        onRemoveItem={idx => ctx.setFieldValue('IndicatorSubjects', [
                            ...ctx.values.IndicatorSubjects.slice(0, Number(Object.keys(filteredItems)[idx])),
                            ...ctx.values.IndicatorSubjects.slice(Number(Object.keys(filteredItems)[idx]) + 1)
                        ])}
                        noItems={<Alert color="info">No {typeNumber[1]} selected</Alert>}
                    >
                        {Object
                            .values(filteredItems)
                            .map(
                                item => (
                                    <SubjectLink key={item.Number} {...item} />)
                            )}
                    </LongControlGroup>
                </Col>
            </Row>
        </>
    );
}

const Services: FC = () => {
    const [activeTab, setActiveTab] = useState<Tabs>('functions');

    const toggle = (tab: Tabs) => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <DetailedTableRow label="Subjects">
            <Nav tabs>
                {Object.entries(typeToTab).map(tab => (
                    <NavLink
                        key={tab[0]}
                        className={activeTab === tab[0] ? 'active p-3' : 'p-3'}
                        onClick={() => toggle(tab[0] as Tabs)}
                    >
                        {tab[1][1]}
                    </NavLink>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {Object.keys(typeToTab).map(tab => (
                    <TabPane key={tab} tabId={tab as Tabs}>
                        <ServiceTab type={tab as Tabs}/>
                    </TabPane>
                ))}
            </TabContent>
        </DetailedTableRow>
    )
}

export default Services;