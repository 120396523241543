import { FC, useMemo } from 'react';
import Page from '../Common/Page';
import { useParams } from 'react-router';
import {
    useGetMetricTypeCalculationDataQuery,
    useGetMetricTypeDetailsQuery,
    useGetMetricTypeLoadedDetailsQuery, useUpdateMetricTypeMutation
} from '../Store/Indicators/indicatorApi';
import SubmitButton from '../Common/SubmitButton';
import MetricTypeDetailsForm from './DetailsForm';
import {
    CalculationFieldValue,
    CalculationJsonModel,
    IndicatorDetailsApiModel,
    MetricTypeJsonModel, SubmissionLag
} from '../Store/Indicators/Types';
import { toast } from 'react-toastify';
import { useNavigateOnError, useSubmitHandler } from '../hooks';
import { AddResult, UpdateResult } from '../Store/Types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DownloadAllMetricsButton, UploadButton } from '../Widgets/Buttons';
import { RefreshDataset } from '../Dataset/Details';

export const indicatorApiModelToJson = (
    data: IndicatorDetailsApiModel,
    calculationData: CalculationJsonModel|null
): MetricTypeJsonModel =>  ({
    ...data.Item, 
    StatXploreParameters: data.Item.StatXploreParameters?.length > 0 ? data.Item.StatXploreParameters[0] : null,
    PHEMetricTypeMapping: data.Item.PHEMetricTypeMapping?.length > 0 ? data.Item.PHEMetricTypeMapping[0] : null,
    SubmissionLag: data.Item.SubmissionLag ? {
        Value: data.Item.SubmissionLag.Number,
        Identifier: data.Item.SubmissionLag.TimeIntervalType?.Identifier,
        Label: data.Item.SubmissionLag.TimeIntervalType?.Name,
    } as SubmissionLag : undefined,
    Replacements: (data.Item.Replacements??[]).map(r => ({
        Number: r.ReplacedBy.Number,
        Label: r.ReplacedBy.Label
    })),
    DimensionMembers: data.Item.IndicatorDimensionMemberLinks.map(m => ({
        Identifier: m.DimensionMember.Identifier,
        Label: m.DimensionMember.Label,
        Dimension: {
            Identifier: m.DimensionMember.Dimension.Identifier,
            Label: m.DimensionMember.Dimension.Label,
        }
    })),
    CalculationParameters: calculationData,
    IndicatorSubjects: (data.Item.IndicatorSubjects??[]).map(s => ({
        Number: s.Subject.Number,
        Name: s.Subject.Name,
        IndicatorSubjectType: s.Subject.IndicatorSubjectType,
        Uri: s.Subject.Uri
    })),
    AlternateLabels: data.Item.AlternateLabels?.map(l => l.Label) ?? [],
    ClientApplications: (data.Item.IndicatorPermissions??[]).map(p => p.WebServiceClientApp),
    IsImportedFromNomis: data.Item.AutoDownloadConfigs != null && data.Item.AutoDownloadConfigs.length > 0 
} as MetricTypeJsonModel);

const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading, isFetching } = useGetMetricTypeDetailsQuery(Number(id));
    const { data:updateData } = useGetMetricTypeLoadedDetailsQuery(Number(id));
    const { data: calculationData, isLoading: isLoadingCalculationData } = useGetMetricTypeCalculationDataQuery(Number(id));
    const formData = useMemo(() => data && calculationData !== undefined 
        ? indicatorApiModelToJson(data, calculationData)
        : undefined, [data,calculationData]);
    const [ submitTrigger ] = useUpdateMetricTypeMutation();

    const handler = useSubmitHandler<MetricTypeJsonModel, UpdateResult>({
        success: 'Metric type updated',
        error: 'An error occured while updating the metric type',
        init: 'Updating metric type'
    }, submitTrigger);

    useNavigateOnError('/', error, 'Unable to load metric type details');
    return (
        <Page
            loading={isLoading}
            refreshing={!isLoading && isFetching}
            wide
            title="Metric type details"
            buttons={data ? [
                <RefreshDataset dataset={data.Item.Measure.Dataset.Number} />,
                <Link className="btn btn-secondary" to={`/metricType/changeDataset/${data.Item.Number}`}>
                    Change dataset
                </Link>,
                <UploadButton type="metricType" number={data.Item.Number} />,
                <DownloadAllMetricsButton metricNumber={data.Item.Number}>
                    Metric type upload template
                </DownloadAllMetricsButton>                
            ] : []}
            breadcrumbTrail={[
                {
                    name: 'Home',
                    url: '/'
                },
                {
                    name: data?.Item?.Measure?.Dataset?.Collection?.Source?.Label ?? '',
                    url: `/Source/${data?.Item?.Measure?.Dataset?.Collection?.Source?.Number}`
                },
                {
                    name: data?.Item?.Measure?.Dataset?.Collection?.Label ?? '',
                    url: `/Collection/${data?.Item?.Measure?.Dataset?.Collection?.Number}`
                },
                {
                    name: data?.Item?.Measure?.Dataset?.Label ?? '',
                    url: `/Dataset/${data?.Item?.Measure?.Dataset?.Number}`
                },
                { name: data?.Item?.Measure?.Label ?? '', url: `/Measure/Details/${data?.Item?.Measure?.Identifier}` },
                { name: data?.Item?.Label ?? '', active: true },
                { name: 'Metric type details', active: true },
            ]}
        >
            {formData && data
                ? <MetricTypeDetailsForm 
                    isNew={false} 
                    canEditSecurityType={data.CanEditSecurityType}
                    CalculationValuesMessage={data.CalculationValuesMessage}
                    Polarity={data.Polarity}
                    SecurityTypeInput={data.SecurityTypeInput}
                    indicator={{
                        Item: formData,
                        HoverHelp: data.HoverHelp,
                        CreatedDescription: data.CreatedDescription,
                        ModifiedDescription: data.ModifiedDescription
                    }}
                    dataset={data.Item.Measure.Dataset}
                    lastUpdatedDetails={updateData}
                    onSubmit={handler}
                >
                    {(formProps) => <SubmitButton formProps={formProps}/>}
                </MetricTypeDetailsForm>
                : null}
        </Page>
    );
}

export default Details;