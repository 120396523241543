import { ApplicableGeoAreaLevels, Dataset, DatasetFormModel, DatasetJsonModel } from '../Store/Dataset/Types';

export const toDatasetJson = (
    dataset: DatasetFormModel, isNew: boolean
): DatasetJsonModel => ({
    ...(!isNew ? { Number: dataset.Number } : {}),
    Label: dataset.Label,
    ApplicableGeoAreaLevels: dataset.ApplicableGeoLevel.map(level => ({
        GeoAreaLevelIdentifier: level.AreaType.Identifier,
        InputMethodId: level.InputType.Id,
        Discontinued: level.Discontinued,
        GeoAreaLevelAggregatedFromIdentifier: level.AggregatedFromAreaType?.Identifier,
    } as ApplicableGeoAreaLevels)),
    Discontinued: dataset.Discontinued,
    ShortLabel: dataset.ShortLabel,
    IsSpecificToOrganisation: dataset.IsSpecificToOrganisation,
    ReplacedByDataset: dataset.ReplacedBy?.map(r => r.Number) ?? [],
    CollectionNumber: dataset.Collection!.Number,
    TimePeriodTypeIdentifier: dataset.PrimaryPeriodType?.Identifier
} as DatasetJsonModel)