import { FC } from 'react';
import Page from '../Common/Page';
import TaskTable from '../TaskTable';
import { DownloadAllMetricsButton, UploadButton } from '../Widgets/Buttons';
import { useGetLatestIndicatorTableForDatasetQuery } from '../Store/TaskTable/taskTableApi';
import { useParams } from 'react-router';
import TaskTableHead from '../TaskTable/TaskTableHead';
import { useNavigateIf, useNavigateOnError } from '../hooks';
import { AddIndicatorButton, AddMeasureButton, RefreshDataset } from './Details';

const CollectionTasks: FC = () => {
    const { id } = useParams<'id'>();
    const number = Number(id);
    const { data: loadedData, error, isLoading } = useGetLatestIndicatorTableForDatasetQuery(Number(id));
    useNavigateIf('/dataset', !id || Number.isNaN(number) || number == 0, 'A valid dataset number is required.');
    useNavigateOnError('/dataset', error, 'An error occured loading dataset data');
    return (
        <Page wide loading={isLoading} buttons={[
            <RefreshDataset dataset={number} />,
            <UploadButton type="dataset" number={number} />,
            <AddMeasureButton key="measure-button" dataset={number}/>,
            <AddIndicatorButton key="indicator-button" dataset={number}/>,
            <DownloadAllMetricsButton datasetNumber={number}>
                Metric type upload template
            </DownloadAllMetricsButton> ,
            <DownloadAllMetricsButton allMetricTypes>
                Download all metric types
            </DownloadAllMetricsButton>
        ]}
              breadcrumbTrail={[
                  { name: 'Home', url: '/' },
                  { name: loadedData?.collection.Label??'', url: `/collection/${id}`, active: true },
              ]}
        >
            { !isLoading && loadedData ? <TaskTableHead loadedData={loadedData} /> : null }
            { !isLoading && loadedData ? <TaskTable data={loadedData} /> : null }
        </Page>
    );
}

export default CollectionTasks;
