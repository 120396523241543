import { FC, ReactNode } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { Row } from 'reactstrap';

export interface EditableDateProps {
    children?: ReactNode;
    isEditing: boolean;
    onChange: (date: Date|null) => void;
    value?: Date;
    toolbar?: ReactNode;
}

const EditableDate: FC<EditableDateProps> = ({ value, children, isEditing, onChange, toolbar }) => {
    
    return (
        isEditing ?
            <DatePicker selected={value} onChange={onChange}>
                {toolbar}
            </DatePicker>
            : (children??value?.toLocaleDateString()??'-')        
    );
}


export default EditableDate;