import { createContext } from 'react';
import { Formik, FormikConfig, FormikValues } from 'formik';
import { RootState } from '../../Store/store';
import { useSelector } from 'react-redux';
import { HoverHelpEntry } from '../../Store/Types';
import { toast } from 'react-toastify';
import { useBlocker } from 'react-router';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';

export interface DetailsFormProps {
    canUpdate: boolean;
    isNew: boolean;
    hoverHelp?: HoverHelpEntry[];
}

export const DetailsFormAdditionalContext = createContext<DetailsFormProps>({
    isNew: false,
    canUpdate: false
});

export type FormikDetailsFormProps<Values extends FormikValues = FormikValues, ExtraProps = {}>  = Pick<DetailsFormProps, 'isNew'|'hoverHelp'> & FormikConfig<Values> & ExtraProps; 

const FormikDetailsForm = <Values extends FormikValues = FormikValues, ExtraProps = {}>(
    { 
        isNew,
        hoverHelp,
        children,
        ...props }: FormikDetailsFormProps<Values, ExtraProps>) => {
   
    const canUpdate = useSelector<RootState>(state => state.defaultModel.canUpdate) as boolean;
    return (
       <DetailsFormAdditionalContext.Provider value={{ isNew, hoverHelp, canUpdate,  }}>
           <Formik enableReinitialize={!isNew} {...props}>
               {formProps => {
                   const blocker = useBlocker(() => formProps.dirty && !formProps.isSubmitting);

                   const toggle = () => blocker.state === 'blocked' ? blocker.reset() : null;
                   return (
                       <>                       
                       { typeof children === 'function' ? children(formProps) : children }
                       <Modal
                           isOpen={blocker.state === 'blocked'}
                           toggle={toggle}                           
                       >
                           <ModalBody>
                               <Row className="pb-5">
                                   <Col>If you navigate away any unsaved changes will be lost.</Col>
                               </Row>
                               <Row>
                                   <Col>
                                       <Button color="primary" onClick={() => blocker.state === 'blocked' ? blocker.proceed() : null}>Ok</Button>
                                       <Button onClick={toggle}>Cancel</Button>
                                   </Col>
                               </Row>
                           </ModalBody>
                       </Modal>
                    </>
                   );
               }}
           </Formik>
       </DetailsFormAdditionalContext.Provider> 
    )
}

export default FormikDetailsForm;