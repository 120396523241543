import { MetricTypeJsonModel } from '../../Store/Indicators/Types';
import { FormikErrors } from 'formik';

const metricTypeValidator = (values: MetricTypeJsonModel): FormikErrors<MetricTypeJsonModel>|void => {
    const required  : (keyof MetricTypeJsonModel)[] = [
        'Measure', 'Label', 'ShortLabel', 'FormattedHelpText'
    ];
    let errors = required.reduce<FormikErrors<MetricTypeJsonModel>>((acc, path) => ({
        ...acc,
        ...(!values[path] ? {[path]: 'Value is required and cannot be empty' } : {})
    }), {});
    if (!!values.StatXploreParameters && !values.StatXploreParameters.Database) {
        (errors as any).StatXploreParameters = { Database: "Database is required" };
    }
    if (!!values.StatXploreParameters && !values.StatXploreParameters.Measure) {
        errors.StatXploreParameters = {
            ...((errors as any).StatXploreParameters??{}),
            Measure: "Measure is required"
        }
    }
    if (!!values.PHEMetricTypeMapping && !values.PHEMetricTypeMapping.PHEMetricTypeID) {
        (errors as any).PHEMetricTypeMapping = {
            PHEMetricTypeID: "Metric type ID is required"
        }
    }

    return errors;
}


export default metricTypeValidator;  