import { FC } from 'react';
import QueueSection from './QueueSection';
import { useGetAllItemsQuery } from '../Store/Queue/queueApi';


const RunningTasks : FC = () => {

    const { data, error, isLoading } = useGetAllItemsQuery()


    return (
        <QueueSection
            heading="Currently running"
            subheading="Any items from the sections below which are running now (where possible to detect) plus the currently running calculation (if any)"
            headers={['Type','Description','Triggered by','Created']}
            mapper={v => [ v.type, v.description, v.triggeredBy, v.created ]}
            loading={isLoading}
            values={data ? data.runningItems.concat(data.uploadItems.filter(i => i.isRunning)).concat(data.batchJobItems.filter(i => i.isRunning)) : undefined}
            noDataMessage="No recalculations, uploads or batch jobs running"
            error={error}
        />
    );
}

export default RunningTasks;