import { FC, ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import QueueTable from './QueueTable';
import { QueueTask } from '../Store/Queue/Types';

export interface QueueTableProps {
    headers: string[];
    loading?: boolean;
    values?: QueueTask[];
    error?: any;
    noDataMessage?: string;
    mapper: (item: QueueTask) => string[];
}

interface QueueSectionProps extends QueueTableProps {
    heading: string;
    subheading: string|ReactNode;
}


const QueueSection: FC<QueueSectionProps> = (props) => {
    return (
        <Row>
            <Col xs={12}>
                <h2 className="queue-header">{props.heading}</h2>
                <h4 className="header-align-fixed-width-table sub-header">
                    {props.subheading}
                </h4>
            </Col>
            <Col xs={12}>
                <QueueTable
                    {...props}
                />
            </Col>

        </Row>
    );
}


export default QueueSection;