import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery, useUpdateDetailsMutation } from '../Store/Licence/licenceApi';
import { useParams } from 'react-router';

import SubmitButton from '../Common/SubmitButton';
import LicenceEditForm from './LicenceEditForm';
import { useNavigateIf, useNavigateOnError, useSubmitHandler } from '../hooks';
import { Measure } from '../Store/Measure/Types';
import { UpdateResult } from '../Store/Types';
import { Licence } from '../Store/Licence/Types';



const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading, isFetching } = useGetDetailsQuery(id!);
    
    useNavigateIf('/', !id, 'A licence identifier is required');
    useNavigateOnError('/',error,'Error loading licence page');
    const [ update ] = useUpdateDetailsMutation();
    
    const handler = useSubmitHandler<Licence, UpdateResult>({
        success: 'Licence updated',
        error: 'An error occured while updating the licence',
        init: 'Updating licence'
    }, update);

    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: 'Licence details', active: true },
            ] : undefined }
            title="Licence details"
            loading={isLoading}
            refreshing={isFetching}
        >
            {data
                ? <LicenceEditForm licence={data} onSubmit={handler}>
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </LicenceEditForm>
                : null}
        </Page>
    );
}


export default Details;
