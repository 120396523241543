import { FC } from 'react';
import { Task } from '../../Store/Tasks/Types';
import { useFormikContext } from 'formik';
import NextToBeLoadedValue from './NextToBeLoaded';

export interface NextToBeLoadedDetailProps {
    isEditing: boolean;    
}

const NextToBeLoadedDetail: FC<NextToBeLoadedDetailProps> = ({ isEditing }) => {
    const { values, setFieldValue } = useFormikContext<Task>();
    return (
        <NextToBeLoadedValue
            onChange={(date: Date|null) => setFieldValue('NextCollected', date)}
            isEditing={isEditing}
            id={`NL-edited-task`}
            task={values}
        />
    );
}

export default NextToBeLoadedDetail;