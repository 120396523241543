import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Task, TaskEditState } from './Types';

const initialState : TaskEditState|null = null;

export const tasksSlice = createSlice({
    name: 'taskSlice',
    initialState: initialState as TaskEditState|null,
    reducers: {
        editExisting(state, action: PayloadAction<Task>) {
            state = {
                task: action.payload,
                isNew: false
            };
            return state;
        },
        doneEditing(state) {
            state = null;
            return null;
        },
        changeDateProperty(state, action: PayloadAction<{ property: 'NextCollected'|'Published', newValue: string|null }>) {
            if (state) {
                state.task[action.payload.property] = action.payload.newValue;
            }
        }
    },
})