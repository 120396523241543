import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery, useUpdateDetailsMutation } from '../Store/Collection/collectionApi';
import { useParams } from 'react-router';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import CollectionEditForm, { CollectionFormProps } from './CollectionEditForm';
import SubmitButton from '../Common/SubmitButton';
import { CollectionUpdateModel } from '../Store/Collection/Types';
import { toast } from 'react-toastify';
import { useRefreshCollectionMutation } from '../Store/Collection/collectionApi';
import { useNavigateOnError, useSubmitHandler } from '../hooks';
import { UploadButton } from '../Widgets/Buttons';


export const AddDatasetButton: FC<{ collection: number }> = ({ collection }) => (
    <Link className="btn btn-secondary" to={`/dataset/add?collection=${collection}`}>
        Add dataset
    </Link>
);

export const RefreshCollection: FC<{ collection: number }> = ({ collection }) => {
    const  [ trigger ] = useRefreshCollectionMutation();
    const handler = () => toast.promise(trigger(collection), {
       pending: 'Refreshing collection',
       success: 'Collection refreshed',
       error: 'Failed to refresh collection' 
    });
    return (
        <Button color="secondary" onClick={handler}>
            Refresh
        </Button>
    );
}

const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading, isFetching } = useGetDetailsQuery(Number(id));
    const [
        update
    ] = useUpdateDetailsMutation();
    useNavigateOnError('/collection', error, 'An error occured while loading collection details');
    
    const handler = useSubmitHandler({
        success: 'Collection updated',
        error: 'An error occured while updating the collection',
        init: 'Updating collection'
    }, update);
    
    const handleSubmit = async (collection: CollectionFormProps) => {
        return handler({
            Number: collection.Number,
            SourceNumber: collection.Source.Number,
            Label: collection.Label,
            Url: collection.Url,
            Discontinued: collection.Discontinued,
            IsLocalCollectionRequired: collection.IsLocalCollectionRequired,
            ReplacesCollection: collection.ReplacedBy?.map(c => c.Number)
        } as CollectionUpdateModel);
    }

    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: data.Item.Source.Label, url: `/source/${data.Item.Source.Number}` },
                { name: data.Item.Label, url: `/collection/${data.Item.Number}` },
                { name: 'Collection details', url: `/collection/details/${data.Item.Number}`, active: true },
            ] : undefined }
            buttons={data ? [
                <RefreshCollection key="refresh-collection-button" collection={Number(id)} />,
                <UploadButton type="collection" number={data.Item.Number}/>,
                <AddDatasetButton key="dataset-button" collection={Number(id)}/>
            ] : [] }
            title="Collection details"
            loading={isLoading}
            refreshing={isFetching}
        >
            {data
                ? <CollectionEditForm isNew={false}  collection={data} onSubmit={handleSubmit}>
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </CollectionEditForm>
                : null}
        </Page>
    );
}


export default Details;
