import { FC } from 'react';
import { Link } from 'react-router-dom';
import InfoLink from '../Common/InfoLink';
import ButtonWithTooltip from '../Common/ButtonWithTooltip';
import { ArrowCounterclockwise, Floppy, NodePlusFill, PencilFill, Upload, XCircle } from '../Common/Icons';
import { useAppDispatch, useAppSelector } from '../Store/store';
import { useRefreshTaskMutation, useUpdateTaskMutation } from '../Store/Tasks/tasksApi';
import { toast } from 'react-toastify';
import { ToolbarProps } from './Toolbar';
import LinkWithTooltip from '../Common/LinkWithTooltip';
import { tasksSlice } from '../Store/Tasks/tasksSlice';
import { Task, TaskJsonModel } from '../Store/Tasks/Types';
import classNames from 'classnames';
import { useUploadMutation } from '../Store/Indicators/indicatorApi';
import { useSubmitHandler } from '../hooks';
import { UploadEndpointParams } from '../Store/Indicators/Types';

export const toTaskJsonModel = (task: Task): TaskJsonModel => ({
    Id: task.Id,
    CollectionNumber: task.Collection.Number,
    Discontinued: task.Discontinued,
    DatasetNumber: task.Dataset?.Number,
    GeoAreaLevelIdentifier: task.GeoAreaLevel?.Identifier,
    NextCollected: task.NextCollected??undefined,
    LastTimePeriodId: task.LastTimePeriodId,
    IndicatorNumber: task.Indicator?.Number,
    Notes: task.Notes??undefined,
    LastCollected: task.LastCollected,
    Published: task.Published??undefined,
    UpdateMethod: task.UpdateMethod,
    NextTimePeriodId: task.NextTimePeriodId,
    Attachments: task.TaskAttachments.reduce<Record<string,string>>((acc, att) => ({
        ...acc,
        [att.Name]: att.Url
    }), {})
});

const TaskToolbar: FC<ToolbarProps> = (props) => {
    const task = props.row.original;
    const canUpdate = useAppSelector(state => state.defaultModel.canUpdate);
    const [update, {
        isLoading
    }] = useRefreshTaskMutation();

    const [ upload, {
        isLoading: isTaskUploading
    } ] = useUploadMutation();
    
    const [updateTask, {
        isLoading: isTaskUpdateLoading
    }] = useUpdateTaskMutation();

    const editingTask = useAppSelector(
        state => state.taskSlice
    );
    const dispatch = useAppDispatch();
    const updateTaskClick = () => toast.promise(async () => {
        if (!editingTask) return;
        await updateTask(toTaskJsonModel(editingTask.task));
        dispatch(tasksSlice.actions.doneEditing())
    }, {
        pending: 'Updating task',
        success: 'Task updated',
        error: 'Error updating task',
    });

    
    const uploadTaskClick = useSubmitHandler({
        init: 'Adding task to upload queue',
        success: 'Task added to upload queue',
        error: 'Failed to add task to upload queue'
    }, (payload: UploadEndpointParams)=> upload(payload));
    
    const onClickWithToast = () => toast.promise(update(task.Id), {
        pending: 'Refreshing task',
        success: 'Task refreshed',
        error: 'Error refreshing task',
    });

    const editing = !!editingTask && editingTask.task.Id === task.Id;


    return (
        <fieldset disabled={isLoading || isTaskUpdateLoading || isTaskUploading}>
            <div className="d-flex">

                <ButtonWithTooltip
                    id={`task-${task.Id}-save`}
                    color="link"
                    className={classNames('text-dark', { 'd-none': !canUpdate || !editing })}
                    tooltip={{ children: 'Save task' }}
                    onClick={updateTaskClick}
                >
                    <Floppy/>
                </ButtonWithTooltip>
                <ButtonWithTooltip
                    id={`task-${task.Id}-cancel`}
                    color="link"
                    className={classNames('text-dark', { 'd-none': !canUpdate || !editing })}
                    tooltip={{ children: 'Cancel' }}
                    onClick={() => dispatch(tasksSlice.actions.doneEditing())}
                >
                    <XCircle/>
                </ButtonWithTooltip>
                <ButtonWithTooltip
                    id={`task-${task.Id}-edit`}
                    color="link"
                    className={classNames('text-dark', { 'd-none': !canUpdate || editing })}
                    tooltip={{ children: 'Edit task' }}
                    onClick={() => dispatch(tasksSlice.actions.editExisting(task))}
                >
                    <PencilFill/>
                </ButtonWithTooltip>
                <LinkWithTooltip
                    id={`task-${task.Id}-create-override`}
                    to={`/task/add?parentTask=${task.Id}`}
                    className={classNames('btn btn-link text-dark', { 'd-none': !canUpdate || editing })}
                    tooltip={{ children: 'Create task override' }}
                >
                    <NodePlusFill/>
                </LinkWithTooltip>
                <Link
                    className={classNames('btn btn-link text-dark', { 'd-none': editing })}
                    to={`/Task/Details/${task.Id}`}
                >
                    <InfoLink alt="View task details"/>
                </Link>
                <ButtonWithTooltip
                    disabled={isLoading}
                    onClick={onClickWithToast}
                    color="link"
                    className={classNames('text-dark', { 'd-none': !canUpdate || editing })}
                    id={`refresh-task-${task.Id}`}
                    tooltip={{ children: 'Refresh task' }}
                >
                    <ArrowCounterclockwise/>
                </ButtonWithTooltip>
                <ButtonWithTooltip
                    disabled={isLoading}
                    onClick={() => uploadTaskClick({
                        type: 'task',
                        number: task.Id
                    })}
                    color="link"
                    className={classNames('text-dark', { 'd-none': !canUpdate || editing })}
                    id={`upload-task-${task.Id}`}
                    tooltip={{ children: 'Upload task' }}
                >
                    <Upload/>
                </ButtonWithTooltip>
            </div>
        </fieldset>
    );
}
export default TaskToolbar;