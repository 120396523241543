import { FC } from 'react';
import Page from '../Common/Page';
import TaskTable from '../TaskTable';
import { DownloadAllMetricsButton } from '../Widgets/Buttons';
import {
    useGetLatestIndicatorTableForSourceQuery
} from '../Store/TaskTable/taskTableApi';
import { useParams } from 'react-router';
import TaskTableHead from '../TaskTable/TaskTableHead';
import { useNavigateIf, useNavigateOnError } from '../hooks';
import { AddCollectionButton } from './Details';

const SourceTasks: FC = () => {
    const { id } = useParams<'id'>();
    const number = Number(id);
    const { data: loadedData, error, isLoading } = useGetLatestIndicatorTableForSourceQuery(number);
    useNavigateIf('/source', !id || Number.isNaN(number) || number == 0, 'A valid source number is required.');
    useNavigateOnError('/source', error, 'An error occured loading source data');

    return (
        <Page wide loading={isLoading} buttons={[
            <AddCollectionButton key="collection-button" source={number}/>,
            <DownloadAllMetricsButton sourceNumber={number}>
                Metric type upload template
            </DownloadAllMetricsButton> ,
            <DownloadAllMetricsButton allMetricTypes>
                Download all metric types
            </DownloadAllMetricsButton>
        ]}
              breadcrumbTrail={[
                  { name: 'Home', url: '/' },
                  { name: loadedData?.source.Label??'', url: `/source/${id}`, active: true },
              ]}
        >
            { !isLoading && loadedData ? <TaskTableHead loadedData={loadedData} /> : null }
            { !isLoading && loadedData ? <TaskTable data={loadedData} /> : null }
        </Page>
    );
}

export default SourceTasks;
