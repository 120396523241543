import { FC } from 'react';
import Page from '../Common/Page';
import { useParams } from 'react-router';
import { useGetTaskDetailsQuery, useUpdateTaskMutation } from '../Store/Tasks/tasksApi';
import TaskForm from './TaskForm';
import SubmitButton from '../Common/SubmitButton';
import { toTaskJsonModel } from '../TaskTable/TaskToolbar';
import { Task } from '../Store/Tasks/Types';
import { toast } from 'react-toastify';

const Details: FC = () => {
    const { id } = useParams<'id'>();
    if (!id) {
        return <p>No route specified</p>;
    }
    const { data, error, isLoading, isFetching } = useGetTaskDetailsQuery(Number(id));
    const [updateDetails] = useUpdateTaskMutation();

    const submitHandler = async (task: Task) => {
        const id = toast.loading('Updating task');
        const res = await updateDetails(toTaskJsonModel(task));
        if (res.data && !res.error && res.data.success) {
            toast.success('Task updated');
            toast.done(id);
        } else {
            toast.error('Error updating task');
            toast.done(id);
        }
    }



    return (
        <Page
            title="Task details"
            loading={isLoading}
            refreshing={isFetching}
            breadcrumbTrail={[
                { name: 'Home', url: '/' },
                { name: `Task details (id: ${id})`, url: `/task/details/${id}`, active: true },
            ]}
        >
            {data ? (
                <TaskForm 
                    isNew={false}
                    task={data.Task}
                    onSubmit={submitHandler}
                >
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </TaskForm>
            ) : null}
        </Page>
    );
}


export default Details;
