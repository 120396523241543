import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, FormProps, Input, InputProps } from 'reactstrap';
import { useState } from 'react';
import { useSearchParam } from '../../hooks';

const tabs : Record<string, string> = {
    Tasks: '/',
    Sources: '/source',
    Collections: '/collection',
    Datasets: '/dataset',
    Queues: '/queue',
    Search: '/search'
}

const Tabs = () => {
    const nav = useLocation();
    const navigate = useNavigate();

    let active = nav.pathname === '/'
        ? 'Tasks' : (
            Object.keys(tabs).find(k => k !== 'Tasks' && nav.pathname.toLowerCase().startsWith(tabs[k]))
        );
    
    const handleSubmit: FormProps['onSubmit'] = (e) => {
        e.preventDefault();
        navigate(`/search/results?searchTerm=${encodeURIComponent(e.currentTarget.searchTerm.value)}`)
    }

    return (
        <div id="tabs">
            <ul>
                { Object.entries(tabs).filter(([name,path]) => active === 'Search' || name !== 'Search').map(([ name, path ]) => (
                    <li><Link to={path} className={`tab${active! === name ? ' active':''}`}>{name}</Link></li>
                ))}
                { active !== 'Search' ? <Form inline className="d-inline my-2 my-lg-0" onSubmit={handleSubmit}>
                    <Input className="mr-sm-2" type="search" name="searchTerm" placeholder="Search for metrics" />
                </Form> : null }
            </ul>
        </div>
    );
}

export default Tabs;