import { FC, ReactNode } from 'react';


interface ButtonContainerProps {
    children: ReactNode[];
}

const ButtonContainer : FC<ButtonContainerProps> = (props) => {

    return (
        <div id="button-container">
            {props.children}
        </div>
    );
}

export default ButtonContainer;
