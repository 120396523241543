import { FC, useState } from 'react';
import Page from '../Common/Page';
import { useSearchParams } from 'react-router-dom';
import SubmitButton from '../Common/SubmitButton';
import DatasetEditForm from './DatasetEditForm';
import { useGetAddPageQuery, useAddMutation } from '../Store/Dataset/datasetApi';
import { useNavigateOnError, useSubmitHandler } from '../hooks';
import { DatasetFormModel, DatasetJsonModel } from '../Store/Dataset/Types';
import { AddResult } from '../Store/Types';
import { toDatasetJson } from './util';


const AddDataset: FC = () => {
    const [ query ] = useSearchParams();
    const collection = query.get('collection');
    const { data, error, isLoading, isFetching } = useGetAddPageQuery(collection ? Number(collection) : undefined);
    const [addMeasure,setAddMeasure]=useState(false);
    const [
        add
    ] = useAddMutation();
    useNavigateOnError('/dataset',error,'Error loading dataset add page');
    const handler = useSubmitHandler<DatasetJsonModel, AddResult>({
        success: 'Dataset added',
        error: 'An error occured while adding the dataset',
        init: 'Adding dataset'
    }, add, (res) => addMeasure ? `/measure/add?dataset=${res.id}` : `/dataset/details/${res.id}` 
    );

    const handleSubmit = async (dataset: DatasetFormModel) => {
        return handler(toDatasetJson(dataset, true));
    }
    

    
    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: 'Add dataset', active: true },
            ] : undefined }
            title="Add dataset"
            loading={isLoading}
            refreshing={isFetching}
        >
            {data
                ? <DatasetEditForm isNew={true} dataset={data} onSubmit={handleSubmit}>
                    {(formProps) =>
                        <>
                            <SubmitButton formProps={formProps} />
                            <SubmitButton formProps={formProps} label="Save and add measure" onClick={() => setAddMeasure(true)} />
                        </>
                    }
                </DatasetEditForm>
                : null}
        </Page>
    );
}


export default AddDataset;
