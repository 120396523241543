import { useAppSelector } from './Store/store';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import {
    DependencyList,
    Dispatch,
    FormEventHandler,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo, useRef,
    useState
} from 'react';
import { ItemErrors } from './Common/Types';
import { UpdateResult } from './Store/Types';
import type { MaybePromise } from '@reduxjs/toolkit/src/query/tsHelpers';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { formatErrorMessage } from './Utils';
import { useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';

export const useRequireEditRights = () => {
    const canUpdate = useAppSelector(state => state.defaultModel.canUpdate);
    const navigate = useNavigate();

    useEffect(() => {
        if (!canUpdate) {
            toast.warning('You do not have permission to access that page');
            navigate('/');
        }
    }, []);
}



export const useFieldsState =  <T extends object>(
    initial: T,
    onSubmit: (vals: T) => Promise<void>|void,
    validation?: (item: T) => ItemErrors<T>|undefined
) => { 
    const [ values, setValues ] = useState<T>(initial);    
    const setFieldValue = <TKey extends keyof T>(field: TKey, value: T[TKey]) => {
        setValues(prev => ({ ...prev, [field]: value }));
    }
    const onFormSubmit: FormEventHandler<HTMLFormElement> = () => {
        onSubmit(values);
    }
    
    const errors = useMemo(() => {
        return validation?.(values);
    }, [ values, validation ]);
    
    return {
        values,
        setFieldValue,
        onFormSubmit,
        errors
    };
}


type ToastMessages = {
    init: string;
    error: string;
    success?: string;
};


export const useSubmitHandler = <TIn,TOut extends UpdateResult>(
    toastMessages: ToastMessages,
    handler: (obj: TIn) => MaybePromise<QueryReturnValue<TOut>>,
    redirectTo?: (res: TOut) => string,
) => {
    const nav = useNavigate();
    return useCallback(async (obj: TIn) => {
        const id = toast.loading(toastMessages.init);
        try {
            const result = await handler(obj);
            if (result.error) {
                toast.update(id, {
                    render: formatErrorMessage(result.error, toastMessages.error),
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000
                });
                return;
            } else if (result.data && !result.data.success) {
                toast.update(id, {
                    render: formatErrorMessage(result.error, toastMessages.error),
                    type: 'error',
                    isLoading: false,
                    autoClose: 5000
                });
                return;
            } else if (result.data && redirectTo) {
                nav(redirectTo(result.data));
            }
            if (toastMessages.success) {
                toast.update(id, {
                    render: toastMessages.success,
                    type: 'success',
                    isLoading: false,
                    autoClose: 5000
                }); 
            } else {
                toast.done(id);
            }
        } catch (e) {
            toast.update(id, {
                render: formatErrorMessage(e,  toastMessages.error),
                type: 'error',
                isLoading: false,
                autoClose: 5000
            });
        }
    }, [ toastMessages, handler, redirectTo ]);


}

export const useNavigateOnError = (to: string, error: any, defaultMessage: string = 'An error occured') => {
    const [ navigating, setNavigating ] = useState<boolean>(false);
    const nav = useNavigate();
    useEffect(() => {
        if (error && !navigating) {
            setNavigating(true);
            toast.error(formatErrorMessage(error, defaultMessage));
            nav(to);
        }
    }, [ error, to, defaultMessage, nav, navigating ])
}

export const useNavigateIf = (to: string, condition: boolean, message: string = 'An error occured') => {
    const [navigating, setNavigating] = useState<boolean>(false);
    const nav = useNavigate();
    useEffect(() => {
        if (condition && !navigating) {
            setNavigating(true);
            toast.error(formatErrorMessage(message));
            nav(to);
        }
    }, [condition, to, message, nav, navigating])
}

export const useSearchParam = (param: string): [ string|null, Dispatch<SetStateAction<string|null>> ] => {
    const [ query, setQuery ] = useSearchParams();
    const value = query.get(param);
    const setValue = useCallback((val: SetStateAction<string|null>) => {
        const nextValue = typeof val === 'function' ? val(value) : val;
        if (nextValue !== null) {
            query.set(param, nextValue);    
        } else {
            query.delete(param);
        }
        setQuery(query);
    }, [ value, setQuery ]);
    
    return [ value, setValue ];
}
