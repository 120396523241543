import { createApi } from '@reduxjs/toolkit/query/react'
import { TaskList } from './Types';
import fetchBaseQuery from '../baseQuery';

// Define a service using a base URL and expected endpoints
export const queueApi = createApi({
    reducerPath: 'queueApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/queue/'
    }),
    endpoints: (builder) => ({
        getAllItems: builder.query<TaskList, void>({
            query: () => '',
        }),
    }),
})

export const { useGetAllItemsQuery } = queueApi;

export default queueApi;