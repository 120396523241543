import { FC } from 'react';
import { Button, FormGroup, Input, Label, Row } from 'reactstrap';
import { Task, TaskAttachment } from '../Store/Tasks/Types';
import { AnyListingSelectorField } from '../Common/DetailsForm/AnyListingSelectorField';
import { useFormikContext } from 'formik';

const Attachments: FC<{ attachments: TaskAttachment[]; }> = (props) => {
    const ctx = useFormikContext<Task>();
    return (
        <AnyListingSelectorField<TaskAttachment>
            label="File attachments"
            onItemAdded={item => ctx.setFieldValue('TaskAttachments', [
                ...ctx.values.TaskAttachments,
                item
            ])}
            disabled={item => !(item?.Name && item?.Url)}
            onRemoveItem={idx => ctx.setFieldValue('TaskAttachments', ctx.values.TaskAttachments.filter(
                (item, index) => index !== idx
            ))}
            listingSelector={props => (
                <Row className="align-items-center">
                    <Label className="col-auto">Name</Label>
                    <FormGroup className="col">                        
                        <Input value={props.value?.Name??''} onChange={e => props.onChange({
                            ...(props.value??{
                                Id: 0,
                                Url: '',
                                TaskId: 0
                            }),
                            Name: e.currentTarget.value
                        })} />
                    </FormGroup>
                    <Label className="col-auto">URL</Label>
                    <FormGroup className="col">
                        <Input value={props.value?.Url??''} onChange={e => props.onChange({
                            ...(props.value??{
                                Id: 0,
                                Name: '',
                                TaskId: 0
                            }),
                            Url: e.currentTarget.value
                        })} />
                    </FormGroup>
                </Row>
            )}
        >

            {props.attachments.map(a => (
                <a href={a.Url}>{a.Name}</a>
            ))}
        </AnyListingSelectorField>
    );

}

export default Attachments;