import { FC, ReactNode, useMemo } from 'react';
import { Collection, CollectionListing, CollectionListingEntry } from '../Store/Collection/Types';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import ChangeDetails from '../Common/DetailsForm/ChangeDetails';
import {
    DatasetApiModel,
    DatasetListing,
} from '../Store/Dataset/Types';
import { FormikErrors, FormikProps, Form } from 'formik';
import HookedDropdownSelectorField from '../Common/DetailsForm/HookedDropdownSelectorField';
import { TimePeriodType } from '../Store/TimePeriodType/Types';
import { IconWithTooltip, Padlock } from '../Common/Icons';
import {
    useGetAllTimePeriodsQuery,
    useGetCollectionListQuery, useGetDatasetListQuery
} from '../Store/Lists/listApi';
import AreaTypeUpdateMethods from './AreaTypeUpdateMethods';
import InfoLink from '../Common/InfoLink';
import { Link } from 'react-router-dom';
import DetailsForm from '../Common/DetailsForm/DetailsForm';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';
import ListingSelectorFormField from '../Common/DetailsForm/ListingSelectorFormField';
import { DatasetFormModel } from '../Store/Dataset/Types';
import { NumberedEntity } from '../Store/Types';

interface DatasetEditFormPropsBase {
    onSubmit: (values: DatasetFormModel) => void | Promise<void>;
    children: (props: FormikProps<DatasetFormModel>) => ReactNode;
}


interface NewDatasetEditFormProps extends DatasetEditFormPropsBase {
    isNew: true;
    dataset: DatasetApiModel;
}

interface ExistingDatasetEditFormProps extends DatasetEditFormPropsBase {
    isNew: false;
    dataset: DatasetApiModel;
}

type DatasetEditFormProps = NewDatasetEditFormProps | ExistingDatasetEditFormProps;

const datasetValidator = (dataset: DatasetFormModel): FormikErrors<DatasetFormModel> | undefined => {
    const errors: FormikErrors<DatasetFormModel> = {};
    if (!dataset.Collection) {
        errors.CollectionId = 'Collection is required';
    }
    if (!dataset.Label) {
        errors.Label = 'Label must not be empty';
    }
    if (!dataset.ShortLabel) {
        errors.ShortLabel = 'Short Label must not be empty';
    }
    if (dataset.PrimaryPeriodType === null) {
        errors.PrimaryPeriodType = 'Time period is required';
    }
    return Object.entries(errors).length > 0 ? errors : undefined;
}

const TimePeriodLabel: FC<{ option?: TimePeriodType; editable: boolean }> = ({ option, editable }) => (
    option 
    ?
        <>
            {option.Name}
            {!editable
                ? <IconWithTooltip id="dataset-time-period-editor" tooltip="Time period cannot be edited because dataset contains data" icon={Padlock} />
                : null}
        </>
    : '-'
);



const DatasetEditForm: FC<DatasetEditFormProps> = (props) => {
    const values: DatasetFormModel = useMemo(() => ({
        ...props.dataset.Item,
        ReplacedBy: props.dataset.Item.ReplacedBy?.map(d => d.ReplacedBy)??[],
        ApplicableGeoLevel: props.dataset.Item.ApplicableGeoLevel?.map(level => ({
            AreaType: level.GeoAreaLevel,
            InputType: level.IndicatorInputMethod,
            AggregatedFromAreaType: level.AggregatedFromLevel
        } as DatasetFormModel['ApplicableGeoLevel'][number])) ?? [],
        Measures: props.dataset.Item.Measures??[],
        PrimaryPeriodType: props.dataset.Item.PrimaryPeriodType?.TimePeriodType??null
    }), [ props.dataset.Item ]);
    

    return (
        <DetailsForm
            isNew={props.isNew}
            hoverHelp={props.dataset.HoverHelp}
            initialValues={values}
            enableReinitialize
            onSubmit={props.onSubmit}
            validate={datasetValidator}
            validateOnMount={!props.isNew}
        >
            {formProps => (
                <Form>
                    <DetailedTableHeader
                        label={props.isNew ? 'Add dataset'  : 'Dataset details'}
                        hoverProps={{ id: 'dataset-heading' }}
                    />
                    <Container>
                        {!props.isNew ? <ChangeDetails {...props.dataset} /> : null}
                        {!props.isNew ? <DetailedTableRow
                            label="Identifier"
                            hoverProps={{ id: 'dataset-heading' }}
                        > 
                            {formProps.values.Number}
                        </DetailedTableRow> : null }
                        <HookedDropdownSelectorField<DatasetFormModel, 'Collection', CollectionListingEntry, CollectionListing>
                            context={formProps}
                            hoverProps={{ id: 'collection-heading' }}
                            name="Collection"
                            label="Collection"
                            required
                            hookResult={useGetCollectionListQuery()}
                            getDataFromListing={listing => listing.Collections}                            
                            editable
                            formatLabel={opt => `${opt.CollectionLabel} (id: ${opt.CollectionNumber})`}
                            formatValue={val => val ? `${val.Label} (id: ${val.Number})` : '-'}
                            isSelectedOption={opt => opt.CollectionNumber === formProps.values.Collection?.Number}
                            onChange={val => formProps.setValues(prev => ({
                                ...prev,
                                Collection: {
                                    Id: val.CollectionId,
                                    Number: val.CollectionNumber,
                                    Label: val.CollectionLabel
                                } as Collection,
                                CollectionId: val.CollectionId
                            }))}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            editable
                            required
                            name="Label"
                            label="Label"                            
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            editable
                            required
                            name="ShortLabel"
                            label="Short Label"
                            
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            editable
                            name="IsSpecificToOrganisation"
                            label="Specific to organisation"
                            
                        />
                        <ListingSelectorFormField<DatasetFormModel, 'ReplacedBy', NumberedEntity, DatasetListing>
                            context={formProps}
                            name="ReplacedBy"
                            label="Replaced by"
                            hoverProps={{ name: 'ReplacedByID' }}
                            hookResult={useGetDatasetListQuery()}
                            getDataFromListing={(listing: DatasetListing) => listing.Datasets.map(opt => ({
                                Id: opt.DatasetId,
                                Number: opt.DatasetNumber,
                                Label: opt.DatasetLabel
                            } as NumberedEntity))}
                            formatLabel={item => `${item.Label} (id: ${item.Number})`}                            
                            identify={a => a.Number.toString()}
                        />                            
                        <DetailedTableFormRow
                            context={formProps}
                            editable
                            name="Discontinued"
                            label="Discontinued"
                            
                        />
                        <HookedDropdownSelectorField<DatasetFormModel, 'PrimaryPeriodType', TimePeriodType, TimePeriodType[]>
                            context={formProps}
                            required
                            editable={props.isNew || props.dataset.IsEditable}
                            name="PrimaryPeriodType"
                            label="Time Period"
                            alwaysEditing={props.isNew}
                            hoverProps={{ id: 'time-period-heading' }}
                            isSelectedOption={opt => formProps.values.PrimaryPeriodType?.Id === opt.Id}
                            getDataFromListing={v => v}
                            hookResult={useGetAllTimePeriodsQuery(undefined, { skip: !props.isNew && !props.dataset.IsEditable })}
                            formatLabel={opt => `${opt.Name} (id: ${opt.Identifier})`}
                            formatValue={opt => <TimePeriodLabel option={opt??undefined} editable={props.isNew || props.dataset.IsEditable} />}
                            onChange={opt => formProps.setFieldValue('PrimaryPeriodType', opt)}
                        />
                        <AreaTypeUpdateMethods
                            context={formProps}
                            label="Area type update method"
                            hoverProps={{ id: 'add-aggregated-area-type' }}
                        />
                        {!props.isNew ? <DetailedTableRow 
                            align="start"
                            label="Measures"
                            hoverProps={{ id: 'measures-heading' }}
                        >
                            {(props.dataset.Item.Measures?.length??0) > 0 ?
                                <ul className="added control-group long pt-3">
                                    { props.dataset.Item.Measures!.map(m => (
                                        <div className="d-flex justify-content-between pr-2">
                                            <p>
                                                {m.Label} <Link to={`/Measure/Details/${m.Identifier}`}><InfoLink
                                                alt="View measure details"/></Link>
                                            </p>
                                            <Link to={`/MetricType/Dimension?measureIdentifier=${m.Identifier}`}>
                                                Edit metric type dimensions
                                            </Link>
                                        </div>
                                        ))}
                                </ul>
                                : null}
                        </DetailedTableRow> : null }
                    </Container>
                    <FormToolkit>
                        {props.children(formProps)}
                    </FormToolkit>
                </Form>
            )
            }
        </DetailsForm>
    );
};

export default DatasetEditForm;