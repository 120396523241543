import { FC, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Page from '../Common/Page';
import InfoLink from '../Common/InfoLink';
import Table from '../Common/Table';
import { DatasetListingEntry } from '../Store/Dataset/Types';
import { useGetDatasetListQuery } from '../Store/Lists/listApi';
import { toast } from 'react-toastify';



const columnHelper = createColumnHelper<DatasetListingEntry>();

const columns = [
    columnHelper.accessor('SourceLabel', {
        id: 'source',
        cell: val => <Link
            to={`/source/${val.row.original.SourceNumber}`}>{val.row.original.SourceLabel} ({val.row.original.SourceNumber})</Link>,
        header: 'Source',
        enableColumnFilter: true,
    }),
    columnHelper.accessor('CollectionLabel', {
        id: 'collection',
        cell: val => <Link
            to={`/collection/${val.row.original.CollectionNumber}`}>{val.row.original.CollectionLabel} ({val.row.original.CollectionNumber})</Link>,
        header: 'Collection',
        enableColumnFilter: true,
    }),
    columnHelper.accessor('DatasetLabel', {
        id: 'dataset',
        cell: val => <Link
            to={`/dataset/${val.row.original.DatasetNumber}`}>{val.row.original.DatasetLabel} ({val.row.original.DatasetNumber})</Link>,
        header: 'Dataset',
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'details',
        cell: (context) => <Link to={`/dataset/details/${context.row.original.DatasetNumber}`}><InfoLink
            alt={`View details for dataset ${context.row.original.DatasetNumber}`}/> </Link>,
        header: () => 'Details',
        enableColumnFilter: false,
    }),
    columnHelper.accessor('NumberOfMetricTypes', {
        cell: val => val.getValue(),
        header: () => 'Number of metric types',
        enableColumnFilter: true,
        meta: {
            filterVariant: 'range'
        }
    }),

];

const List: FC = () => {
    const { data: loadedData, error, isLoading } = useGetDatasetListQuery();

    const data = useMemo(() => {
        if (loadedData && !error && !isLoading) {
            return loadedData.Datasets;
        }
        return [] as DatasetListingEntry[];
    }, [loadedData !== undefined, error, isLoading]);
    if (error) {
        toast.error('Error loading collection list');
    }



    return (
        <Page loading={isLoading}>
            {!isLoading ? <Table data={data} columns={columns}  /> : null}
        </Page>
    );
}

export default List;
