import { FC, useEffect, useState } from 'react';
import Page from '../Common/Page';
import { Source } from '../Store/Source/Types';
import { useAddSourceMutation, useGetAddPageQuery } from '../Store/Source/sourceApi';
import SourceEditForm from './SourceEditForm';
import { useNavigateOnError, useRequireEditRights, useSubmitHandler } from '../hooks';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router';
import { DatasetFormModel, DatasetJsonModel } from '../Store/Dataset/Types';
import { AddResult } from '../Store/Types';
import { toDatasetJson } from '../Dataset/util';
import SubmitButton from '../Common/SubmitButton';

const Add: FC = () => {
    const [navigationTarget, setNavigationTarget] = useState<'source' | 'addCollection'>('source');
    const [
        addSource
    ] = useAddSourceMutation();
    useRequireEditRights();

    const { data, error, isLoading } = useGetAddPageQuery();

    useNavigateOnError('/source', error, 'Error adding source');
    const handler = useSubmitHandler<Source, AddResult>({
            success: 'Source added',
            error: 'An error occured while adding the source',
            init: 'Adding source'
        }, addSource, (res) => navigationTarget === 'addCollection' ? `/collection/add?source=${res.id}` : `/source/details/${res.id}`
    );


    return (
        <Page title="Add source" loading={isLoading}>
            {data ? <SourceEditForm
                isNew
                source={data}
                onSubmit={handler}
            >
                {(formProps) => (
                    <>
                        <SubmitButton formProps={formProps} />
                        <SubmitButton formProps={formProps} label="Save and add collection" onClick={() => setNavigationTarget('addCollection')} />
                    </>
                )}
            </SourceEditForm> : null}
        </Page>
    );
}


export default Add;
