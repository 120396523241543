import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

export default (baseArgs: Parameters<typeof fetchBaseQuery>[0]) => fetchBaseQuery({
    ...baseArgs,
    fetchFn: async (...args) => {
        try {
            return await fetch(...args);            
        } catch (error) {            
            console.error(error);
            window.location.reload(); // Hard refresh
            throw error;            
        }
    }
});
