import { FC } from 'react';
import Page from '../Common/Page';
import { useGetDetailsQuery, useRefreshDatasetMutation, useUpdateDetailsMutation } from '../Store/Dataset/datasetApi';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import DatasetEditForm from '../Dataset/DatasetEditForm';
import SubmitButton from '../Common/SubmitButton';
import { useNavigateOnError, useSubmitHandler } from '../hooks';
import { DatasetFormModel, DatasetJsonModel } from '../Store/Dataset/Types';
import { UpdateResult } from '../Store/Types';
import { toDatasetJson } from './util';
import { UploadButton } from '../Widgets/Buttons';
import { useRefreshCollectionMutation } from '../Store/Collection/collectionApi';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

export const AddMeasureButton: FC<{ dataset: number }> = ({ dataset }) => (
    <Link className="btn btn-secondary" to={`/measure/add?dataset=${dataset}`}>
        Add measure
    </Link>
);

export const AddIndicatorButton: FC<{ dataset: number }> = ({ dataset }) => (
    <Link className="btn btn-secondary" to={`/metricType/add?dataset=${dataset}`}>
        Add metric type
    </Link>
);

export const RefreshDataset: FC<{ dataset: number }> = ({ dataset }) => {
    const  [ trigger ] = useRefreshDatasetMutation();
    const handler = () => toast.promise(trigger(dataset), {
        pending: 'Refreshing dataset',
        success: 'Dataset refreshed',
        error: 'Failed to refresh dataset'
    });
    return (
        <Button color="secondary" onClick={handler}>
            Refresh
        </Button>
    );
}

const Details: FC = () => {
    const { id } = useParams<'id'>();
    const { data, error, isLoading, isFetching } = useGetDetailsQuery(Number(id));
    const [
        update
    ] = useUpdateDetailsMutation();
    const handler = useSubmitHandler<DatasetJsonModel, UpdateResult>({
            success: 'Dataset updated',
            error: 'An error occured while updating the dataset',
            init: 'Updating dataset'
        }, update
    );

    const handleSubmit = async (dataset: DatasetFormModel) => {
        return handler(toDatasetJson(dataset, false));
    }
    useNavigateOnError('/dataset', error, 'An error occured while loading dataset details');

    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: data.Item.Collection!.Source.Label, url: `/source/${data.Item.Collection!.Source.Number}` },
                { name: data.Item.Collection!.Label, url: `/collection/${data.Item.Collection!.Number}` },
                { name: data.Item.Label, url: `/dataset/${data.Item.Number}` },
                { name: 'Dataset details', url: `/dataset/details/${data.Item.Number}`, active: true },
            ] : undefined }
            buttons={data ? [
                <RefreshDataset dataset={data.Item.Number} />,
                <UploadButton type="dataset" number={data.Item.Number} />,
                <AddMeasureButton key="measure-button" dataset={Number(id)}/>,                
                <AddIndicatorButton key="indicator-button" dataset={Number(id)}/>,
            ] : []}
            title="Dataset details"
            loading={isLoading}
            refreshing={isFetching && !isLoading}
        >
            {data
                ? <DatasetEditForm isNew={false} dataset={data} onSubmit={handleSubmit}>
                    {(formProps) =>  <SubmitButton formProps={formProps} />}
                </DatasetEditForm>
                : null}
        </Page>
    );
}


export default Details;
