import { ListingWrapper } from './ListingSelectorField';
import { FC, ReactNode } from 'react';

interface LongControlGroupProps {
    children: ReactNode[];
    canUpdate: boolean;
    onRemoveItem: (index: number) => void;
    noItems?: ReactNode;
}

const LongControlGroup: FC<LongControlGroupProps> = (props) => (
    props.children.length > 0 ?
        <ul className={`added control-group long${props.canUpdate ? ' can-update' : ''}`}>
            {props.children.map((child, index) => (
                <ListingWrapper
                    canUpdate={props.canUpdate}
                    onRemoveItem={() => props.onRemoveItem(index)}
                >
                    {child}
                </ListingWrapper>
            ))}
        </ul> : props.noItems??null
);

export default LongControlGroup;