import { IndicatorTableModel } from '../Store/TaskTable/Types';
import { useMemo } from 'react';
import { Task, TaskGroup, TaskGroupParent } from '../Store/Tasks/Types';

const groupBy: (keyof Task)[] = [
    'CollectionId',
    'DatasetId',
    'IndicatorId',
    'GeoAreaLevelId'
];

const getGroupId = (task: Task) => groupBy.map(g => task[g]??'-').join('_');

export const isParentTask = (task: TaskGroup): task is TaskGroupParent => (
    (task as TaskGroupParent).subtasks !== undefined
);


const useGroupedTasks = (model: IndicatorTableModel) => {
    const groupRecords = useMemo(() => {
        if (model.scope !== 'Task') {
            return model.tasks as TaskGroup[];
        }
        const groupRoots = model.tasks.reduce<Record<string, TaskGroup>>((acc, task) => {
            const id = getGroupId(task);
            if (task.UpdateMethod === 'Uploaded') {
                return {
                    ...acc,
                    [id]: {
                        ...task,
                        subtasks: [ ]
                    } as TaskGroup
                };
            }
            return acc;
        }, {});

        return (
            Object.values(model.tasks.filter(t => t.UpdateMethod !== 'Uploaded')
                .reduce<Record<string, TaskGroup>>((acc, task) => {
                    const id = getGroupId(task);
                    const parent = acc[id];
                    if (parent && isParentTask(parent)) {
                        parent.subtasks.push(task);
                    }
                    return acc;
                }, groupRoots))
        );
    }, [ model.tasks, model.scope ]);

    return Object.values(groupRecords);
};

export default useGroupedTasks;