import { FC, useEffect, useMemo, useState } from 'react';
import Page from '../Common/Page';
import { useLazySearchMetricsQuery } from '../Store/Search/searchApi';
import { useSearchParams } from 'react-router-dom';
import { MetricTypeSearchResultSet, SearchParams } from '../Store/Search/Types';
import { toast } from 'react-toastify';
import { formatErrorMessage } from '../Utils';
import ResultTable from './ResultTable';


const getSearchParams = (query: URLSearchParams): SearchParams => {
    const arr = Array.from(query);
    return arr.reduce<SearchParams>((acc, [key,value]) => ({
        ...acc,
        ...(key !== 'page' ? { [key !== 'searchTerm' ? key : 'q']: value }: {})
    }), { page: Number(query.get('page')??'1'), pageSize: Number(query.get('pageSize')??'100') });
}

const Search: FC = () => {
    const [ searcher, { isLoading, isFetching } ] = useLazySearchMetricsQuery();
    const [ query ] = useSearchParams();
    const [ resultSet, setResultSet ] = useState<MetricTypeSearchResultSet|null>(null);
    useEffect(() => {
        (async () => {
            const params = getSearchParams(query);
            try {
                const result = await searcher(params);
                if (!result.error && result.data) {
                    setResultSet(result.data);
                } else {
                    toast.error(formatErrorMessage(result.error));
                    setResultSet(null);
                }
            } catch (error) {
                toast.error(formatErrorMessage(error));
                setResultSet(null);
            }
        })();
    }, [ query ]);
    
    return (
        <Page
            loading={isLoading}
            refreshing={isFetching}
            wide
            title="Search results"
            breadcrumbTrail={[
                {
                    name: 'Home',
                    url: '/'
                },
                {
                    name: 'Search',
                    active: true
                }
            ]}
        >
            <ResultTable results={resultSet} />
        </Page>
    )
}



export default Search;
