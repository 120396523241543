import { FC, useEffect, useState } from 'react';
import { useGetTaskLastLoadedByQuery } from '../Store/Tasks/tasksApi';
import { IconWithTooltip, InfoCircle, PersonUp } from '../Common/Icons';
import { Button, Tooltip } from 'reactstrap';
import { useGetMetricTypeLoadedDetailsQuery } from '../Store/Indicators/indicatorApi';
import { CellContext } from '@tanstack/react-table';
import { TaskGroup } from '../Store/Tasks/Types';
import InfoLink from '../Common/InfoLink';

interface LastLoadedByTooltipProps {
    id: string;
    busy: boolean;
    content?: string;
    error?: string;
    onShowStateChange?: (state: boolean) => void;
}
const LastLoadedByTooltip : FC<LastLoadedByTooltipProps> = (props) => {
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const toggleTooltip = () => {
        setShowTooltip(prev => {
            props.onShowStateChange?.(!prev);
            return !prev;
        });
    }
    
    return (
        <>
            <Tooltip isOpen={showTooltip} autohide={false} target={props.id} toggle={toggleTooltip}>
                { props.busy
                    ? 'Loading ...'
                    : (props.error ?? props.content) }
            </Tooltip>
            <Button color="link" id={props.id} onClick={toggleTooltip}>
                <PersonUp />
            </Button>
        </>
    );
}


export const TaskLastLoadedBy: FC<{taskId: number}> = ({ taskId }) => {
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const { data, isLoading, isFetching, isError, isUninitialized } = useGetTaskLastLoadedByQuery(taskId, {
        skip: !showTooltip
    });
    return (
        <LastLoadedByTooltip 
            id={`task-${taskId}-last-loaded-by-tooltip`}
            busy={isLoading || isFetching || isUninitialized}
            error={isError ? 'Error getting last uploader' : undefined}
            content={data?.UserName}
            onShowStateChange={state => setShowTooltip(state)}
        />
    )
}

const MetricTypeLoadedBy: FC<{metricTypeNumber: number}> = ({ metricTypeNumber }) => {
    const [ showTooltip, setShowTooltip ] = useState<boolean>(false);
    const { data, isLoading, isFetching, isError, isUninitialized } = useGetMetricTypeLoadedDetailsQuery(metricTypeNumber, {
        skip: !showTooltip
    });
    return (
        <LastLoadedByTooltip
            id={`metric-type-${metricTypeNumber}-last-loaded-by-tooltip`}
            busy={isLoading || isFetching || isUninitialized}
            error={isError ? 'Error getting last uploader' : undefined}
            content={data?.hasData ? data.user : (data && !data.hasData ? 'No data' : undefined)}
            onShowStateChange={state => setShowTooltip(state)}
        />
    )
}

const LastLoadedBy: FC<{ context: CellContext<TaskGroup, any> }> = ({ context }) => {
    if (context.row.original.Id > 0) {
        return <TaskLastLoadedBy taskId={context.row.original.Id} />;
    }
    if (context.row.original.Indicator !== null) {
        return <MetricTypeLoadedBy metricTypeNumber={context.row.original.Indicator.Number} />;
    }
    return <IconWithTooltip id={`unknown-task-row-${context.row.id}`} icon={PersonUp} tooltip="Unknown" />;
}

export default LastLoadedBy;
