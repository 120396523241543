/* Ripped from LG Inform */

import { StylesConfig } from 'react-select';
import { CSSProperties } from 'react';

export const customStyles: StylesConfig<any, any, any> = {
    groupHeading: base => ({
        ...base,
        fontWeight: 700,
        color: 'black'
    }),
    menuPortal: base => ({
        ...base,
        zIndex: 10001,
        minWidth: 'min(25rem,90vw)'
    }),
    valueContainer: base => ({
        ...base,
        boxShadow: 'none',
        color: '#555555',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: 'transparent'
    }),
    option: (base, more) => ({
        ...base,
        boxShadow: 'none',
        color: more.isDisabled ? '#C0C0C0' : '#555555',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: more.isFocused ? '#92278f55' : 'transparent'
    }),
    placeholder: base => ({
        ...base,
        color: '#0079BF',
        fontWeight: 700
    }),
    singleValue: (base, state) => {
        return {
            ...base,
            boxShadow: 'none',
            color: `rgba(85,85,85,${state.selectProps.menuIsOpen ? '0.3' : '1'})`,
            fontFamily: 'Arial, sans-serif',
        };
    },
    multiValue: base => ({
        ...base,
        boxShadow: 'none',
        color: '#555555',
        fontFamily: 'Arial, sans-serif',
        fontSize: '12px'
    }),
    multiValueLabel: base => ({
        ...base,
        boxShadow: 'none',
        color: '#555555',
        fontFamily: 'Arial, sans-serif',
        fontSize: '12px'
    }),
    control: (base, state) => {
        return ({
            ...base,
            boxShadow: 'none',
            borderColor: (state.isFocused ? 'var(--primary)' : base.borderColor),
            '&:hover' : {
                borderColor: 'var(--primary)'
            }
        });
    }
};

export const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};
export const groupBadgeStyles : CSSProperties = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};