import { createApi } from '@reduxjs/toolkit/query/react'
import { AddResult, UpdateResult } from '../Types';
import {
    DatasetApiModel,
    DatasetApplicableGeoLevelSuggestion, DatasetFormApplicableGeoLevel, DatasetJsonModel,
} from './Types';
import fetchBaseQuery from '../baseQuery';

// Define a service using a base URL and expected endpoints
export const datasetApi = createApi({
    reducerPath: 'datasetApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/dataset/'
    }),
    tagTypes: ['Dataset','List'],
    endpoints: (builder) => ({
        getAddPage: builder.query<DatasetApiModel, number|undefined>({
            query: (collection?: number) => ({
                url: `add`,
                params: collection ? {
                    collection
                } : undefined,
            }),
            providesTags: (res, err, collection) =>  [
                { type: 'Dataset', id: `Add-${collection??'empty'}` }
            ]
        }),
        getDetails: builder.query<DatasetApiModel, number>({
            query: (dataset) => `details/${dataset}`,
            providesTags: (result?: DatasetApiModel) => result ? [{ type: 'Dataset', id: result.Item.Number }] : []
        }),
        getLinkSuggestions: builder.query<DatasetApplicableGeoLevelSuggestion[], DatasetFormApplicableGeoLevel[]>({
            query: (currentLinks: DatasetFormApplicableGeoLevel[]) => ({ 
                url: `suggestDatasetApplicableTypes`,
                method: 'POST',
                body: {
                    levels: currentLinks
                }
            }),
            providesTags: (res, error, arg) => [
                { 
                    type: 'Dataset', 
                    id: arg.map(d => `${d.AreaType.Identifier}-${d.InputType.Identifier}-${d.AggregatedFromAreaType?.Identifier??''}`).join('#') 
                }
            ]
        }),
        updateDetails: builder.mutation<UpdateResult, DatasetJsonModel>({
            query: (data) => ({
                url: 'update',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, params) => result
                ? [{ type: 'Dataset', id: params.Number }]
                : []
        }),
        scheduleStatXploreImport: builder.mutation<void, { datasetNumber: number; period: number }>({
            query: (data) => ({
                url: 'scheduleStatXploreImport',
                method: 'POST',
                body: data,
            }),
        }),
        add: builder.mutation<AddResult, DatasetJsonModel>({
            query: (data) => ({
                url: 'add',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: result => result ? [{type: 'List', id: 'Dataset' }] : []
        }),
        refreshDataset: builder.mutation<UpdateResult, number>({
            query: (data) => ({
                url: `refresh/${data}`,
                method: 'POST',
            }),
        })
    }),
});


export const {
    useGetAddPageQuery,
    useGetDetailsQuery,
    useUpdateDetailsMutation,
    useLazyGetLinkSuggestionsQuery,
    useAddMutation,
    useScheduleStatXploreImportMutation,
    useRefreshDatasetMutation
} = datasetApi;

export default datasetApi;
