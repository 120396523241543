import { MetricTypeSearchResult, MetricTypeSearchResultSet } from '../Store/Search/Types';
import { Dispatch, FC, Fragment, SetStateAction, useCallback } from 'react';
import {
    ColumnFiltersState,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    PaginationState,
    useReactTable
} from '@tanstack/react-table';
import { Link, useSearchParams } from 'react-router-dom';
import { Badge, Table } from 'reactstrap';
import { useSearchParam } from '../hooks';
import SortableHeader from '../Common/Table/SortableHeader';
import FilterableHeader, { DebouncedInput } from '../Common/Table/FilterableHeader';
import PaginationControl from '../Common/Table/PaginationControl';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';

const columnHelper = createColumnHelper<MetricTypeSearchResult>();



const columns = [
    columnHelper.accessor('SourceLabel', {
        cell: (ctx) => <Link to={ctx.row.original.SourceURI}>{ctx.getValue()}</Link>,
        id: 'sourceSearch',
        header: 'Source',
        enableSorting: false,
    }),
    columnHelper.accessor('CollectionLabel', {
        cell: (ctx) => <Link to={ctx.row.original.CollectionURI}>{ctx.getValue()}</Link>,
        header: 'Collection',
        enableSorting: false,
        id: 'collectionSearch',
    }),
    columnHelper.accessor('DatasetLabel', {
        cell: (ctx) => <Link to={ctx.row.original.DatasetURI}>{ctx.getValue()}</Link>,
        header: 'Dataset',
        enableSorting: false,
        id: 'datasetSearch',
    }),
    columnHelper.accessor('MeasureLabel', {
        cell: (ctx) => <Link to={ctx.row.original.MeasureURI}>{ctx.getValue()}</Link>,
        header: 'Measure',
        enableSorting: false,
        id: 'measureSearch',
    }),
    columnHelper.accessor('MetricTypeLongLabel', {
        cell: (ctx) => {
            const isExactMatch = ctx.table.getState().globalFilter === ctx.row.original.MetricTypeNumber.toString();
            return (
                <>
                <Link to={ctx.row.original.MetricTypeURI}>{ctx.getValue()}</Link>
                { isExactMatch ? <Badge className="ml-3 ms-3" pill>Exact</Badge> : null}
                </>
            );
        },
        header: 'Metric type',
        enableSorting: false,
        enableColumnFilter:false
    }),
];

const usePaginationState = (): [ PaginationState, Dispatch<SetStateAction<PaginationState>> ] => {
    const [ query, setQuery ] = useSearchParams();
    const value: PaginationState = {
        pageIndex: Number(query.get('page')??'1')-1,
        pageSize: Number(query.get('pageSize')??'100')
    };
    
    const setValue = useCallback((val: SetStateAction<PaginationState>)  => {
        const nextValue = typeof val === 'function' ? val(value) : val;
        query.set('page', (nextValue.pageIndex+1).toString());
        query.set('pageSize', nextValue.pageSize.toString());
        setQuery(query);
    }, [ value, setQuery ]);

    return [ value, setValue ];
}

const filterHeaders = [ 'sourceSearch', 'collectionSearch', 'datasetSearch', 'measureSearch' ];

const useColumnFilterState = (): [ ColumnFiltersState, Dispatch<SetStateAction<ColumnFiltersState>> ] => {
    const [ query, setQuery ] = useSearchParams();
    const value: ColumnFiltersState = [
        {
            id: 'sourceSearch',
            value: query.get('sourceSearch'),
        },
        {
            id: 'collectionSearch',
            value: query.get('collectionSearch'),
        },
        {
            id: 'datasetSearch',
            value: query.get('datasetSearch'),
        },
        {
            id: 'measureSearch',
            value: query.get('measureSearch'),
        }
    ];

    const setValue = useCallback((val: SetStateAction<ColumnFiltersState>)  => {
        const nextValue = typeof val === 'function' ? val(value) : val;
        filterHeaders.forEach((id: string) => {
            const entry = nextValue.find(item => item.id === id);
            if (entry && entry.value) {
                query.set(id, entry.value as string);
            } else {
                query.delete(id);
            }
        });        
        setQuery(query);
    }, [ value, setQuery ]);

    return [ value, setValue ];
    
}



const ResultTable: FC<{ results: MetricTypeSearchResultSet|null }> = props => {
    const [ pagination, setPagination ] = usePaginationState();
    const [ search, setSearch ] = useSearchParam('searchTerm');
    const [ columnState, setColumnState ] = useColumnFilterState();
    
    
    const table = useReactTable({
        columns,
        data: props.results?.Hits??[],
        manualPagination: true,
        manualFiltering: true,
        getCoreRowModel: getCoreRowModel(),        
        pageCount: Math.ceil((props.results?.Count??0) / Number(pagination.pageSize)),
        onPaginationChange: setPagination,
        onGlobalFilterChange: setSearch,
        onColumnFiltersChange: setColumnState,
        state: {
            pagination,
            globalFilter: search,
            columnFilters: columnState
        }
    });

    

    return (
        <>
            <DetailedTableRow label="Search term">
                <DebouncedInput onChange={val => table.setGlobalFilter(val)} value={table.getState().globalFilter} />
            </DetailedTableRow>    
            <PaginationControl showAllResultsOption={false} isTop={true} table={table} />
            <Table>
                <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <Fragment key={headerGroup.id}>
                        <tr>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : <SortableHeader header={header} isVisible />}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder ? null :
                                        <FilterableHeader column={header.column}/>}
                                </th>
                            ))}
                        </tr>
                    </Fragment>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
            <PaginationControl showAllResultsOption={false} isTop={false} table={table} />
        </>
    )
}

export default ResultTable;