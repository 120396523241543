import { FC } from 'react';
import { Table } from '@tanstack/react-table';
import { Button, Input, Label } from 'reactstrap';

interface PaginationControlProps {
    isTop: boolean;
    table: Table<any>;
    itemType?: string;
    totalTotal?: number;
    showAllResultsOption?: boolean;
}

const getPaginationNumbers = (totalPages: number, currentPage: number, size: number = 7) : (number|'...')[] => {
    const result: (number|'...')[] = [];
    let skipped = 0;
    for (let i = 0; i < totalPages; i++) {
        if (i === 0 || i === totalPages - 1 || i === currentPage || Math.abs(i-currentPage) < Math.floor(size/2)) {
            result.push(i);
            skipped = 0;
        } else if (skipped === 0) {
            result.push('...');
            skipped = 1;
        }
    }
    return result;
} 

const PaginationControl: FC<PaginationControlProps> = (props) => {
    const { table, isTop, itemType = 'items', totalTotal, showAllResultsOption = true } = props;

    return (
        <div className={`d-flex align-items-center gap-2 ${isTop ? 'pb-3' : 'pt-3'}`}>
            <span className="d-flex align-items-baseline gap-1">
                <Label htmlFor={`${isTop ? 'pagination-control-top' : 'pagination-control-bottom'}-page-selector`}>Show </Label>
                <Input
                    type="select"
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 20, 30, 40, 50, 100].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize} {itemType} per page
                        </option>
                    ))}
                    {showAllResultsOption ? <option value={Number.MAX_SAFE_INTEGER}>
                        All {itemType}
                    </option> : null }
                </Input>
            </span>
            <div>
                {getPaginationNumbers(table.getPageCount(), table.getState().pagination.pageIndex).map(pageIndex => (
                    <Button 
                        color="link"
                        disabled={pageIndex === '...' || pageIndex === table.getState().pagination.pageIndex}
                        onClick={() => table.setPageIndex(pageIndex as number)}
                    >
                        {typeof pageIndex === 'number' ? pageIndex + 1 : pageIndex}
                    </Button>
                )) }
            </div>
            { totalTotal !== undefined ? <div>
                Showing {table.getRowCount()} of {totalTotal} total {itemType}
            </div> : null }
        </div>
    );
}


export default PaginationControl;