import { FC, useEffect, useState } from 'react';
import Page from '../Common/Page';
import {
    useAddMutation,
    useGetAddPageQuery,
    useGetDetailsQuery,
    useUpdateDetailsMutation
} from '../Store/Collection/collectionApi';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button } from 'reactstrap';
import { Link, useSearchParams } from 'react-router-dom';
import CollectionEditForm, { CollectionFormProps } from './CollectionEditForm';
import SubmitButton from '../Common/SubmitButton';
import { toast } from 'react-toastify';
import { Collection, CollectionAddModel, CollectionUpdateModel } from '../Store/Collection/Types';
import { formatErrorMessage } from '../Utils';
import { FormikHelpers } from 'formik';
import { useNavigateOnError, useRequireEditRights, useSubmitHandler } from '../hooks';
import { AddResult } from '../Store/Types';


const AddCollection: FC = () => {
    const [ query ] = useSearchParams();
    const source = query.get('source');
    const [ addDataset, setAddDataset ] = useState(false);
    const { data, error, isLoading } = useGetAddPageQuery(source ? Number(source) : undefined);
    const [
        add
    ] = useAddMutation();

    useRequireEditRights();
    const handler = useSubmitHandler<CollectionAddModel, AddResult>({
        success: 'Collection added',
        error: 'An error occured while adding the collection',
        init: 'Adding collection'
    }, add, (res) => addDataset ? `/dataset/add?collection=${res.id}` : `/collection/details/${res.id}` );

    const handleSubmit = async (collection: CollectionFormProps) => {
        await handler({
            SourceNumber: collection.Source.Number,
            Label: collection.Label,
            Url: collection.Url,
            Discontinued: collection.Discontinued,
            IsLocalCollectionRequired: collection.IsLocalCollectionRequired,
            ReplacesCollection: collection.ReplacedBy?.map(c => c.Number)
        } as CollectionAddModel);
        setAddDataset(false);
    }
    useNavigateOnError('/collection', error, 'Unable to add collection');
    
    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: data.Item.Source.Label, url: `/source/${data.Item.Source.Number}` },
                { name: 'Add collection', active: true },
            ] : undefined }
            title="Collection details"
            loading={isLoading}
        >
            {data
                ? <CollectionEditForm isNew={true}  collection={data} onSubmit={handleSubmit}>
                    {(formProps) =>  (
                        <>
                            <SubmitButton formProps={formProps} />
                            <SubmitButton formProps={formProps} label="Save and add dataset" onClick={() => setAddDataset(true)} />
                        </>
                    )}
                </CollectionEditForm>
                : null}
        </Page>
    );
}


export default AddCollection;
