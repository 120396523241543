import { FC } from 'react';
import { Dimension, MetricTypeDimensionsModel } from '../../Store/Dimension/Types';
import { NumberedEntity } from '../../Store/Types';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import PaginatedMetricSelector from '../../Common/PaginatedMetricSelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';


interface MetricSelectorProps {
    value?: NumberedEntity;
    dimension: Dimension;
}

const MetricSelector: FC<MetricSelectorProps> = (props) => {
    const canUpdate = useSelector<RootState>(state => state.defaultModel.canUpdate) as boolean;
    const form = useFormikContext<MetricTypeDimensionsModel>();
    const handleChange = (value: SingleValue<NumberedEntity>) => {
        if (value) {
            form.setFieldValue('TotalsOverride', {
                ...form.values.TotalsOverride,
                [props.dimension.Identifier]: value
            }).then();
        } else {
            form.setFieldValue('TotalsOverride', Object.entries(form.values.TotalsOverride).reduce<Record<string, NumberedEntity>>((acc, [key,value]) => ({
                ...acc,
                ...(key !== props.dimension.Identifier ? { [key]: value } : {})
            }), {})).then();
        }
    }


    return (
        <PaginatedMetricSelector
            isClearable
            placeholder="None"
            value={props.value}
            onChange={handleChange}
            disabled={!canUpdate}
        />
    );

};

export default MetricSelector;