import { FC } from 'react';
import { Container } from 'reactstrap';
import RunningTasks from './RunningTasks';
import RecalculationQueue from './RecalculationQueue';
import ValuesTransfer from './ValuesTransfer';
import MetricsAndMarkersUpload from './MetricsAndMarkersUpload';
import ValuesUpload from './ValuesUpload';
import StatXplore from './StatXplore';
import Page from '../Common/Page';

const Queue : FC = () => {
    return (
        <Page title="Queue">
            <RunningTasks />
            <ValuesTransfer />
            <MetricsAndMarkersUpload />
            <ValuesUpload />
            <StatXplore />
        </Page>
    );
}

export default Queue;


