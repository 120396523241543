import { FC, PropsWithChildren } from 'react';
import { RootState, useAppSelector } from '../../Store/store';
import { useSelector } from 'react-redux';

const FormToolkit: FC<PropsWithChildren> = ({ children }) => {
    const canUpdate = useSelector<RootState>(state => state.defaultModel.canUpdate);
    if (!canUpdate) {
        return null;
    }
    return (
        <div className="form-toolkit py-3 d-flex justify-content-end align-items-center">
            {children}
        </div>
    )
}

export default FormToolkit;