import { FC } from 'react';
import Page from '../Common/Page';
import { useNavigate, useNavigation } from 'react-router';
import SubmitButton from '../Common/SubmitButton';
import MetricTypeDetailsForm from './DetailsForm';
import { MetricTypeJsonModel } from '../Store/Indicators/Types';
import { useGetDetailsQuery as useGetMeasureDetailsQuery } from '../Store/Measure/measureApi';
import { useGetDetailsQuery as useGetDatasetDetailsQuery } from '../Store/Dataset/datasetApi';
import { useGetDatasetMeasuresQuery } from '../Store/Lists/listApi';
import { useAddMetricTypeMutation, useGetMetricTypeAddModelQuery } from '../Store/Indicators/indicatorApi';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigateIf, useNavigateOnError, useSubmitHandler } from '../hooks';
import { CollectionAddModel } from '../Store/Collection/Types';
import { AddResult } from '../Store/Types';
import { CollectionFormProps } from '../Collection/CollectionEditForm';

const Add: FC = () => {
    const [ search ] = useSearchParams();
    const measure = search.get('measure');
    const dataset = search.get('dataset');
    const datasetNumber = Number(dataset);
    
    useNavigateIf('/', Number.isNaN(datasetNumber) || datasetNumber === 0, 'You must select a dataset for the new metric type');
    
    const { data: selectedMeasure, isLoading: measureLoading } = useGetMeasureDetailsQuery(measure??'', {
        skip: !measure
    });
    const { data: allMeasures, isLoading: allMeasuresLoading } = useGetDatasetMeasuresQuery({
        datasetNumber
    });
    const { data: selectedDataset, isLoading: datasetLoading } = useGetDatasetDetailsQuery(datasetNumber);
    
    const { data: addModel, error, isLoading } = useGetMetricTypeAddModelQuery(); 
    const [ submitTrigger ] = useAddMetricTypeMutation();

    const handler = useSubmitHandler<MetricTypeJsonModel, AddResult>({
        success: 'Metric type added',
        error: 'An error occured while adding the metric type',
        init: 'Adding metric type'
    }, submitTrigger, (res) => `/metricType/details/${res.id}` );

    useNavigateOnError('/', error, 'Unable to add metric type');
    
    const newMetric = {        
        Measure: selectedMeasure?.Item,
        Replacements: [],
        ClientApplications: [ { 
            Identifier: 'esdStandards',
            Label: 'esd-standards'
        } ],
        UploadOrCalculate: false,
        CalculationParameters: null,
        AlternateLabels: [],
        Label: '',
        Number: 0,
        DimensionMembers: [],
        Polarity: null,
        Comment: null,        
        Discontinued: false,
        FormattedHelpText: '',
        OriginalLabel: null,
        ShortLabel: '',
        IndicatorSubjects: [],
        Deflatable: 0,
        IsDraft: false,
        MeasureId: selectedMeasure?.Item?.Id??0,
        IsExcludedFromBreakdownList: false,
        SubmissionLagId: 0,
        PHEMetricTypeMapping: null,
        StatXploreParameters: null,
        Created: '',
        Modified: '',
        ContributorId: null,
        CreatorId: null,
        CalculationNotes: null,
        CalculationMethod: null,
        IndicatorCalculationMethod: null,
        Reaggregate: null,
        SecurityType: null,
        RequiredLicenceID: null,
        IsImportedFromNomis: false,
        NullAsZero: false
    } as MetricTypeJsonModel;



    return (
        <Page
            loading={datasetLoading || (measure ? measureLoading : allMeasuresLoading) || isLoading}
            wide
            title="Metric type details"
            breadcrumbTrail={[
                {
                    name: 'Home',
                    url: '/'
                },
                {
                    name: selectedDataset?.Item?.Collection?.Source?.Label ?? '',
                    url: `/Source/${selectedDataset?.Item?.Collection?.Source?.Number}`
                },
                {
                    name: selectedDataset?.Item?.Collection?.Label ?? '',
                    url: `/Collection/${selectedDataset?.Item?.Collection?.Number}`
                },                
                {
                    name: selectedDataset?.Item?.Label ?? '',
                    url: `/Dataset/${selectedDataset?.Item?.Number}`
                },
                ...(measure ? [{
                    name: selectedMeasure?.Item?.Label ?? '',
                    url: `/Measure/Details/${selectedMeasure?.Item?.Identifier}`
                }] : []),
                { name: 'Add metric type', active: true },
            ]}
        >
            {selectedDataset && (measure || allMeasures) && addModel
                ? <MetricTypeDetailsForm 
                    isNew={true}    
                    canEditSecurityType={addModel.CanEditSecurityType}
                    Polarity={addModel.Polarity}
                    SecurityTypeInput={addModel.SecurityTypeInput}
                    measures={allMeasures}
                    dataset={selectedDataset.Item}
                    indicator={{
                        Item: newMetric,
                        HoverHelp: addModel.HoverHelp,                        
                    }}
                    onSubmit={handler}
                >
                    {(formProps) => <SubmitButton formProps={formProps}/>}
                </MetricTypeDetailsForm>
                : null}
        </Page>
    );
}

export default Add;