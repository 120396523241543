import { FC } from 'react';
import Page from '../Common/Page';
import TaskTable from '../TaskTable';
import { DownloadAllMetricsButton } from '../Widgets/Buttons';
import {
    useGetLatestIndicatorTableForCollectionQuery,
} from '../Store/TaskTable/taskTableApi';
import { useParams } from 'react-router';
import TaskTableHead from '../TaskTable/TaskTableHead';
import { useNavigateIf, useNavigateOnError } from '../hooks';
import { AddDatasetButton, RefreshCollection } from './Details';

const CollectionTasks: FC = () => {
    const { id } = useParams<'id'>();
    const number = Number(id);
    const { data: loadedData, error, isLoading } = useGetLatestIndicatorTableForCollectionQuery(number);
    useNavigateIf('/collection', !id || Number.isNaN(number) || number == 0, 'A collection number is required');
    useNavigateOnError('/collection', error, 'Error loading reports by collection');

    return (
        <Page wide loading={isLoading} buttons={[
            <RefreshCollection collection={number} />,
            <AddDatasetButton collection={number} />,
            <DownloadAllMetricsButton collectionNumber={number}>
                Metric type upload template
            </DownloadAllMetricsButton> ,
            <DownloadAllMetricsButton allMetricTypes>
                Download all metric types
            </DownloadAllMetricsButton>
        ]}
              breadcrumbTrail={[
                  { name: 'Home', url: '/' },
                  { name: loadedData?.collection?.Source.Label??'', url: `/source/${loadedData?.collection?.Source?.Number}` },
                  { name: loadedData?.collection.Label??'', url: `/collection/${id}`, active: true },
              ]}
        >
            { !isLoading && loadedData ? <TaskTableHead loadedData={loadedData} /> : null }
            { !isLoading && loadedData ? <TaskTable data={loadedData} /> : null }
        </Page>
    );
}

export default CollectionTasks;
