import { FC, ReactNode } from 'react';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import { Form, FormikProps } from 'formik';
import { DetailsApiModel } from '../Store/Types';
import { useUpdateDetailsMutation } from '../Store/Licence/licenceApi';
import { Agency, AgencyLicence, Licence } from '../Store/Licence/Types';
import LongControlGroup from '../Common/DetailsForm/LongControlGroup';
import { ListingSelectorField } from '../Common/DetailsForm/ListingSelectorField';
import { useGetAgencyListQuery } from '../Store/Lists/listApi';
import DetailsForm from '../Common/DetailsForm/DetailsForm';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';
import { Measure } from '../Store/Measure/Types';

interface MeasurementUnitEditFormProps {
    licence: DetailsApiModel<Licence>;
    onSubmit: (values: Licence) => void | Promise<void>;
    children: (props: FormikProps<Licence>) => ReactNode;
}


const LicenceEditForm: FC<MeasurementUnitEditFormProps> = (props) => {

    return (
        <DetailsForm isNew={false} hoverHelp={props.licence.HoverHelp} initialValues={props.licence.Item} onSubmit={props.onSubmit}>
            {formProps => (
                <Form>
                    <DetailedTableHeader
                        label="Licence details"                        
                    />
                    <Container>
                        <DetailedTableFormRow
                            context={formProps}
                            editable={false}
                            label="Label"
                            name="Label"
                            hoverProps={{ id: 'label-heading' }}
                        />
                        <DetailedTableRow 
                            label="Metric types"
                            hoverProps={{ id: 'label-heading' }}
                        >
                            <LongControlGroup
                                canUpdate={false}
                                onRemoveItem={() => {}}
                            >
                                {formProps.values.Indicators.map(i => (
                                    `${i.Label} (id: ${i.Number})`
                                ))}
                            </LongControlGroup>
                        </DetailedTableRow>
                        <ListingSelectorField<Agency, Agency[]>
                            hoverProps={{ id: 'organisations-heading' }}
                            formatLabel={opt => opt.Label}
                            getDataFromListing={result => result}
                            hookResult={useGetAgencyListQuery()}
                            label="Organisations"
                            onItemAdded={newItem => !formProps.values.AgencyLicences.some(licence => licence.Agency.Id === newItem.Id) ?
                                formProps.setFieldValue('AgencyLicences', [
                                    ...formProps.values.AgencyLicences,
                                    {
                                        Agency: newItem,
                                        Modified: '',
                                        Id: 0,
                                        AgencyID: newItem.Id,
                                        AgencyApprovedAndHasUsers: newItem,
                                        LicenceTypeID: 0,
                                        CreatorId: null,
                                        ContributorId: null,
                                        ProfileProviderID: formProps.values.Id,
                                        Created: ''
                                    } as AgencyLicence
                                ].sort((a,b) => a.Agency.Label.localeCompare(b.Agency.Label))) : null}
                            onRemoveItem={index => formProps.setFieldValue('AgencyLicences', formProps.values.AgencyLicences.filter((l,i) => i!==index))}
                        >
                            {formProps.values.AgencyLicences.map(agencyLicence => (
                                agencyLicence.Agency.Label
                            ))}
                        </ListingSelectorField>
                        <FormToolkit>
                            {props.children(formProps)}
                        </FormToolkit>
                    </Container>
                </Form>
            )
            }
        </DetailsForm>
    );
};

export default LicenceEditForm;