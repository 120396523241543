import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { TaskGroup } from '../Store/Tasks/Types';
import ToolbarHeader from './ToolbarHeader';
import Toolbar from './Toolbar';
import { ColumnVisibilityConfiguration, IndicatorTableModel } from '../Store/TaskTable/Types';
import { UncontrolledTooltip } from 'reactstrap';
import { MinusCircle, PlusCircle } from '../Common/Icons';
import ButtonWithTooltip from '../Common/ButtonWithTooltip';
import { DisplayColumnDef } from '@tanstack/table-core/src/types';
import { queryable } from '../Utils';
import { useMemo } from 'react';
import LastLoadedBy from '../Tasks/LastLoadedBy';
import PublishedValue from './PublishedValue';
import NextToBeLoadedColumn from '../Common/Tasks/NextToBeLoadedColumn';
import { IdentifiedColumnDef } from '@tanstack/table-core/build/lib/types';

const columnHelper = createColumnHelper<TaskGroup>();



const cellDefinitionFromColumn = (columnId: string): Pick<ColumnDef<TaskGroup>, 'cell'|'sortingFn'> => {
    let cell: DisplayColumnDef<TaskGroup>['cell'] = undefined;
    switch (columnId) {
        case 'Source':
            cell = (val: CellContext<TaskGroup, any>) => <Link
                to={`/source/${val.row.original.Collection.Source.Number}`}>{val.row.original.Collection.Source.Label} ({val.row.original.Collection.Source.Number})</Link>
            break;
        case 'Collection':
        case 'Dataset':
            const id = columnId as 'Collection'|'Dataset';
             cell = (val: CellContext<TaskGroup, any>) => val.row.original[id] ? <Link
                to={`/${id.toLocaleLowerCase()}/${val.row.original[id]!.Number}`}>{val.row.original[id]!.Label} ({val.row.original[id]!.Number})</Link> : '-';
            break;
        case 'MetricType':
             cell = (val: CellContext<TaskGroup, any>) => val.row.original.Indicator
                ? `${val.row.original.Indicator.Label} (${val.row.original.Indicator.Number})`
                : '-';
            break;
        case 'UpdateMethod':
            cell = (val: CellContext<TaskGroup, any>) => {
                const id = queryable(`UM-${val.row.id}`);
                return (
                    <>
                        <UncontrolledTooltip target={id}>{val.row.original.UpdateMethod}</UncontrolledTooltip>
                        <div className="text-center"
                             id={id}>{val.row.original.UpdateMethod?.substring(0, 1)}</div>
                    </>
                );
            }
            break;
        case 'Discontinued':
        case 'IsDraft':
            cell = (val: CellContext<TaskGroup, any>) => val.getValue() ? 'True' : 'False'
            break;
        case 'NextToBeLoaded':
            cell = (val: CellContext<TaskGroup, any>) => <NextToBeLoadedColumn val={val} />
            break;
        case 'PublishedDate':
            cell = (val: CellContext<TaskGroup, any>) => <PublishedValue task={val.row.original} />
            break;
        case 'LastLoaded':
            cell = (val: CellContext<TaskGroup, any>) => val.getValue() ? new Date(val.getValue()).toLocaleDateString() : '-';
            break;
        case 'LastTimePeriod':
            cell = (val: CellContext<TaskGroup, any>) => (
                    val.row.original.LastTimePeriod
                        ? <><UncontrolledTooltip target={queryable(`LastTimePeriod-${val.row.id}`)}>
                                {val.row.original.LastTimePeriod.DisplayName}
                            </UncontrolledTooltip>
                            <span id={queryable(`LastTimePeriod-${val.row.id}`)}>{val.row.original.LastTimePeriod.Identifier}</span></>
                        : '-'
            );
            break;
        case 'LastLoadedBy':
            cell = (val: CellContext<TaskGroup, any>) => (
                <LastLoadedBy context={val} />
            );
    }
    return cell ? {
        cell
    } : {};
}

const cellDef = (column: ColumnVisibilityConfiguration) => {
    let newVar = {
        ...column,
        ...cellDefinitionFromColumn(column.id),
        sortUndefined: 1 as const,
    };

    switch (column.id) {
        case 'Source':
            return columnHelper.accessor('Collection.Source.Label', newVar);
        case 'Collection':
            return columnHelper.accessor('Collection.Label', newVar);
        case 'Dataset':
            return columnHelper.accessor('Dataset.Label', newVar);
        case 'MetricType':
            return columnHelper.accessor('Indicator.Label', newVar);
        case 'AreaType':
            return columnHelper.accessor('GeoAreaLevel.Name', newVar);
        case 'Discontinued':
        case 'IsDraft':
        case 'UpdateMethod':
            return columnHelper.accessor('UpdateMethod', {
                ...newVar,
                sortingFn: (rowA, rowB, columnId) => {
                    const order = [
                        '-', 'Virtual', 'Calculated', 'Aggregated', 'Uploaded',
                    ];
                    return order.indexOf(rowA.original.UpdateMethod)-order.indexOf(rowB.original.UpdateMethod);
                }
            });
        case 'LastTimePeriod':
            return columnHelper.accessor('LastTimePeriod.Identifier', newVar);
        case 'LastLoaded':
            return columnHelper.accessor(val => val.LastCollected ?  Date.parse(val.LastCollected) : undefined, newVar);
        case 'PublishedDate':
            return columnHelper.accessor(val => val.Published ? Date.parse(val.Published) : undefined, newVar);
        case 'LastLoadedBy':
            return columnHelper.display(newVar);
        case 'NextToBeLoaded':
            return columnHelper.accessor(val => val.NextCollected && !val.Discontinued ? Date.parse(val.NextCollected) : undefined, {
                ...newVar,
                filterFn: (rowA, columnId, filterValue: [number|null, number|null ,boolean|undefined]|undefined) => {
                    if (!filterValue?.[2] && rowA.original.Discontinued) {
                        return false;
                    }
                    const from = filterValue?.[0]??0;
                    const to = filterValue?.[1]??8640000000000000;
                    const current = rowA.original.NextCollected ? Date.parse(rowA.original.NextCollected) : 0;
                    return current >= from && current <= to;
                }
                
            });
        default:
            return null;
    }
}

const useColumns = (model: IndicatorTableModel) => {
    const { scope, columns: columnConfiguration } = model;
    const tableIdentifier = (
        scope === 'Task' ? undefined : (
            scope === 'Source' ? model.source.Number : (
                scope === 'Collection' ? model.collection.Number : (
                    scope === 'Dataset' ? model.dataset.Number : (
                        scope === 'MetricReportTag' ? model.indicatorSubject.Number : undefined
                    )
                )
            )
        )
    );
    
    
    return useMemo(() => [
        ...(scope === 'Task' ? [columnHelper.display({
            id: 'ExpandTask',
            header: '',
            cell: val => {
                const UpdateMethod = cellDefinitionFromColumn('UpdateMethod')?.cell;
                return val.row.getCanExpand() && val.row.depth === 0
                    ? (
                        <ButtonWithTooltip id={`expand-${val.row.original.Id}`} tooltip={{
                            children: 'View non-upload tasks'
                        }} color="link" className="text-dark" onClick={val.row.getToggleExpandedHandler()}>
                            {val.row.getIsExpanded() ? <MinusCircle /> : <PlusCircle />}
                        </ButtonWithTooltip>
                    )
                    : (val.row.depth > 0 && UpdateMethod ? <UpdateMethod {...val} /> : null)
            }
        })] : []),

        ...columnConfiguration
            .filter(c => c.id !== 'ExpandTask')
            .map(c => cellDef(c))
            .filter((v: ReturnType<typeof cellDef>): v is Exclude<ReturnType<typeof cellDef>, null> => v !== null),
        columnHelper.display({
            id: 'Toolbar',
            cell: val => <Toolbar row={val.row} scope={scope} />,
            header: (ctx) => <ToolbarHeader
                table={ctx.table}
                columns={columnConfiguration}
                scope={scope}
                identifier={tableIdentifier}
            />,
            enableColumnFilter: false,
            enableSorting: false,
            enableHiding: false
        })
    ], [ columnConfiguration, scope ]);
}

export default useColumns;