import {
    CollectionIndicatorTableModel,
    DatasetIndicatorTableModel,
    IndicatorTableModel, ReportTagTableModel,
    SourceIndicatorTableModel
} from '../Store/TaskTable/Types';
import { FC, useState } from 'react';
import { Button, Container, Input, Label, Table } from 'reactstrap';
import ButtonWithTooltip from '../Common/ButtonWithTooltip';
import InfoLink from '../Common/InfoLink';
import { useAppSelector } from '../Store/store';
import { Link } from 'react-router-dom';
import { NumberedEntity } from '../Store/Types';
import { resourcesLink } from '../Utils';
import { DetailedTableEnumRow } from '../Common/DetailsForm/DetailedTable';
import { useScheduleStatXploreImportMutation } from '../Store/Dataset/datasetApi';

interface TaskTableHeadProps {
    loadedData: IndicatorTableModel;
}

const hasSource = (table: IndicatorTableModel): table is SourceIndicatorTableModel | DatasetIndicatorTableModel | CollectionIndicatorTableModel => (
    ![ 'Task', 'MetricReportTag' ].includes(table.scope)
);

const hasCollection = (table: IndicatorTableModel): table is DatasetIndicatorTableModel | CollectionIndicatorTableModel => (
    ![ 'Task', 'MetricReportTag', 'Source' ].includes(table.scope)    
);

const hasDataset = (table: IndicatorTableModel): table is DatasetIndicatorTableModel => (
    table.scope === 'Dataset'
);

const hasReportTag = (table: IndicatorTableModel): table is ReportTagTableModel => (
    table.scope === 'MetricReportTag'
);

interface TaskTableHeadRowProps {
    header?: string;
    name: string
    row: NumberedEntity;
    active: boolean;
    resources: string;
    url?: string;
}

const TaskTableHeadRow: FC<TaskTableHeadRowProps> = (props) => {
    const text = `${props.row.Label} (id: ${props.row.Number})`;

    return (
        <tr>
            <th>{props.header ?? (props.name[0]?.toUpperCase() + props.name.substring(1))}</th>
            <td>
                <div className="d-flex">
                    {!props.active 
                        ? <Link className="btn btn-link" to={`/${props.name}/${props.row.Number}`}>{text}</Link> 
                        : (
                            props.url
                                ? <Button color="link" href={props.url} target="_blank">{text}</Button> 
                                : <span>{text}</span> 
                        )}
                    <Link to={`/${props.name}/details/${props.row.Number}`}>
                        <InfoLink alt={`View ${props.name} details`}/>
                    </Link>
                    <ButtonWithTooltip
                        id={`${props.name}-${props.row.Number}-dl-link`}
                        href={`/DeveloperTools/${props.name}?${props.name}Number=${props.row.Number}`}
                        target="_blank"
                        tooltip={{ children: 'Developer tools' }}
                        color="link"
                    > <img className="button-icon" alt="Developer tools"
                           src={resourcesLink('/images/icons/dev_tools.png', props.resources)}/>
                    </ButtonWithTooltip>
                    
                </div>
            </td>

        </tr>
    );
}


const TaskTableHead: FC<TaskTableHeadProps> = (props) => {

    const resources = useAppSelector(state => state.defaultModel.commonResourceLocation);
    const [ periods, setPeriods ] = useState<string>('1');
    const [ trigger ] = useScheduleStatXploreImportMutation();
    
    if (props.loadedData.scope === 'Task') {
        return null;
    }


    return (
        <Container>
            <Table className="task-table-head">
                {hasSource(props.loadedData)
                    ? <TaskTableHeadRow row={props.loadedData.source} name="source"
                                        active={props.loadedData.scope === 'Source'} resources={resources}/>
                    : null
                }
                {hasCollection(props.loadedData)
                    ? <TaskTableHeadRow 
                        row={props.loadedData.collection}
                        name="collection"
                        active={props.loadedData.scope === 'Collection'} 
                        resources={resources}
                        url={props.loadedData.collection.Url}
                    />
                    : null}
                {hasDataset(props.loadedData)
                    ? <TaskTableHeadRow row={props.loadedData.dataset} name="dataset"
                                        active={props.loadedData.scope === 'Dataset'} resources={resources}/>
                    : null}
                {hasReportTag(props.loadedData)
                    ? (
                        <tr>
                            <th>Report tag</th>
                            <td>
                                {props.loadedData.indicatorSubject?.Name ?? ''} (id: {props.loadedData.indicatorSubject?.Number})
                            </td>
                        </tr>
                    ) : null}
                {hasDataset(props.loadedData) && props.loadedData.statXploreParametersArray?.length > 0
                    ? (
                        <tr>
                            <th>StatXplore Import <InfoLink alt="Some tasks listed here are (partially or fully) imported from the Stat.Xplore API. You can schedule their next import using this form." /></th>
                            <td>
                               <div className="d-flex">
                                   <Label for="stat-xplore-period-selector">
                                       Periods to import
                                   </Label>
                                   <Input 
                                       id="stat-xplore-period-selector" type="select"
                                       style={{ width: 'auto' }}
                                       value={periods}
                                       onChange={e => setPeriods(e.currentTarget.value)}
                                   >
                                       {(new Array(4)).fill(0).map((_, i) => ({ Id: i + 1, Name: `Latest ${i + 1}` })).map(v => (
                                           <option key={v.Id} value={v.Id}>{v.Name}</option>
                                       ))}
                                   </Input>                                   
                                   <Button onClick={() => trigger({
                                       datasetNumber: (props.loadedData as DatasetIndicatorTableModel).dataset.Number,
                                       period: Number(periods)
                                   })}>Schedule import</Button> 
                               </div> 
                            </td>
                        </tr>
                    ) : null}
            </Table>
        </Container>
    )
}

export default TaskTableHead;