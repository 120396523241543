import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Button, Col, Input, Label, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { XCircle } from '../Icons';
import { FilterProps } from './FilterableHeader';

interface DateRangeFilterProps {
    id: string;
    from: Date|null;
    to: Date|null;
    includeDiscontinued?: boolean;
}

type DateRangeFilterType = [Date | null, Date | null, boolean|undefined];
type UnderlyingFilterType = [number|null, number|null]|[number|null, number|null ,boolean];


const fromFilterProps = (props: FilterProps&{ withDiscontinuedOption?: boolean }): DateRangeFilterProps => {
    const currentFilter: UnderlyingFilterType|undefined = props.column.getFilterValue() as UnderlyingFilterType|undefined;
    return {
        id: props.column.id,
        from:  (currentFilter?.[0]??0)>0 ? new Date(currentFilter![0]!) : null,
        to:  (currentFilter?.[1]??0)>0 ? new Date(currentFilter![1]!) : null,
        includeDiscontinued: props.withDiscontinuedOption ? (currentFilter?.[2]??false) : undefined,
    };
    
}

const DateRangeFilter: FC<FilterProps&{ withDiscontinuedOption?: boolean }> = (filterProps) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const props = fromFilterProps(filterProps);
    if (filterProps.withDiscontinuedOption) {
        console.log(filterProps.column.getFilterValue());
    }
    
    const change = (
        from: Date|null, to: Date|null, discontinued?: boolean
    ) => {
        const newFilter = [
            from?.getTime()??null,
            to?.getTime()??null,
            ...(filterProps.withDiscontinuedOption ? [ discontinued ] : [])
        ];
        
        if (newFilter[0] == null && newFilter[1] == null && (!filterProps.withDiscontinuedOption || props.includeDiscontinued === undefined)) {
            filterProps.column.setFilterValue(null);
        } else {
            filterProps.column.setFilterValue(newFilter);
        }
    }
    
    const setDiscontinued = (e: ChangeEvent<HTMLInputElement>) => {
        change(props.from,props.to,e.currentTarget.checked || undefined)
    }

    return (
        <>
            <Popover placement="bottom" isOpen={popoverOpen} target={`filter-for-${props.id}`}
                     toggle={() => setPopoverOpen(prev => !prev)}>
                <PopoverHeader>Select date range</PopoverHeader>
                <PopoverBody>
                    <div className="d-flex">
                        <DatePicker
                            selected={props.from}
                            className="w-36 border shadow rounded form-control"
                            dateFormat="dd/MM/yyyy"
                            onChange={val => change(val, props.to, props.includeDiscontinued)}
                            selectsStart
                            inline
                        />
                        <DatePicker
                            selected={props.to}
                            className="w-36 border shadow rounded form-control"
                            dateFormat="dd/MM/yyyy"
                            minDate={props.from??undefined}
                            onChange={val => change(props.from, val, props.includeDiscontinued)}
                            selectsEnd
                            inline
                        />
                    </div>
                    { filterProps.withDiscontinuedOption ? (
                        <div className="d-flex form-check align-items-baseline">
                            <Input
                                type="checkbox"
                                name="check" checked={props.includeDiscontinued} 
                                id={`${props.id}-discontinued-check`}
                                onChange={setDiscontinued} 
                            />
                            <Label
                                htmlFor={`${props.id}-discontinued-check`} 
                                check
                                style={{ marginTop: '0.15rem' }}
                            >Include discontinued</Label>
                        </div>
                    ) : null }
                    <div className="d-flex justify-content-end">
                        <Button color="primary" onClick={() => setPopoverOpen(false)}>Done</Button>
                    </div>
                </PopoverBody>
            </Popover>
            <Col className="d-flex align-items-center">
                <Input
                    id={`filter-for-${props.id}`}
                    placeholder="Filter ..."
                    value={`${props.from?.toLocaleDateString() ?? ''}${props.from && props.to ? ' - ' : ''}${props.to?.toLocaleDateString() ?? ''}`}
                    readOnly
                />
                <Button color="link" onClick={() => change(null, null, false)}>
                    <XCircle className="text-primary" />
                </Button>
            </Col>
        </>
    );
}

export default DateRangeFilter;