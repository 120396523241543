import { createApi } from '@reduxjs/toolkit/query/react'
import { Indicator, IndicatorCalculationMethod, Subject, TimeIntervalType } from '../Indicators/Types';
import { TimePeriodType } from '../TimePeriodType/Types';
import { DatasetListing } from '../Dataset/Types';
import { GeoAreaLevel } from '../GeoArea/Types';
import { CollectionListing } from '../Collection/Types';
import { SourceListing } from '../Source/Types';
import { createSelector } from '@reduxjs/toolkit';
import { TypedUseQueryStateResult } from '@reduxjs/toolkit/dist/query/react';
import { GeoAreaLevelLink, IndicatorInputMethod, SubjectHierarchy } from './Types';
import { MeasurementUnit } from '../MeasurementUnit/Types';
import { Agency } from '../Licence/Types';
import { Measure } from '../Measure/Types';
import { Dimension } from '../Dimension/Types';
import { IdentifiableEntity } from '../Types';
import fetchBaseQuery from '../baseQuery';



const listApi = createApi({
    reducerPath: 'listApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api'
    }),
    tagTypes: [ 'List', 'FilteredList' ],
    endpoints: (builder) => ({
        getAllAreaTypes: builder.query<GeoAreaLevel[], void>({
            query: () => `geoAreaLevel/list`,
            providesTags: [ { id: 'GeoAreaLevel', type: 'List' } ]
        }),
        getAreaTypeLinks: builder.query<GeoAreaLevelLink[], void>({
            query: () => `geoAreaLevelLinks/list`,
            providesTags: [ { id: 'GeoAreaLevel', type: 'List' } ]
        }),
        getIndicatorForDataset: builder.query<Indicator[], number>({
            query: (datasetId) => ({
                url: `metricType/list`,
                params: {
                    datasetId
                }
            }),
            providesTags: (res,err,arg) =>  [ { id: `DatasetIndicator-${arg}`, type: 'FilteredList' } ]
        }),
        getAllTimePeriods: builder.query<TimePeriodType[], void>({
            query: () => `timePeriod/list`,
            providesTags: [ { id: 'TimePeriodType', type: 'List' } ]
        }),
        getDatasetList: builder.query<DatasetListing, void>({
            query: () => `dataset/list`,
            providesTags: [{ id: 'Dataset', type: 'List' }]
        }),
        getCollectionDatasetsList: builder.query<DatasetListing, number>({
            query: (collection) => ({
                url: `dataset/list`,
                params: {
                    collection
                }
            }),
            providesTags: (result, error, arg) => [{ type: 'FilteredList', id: `DatasetCollectionList-${arg}` }]
        }),
        getCollectionList: builder.query<CollectionListing, void>({
            query: () => `collection/list`,
            providesTags: [{ id: 'Collection', type: 'List' }]
        }),
        getSourceList: builder.query<SourceListing, void>({
            query: () => `source/list`,
            providesTags: [{ id: 'Source', type: 'List' }]
        }),
        getUpdateMethodsList: builder.query<IndicatorInputMethod[], void>({
            query: () => `updateMethods/list`,
            providesTags: [{ id: 'UpdateMethod', type: 'List' }]
        }),
        getDatasetMeasures: builder.query<Measure[], { datasetNumber: number; inputPrecision?: number }>({
            query: (params) => ({ 
                url: `measures/list`,
                params
            }),
            providesTags: (res,error,number) => [{ id: `Dataset-${number}-Measures`, type: 'FilteredList' }]
        }),
        getMeasurementUnitsList: builder.query<MeasurementUnit[], void>({
            query: () => `measurementUnits/list`,
            providesTags: [{ id: 'MeasurementUnit', type: 'List' }]
        }),
        getBasicMeasurementUnitsList: builder.query<MeasurementUnit[], void>({
            query: () => `basicMeasurementUnits/list`,
            providesTags: [{ id: 'BasicMeasurementUnit', type: 'FilteredList' }]
        }),
        getAgencyList: builder.query<Agency[], void>({
            query: () => `agencies/list`,
            providesTags: [{ id: 'Agency', type: 'List' }]
        }),
        getDimensions: builder.query<Dimension[], boolean|undefined>({
            query: (withMembers?: boolean) => ({ url: `dimension/list`, params: { withMembers: withMembers ? 'true' : false } }),
            providesTags: (res, err, param) => res ? [{ id: param ? 'DimensionsWithMembers' : 'Dimensions', type: 'List' }] : []
        }),
        getTimeIntervalTypes: builder.query<TimeIntervalType[], void>({
            query: () => `timeIntervalType/list`,
            providesTags: [{ id: 'TimeIntervalType', type: 'List' }]
        }),
        getWebserviceClientApplications: builder.query<IdentifiableEntity[], void>({
            query: () => `clientApplications/list`,
            providesTags: [{ id: 'TimeIntervalType', type: 'List' }]
        }),
        getSubject: builder.query<Subject[], number>({
            query: (type) => ({ url: `subjects/list`, params: { type } }),            
            providesTags: (res,error, type) => res ? [{ id: `SubjectType-${type}`, type: 'FilteredList' }] : []
        }),
        getSubjectHierarchy: builder.query<SubjectHierarchy[], void>({
            query: () => ({ url: `subjectHierarchy/list` }),
            providesTags: (res,error, type) => res ? [{ id: `SubjectHierarchy`, type: 'List' }] : []
        }),
        getCalculationMethodWithFields: builder.query<IndicatorCalculationMethod[], void>({
            query: (type) => ({ url: `calculationMethods/list`, params: { type } }),
            providesTags: [{ id: `CalculationMethods`, type: 'List' }]
        }),
    }),
    
});

type GeoAreaLevelResultType = TypedUseQueryStateResult<GeoAreaLevel[], any, any>;

export const areaTypeSelector = createSelector(
    (res: GeoAreaLevelResultType) => res.data,
    (res: GeoAreaLevelResultType, includeAbolished: boolean = false, includeGroups: boolean = true) => ({ includeAbolished, includeGroups }),
    (res,params) => (
        res?.filter(areaType => (params.includeAbolished || !areaType.IsAbolishedAreaLevel)
            && (params.includeGroups || !areaType.IsGroup))?.sort(((at1,at2) => at1.Name.localeCompare(at2.Name)))
    )
);


export const {
    useGetAllAreaTypesQuery,
    useGetIndicatorForDatasetQuery,
    useGetDatasetListQuery,
    useGetAllTimePeriodsQuery,
    useGetCollectionDatasetsListQuery,
    useGetCollectionListQuery,
    useGetSourceListQuery,
    useGetUpdateMethodsListQuery,
    useGetAreaTypeLinksQuery,
    useGetMeasurementUnitsListQuery,
    useGetAgencyListQuery,
    useGetDatasetMeasuresQuery,
    useGetDimensionsQuery,
    useGetTimeIntervalTypesQuery,
    useGetWebserviceClientApplicationsQuery,
    useGetSubjectQuery,
    useGetCalculationMethodWithFieldsQuery,
    useGetBasicMeasurementUnitsListQuery,
    useGetSubjectHierarchyQuery
} = listApi;



export default listApi;
