import { FC, ReactNode } from 'react';
import {
    Dimension,
    DimensionMember,
    DimensionMemberHierarchyItem,
    MetricTypeDimensionsModel
} from '../../Store/Dimension/Types';
import { useGetMemberHierarchyQuery } from '../../Store/Dimension/dimensionApi';
import { Input } from 'reactstrap';
import DropdownSelector from '../../Common/DetailsForm/DropdownSelector';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { NumberedEntity } from '../../Store/Types';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';

interface DimensionMemberSelectorProps {
    dimension: Dimension;
    indicator: NumberedEntity;    
}

type DimensionMemberWithDepth = DimensionMember & { depth: number };

const DimensionMemberSelector: FC<DimensionMemberSelectorProps> = (props) => {
    const canUpdate = useSelector<RootState>(state => state.defaultModel.canUpdate) as boolean;
    const res = useGetMemberHierarchyQuery(props.dimension.Identifier);
    const ctx = useFormikContext<MetricTypeDimensionsModel>();
    const selectedValue = ctx.values.Indicators.find(i => i.Indicator.Number === props.indicator.Number)
        ?.DimensionMembers
        ?.find(dm => dm.Dimension.Identifier === props.dimension.Identifier);
    const getOptions = (d: DimensionMemberHierarchyItem, depth: number = 0): DimensionMemberWithDepth[] => {
        const res: DimensionMemberWithDepth[] = [
            { ...d.DimensionMember, depth }
        ];
        return res.concat(d.ChildDimensions.flatMap<DimensionMemberWithDepth>(d => getOptions(d, depth+1)));
    }
    
    const handleChange = (value: SingleValue<DimensionMemberWithDepth>) => {
        ctx.setFieldValue('Indicators', ctx.values.Indicators.map(i => i.Indicator.Number !== props.indicator.Number 
            ? i 
            : ({
                ...i,
                DimensionMembers: [ 
                    ...i.DimensionMembers.filter(dm => dm.Dimension.Identifier !== props.dimension.Identifier),
                    ...(value ? [ value ]: [])
                ]
            }))).then();
    }
    
    return (
        <DropdownSelector<DimensionMemberWithDepth, DimensionMemberHierarchyItem[]>
            isClearable
            getDataFromListing={options => options.flatMap(opt => getOptions(opt, 0))}
            isSelectedOption={opt => opt.Identifier === selectedValue?.Identifier}
            formatLabel={opt => opt.Label}
            formatLabelNode={opt => <span style={{ marginLeft: `${opt.depth*0.5}rem` }}>{opt.Label}</span>}
            hookResult={res}
            placeholder="None"
            onChange={handleChange}
            isDisabled={!canUpdate || res.isLoading}
        />
    );
    
};

export default DimensionMemberSelector;