import { FC } from 'react';
import QueueSection from './QueueSection';
import { useGetAllItemsQuery } from '../Store/Queue/queueApi';


const ValuesUpload : FC = () => {

    const { data, error, isLoading } = useGetAllItemsQuery()


    return (
        <QueueSection
            heading="Metric values upload queue"
            subheading={
                <>
                    Metric value uploads
                    submitted via <a target="_blank" href="https://upload.esd.org.uk">
                    upload.esd.org.uk
                </a>
                </>
            }
            headers={['File name','Triggered by']}
            mapper={v => [ v.filename??'Untitled', v.triggeredBy ]}
            error={error}
            loading={isLoading}
            values={data?.batchJobItems}
        />
    );
}


export default ValuesUpload;