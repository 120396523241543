import { ReactNode, useMemo } from 'react';
import Select, { GroupBase, OnChangeValue } from 'react-select';
import { customStyles } from '../selectStyles';
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types';
// import { VariableSizeList as List, VariableSizeListProps } from 'react-window';



export interface SimpleSelectorProps<TOption, TListing, TGroup extends GroupBase<TOption> = GroupBase<TOption>> {    
    listing?: TListing;
    isLoading?: boolean;
    isDisabled?: boolean;
    isClearable?: boolean;
    onChange: (selected: OnChangeValue<TOption, false>) => void;
    getDataFromListing: (listing: TListing) => OptionsOrGroups<TOption, TGroup>;
    isSelectedOption: (item: TOption) => boolean;    
    isOptionDisabled?: (item: TOption) => boolean;
    formatLabel: (item: TOption, allOptions?: OptionsOrGroups<TOption, TGroup>) => string;
    formatLabelNode?: (item: TOption, allOptions?: OptionsOrGroups<TOption, TGroup>) => ReactNode;
    formatGroupLabel?: (item: TGroup) => ReactNode;
    onBlur?: () => void;
    placeholder?: string;
    error?: boolean;
}

const SimpleSelector = <TOption,TListing, TGroup extends GroupBase<TOption> = GroupBase<TOption>>(props: SimpleSelectorProps<TOption,TListing, TGroup>) => {    
    const options = useMemo(() => {
        return !props.isLoading && props.listing
            ? props.getDataFromListing(props.listing)
            : undefined;
    }, [ props.getDataFromListing, props.isLoading, props.listing ]);
    const isGroup = (options: TOption|TGroup): options is TGroup => (
        (((options as GroupBase<TOption>).options?.length)??0) > 0 
    );
    const selected = useMemo(() => {
        if (!options) {
            return null;
        }
        for (let opt of options) {
            if (isGroup(opt)) {
                for (let innerOpt of opt.options) {
                    if (props.isSelectedOption(innerOpt)) {
                        return innerOpt;
                    }
                }
            } else {
                if (props.isSelectedOption(opt)) {
                    return opt;
                }
            }
            
        }
        
        return null;
    }, [ props.isSelectedOption, options ])
    const formatOptionLabel = props.formatLabelNode??props.formatLabel;
    
    return (
        <Select<TOption, false, TGroup>
            menuPortalTarget={window.document.body}
            isClearable={props.isClearable}        
            value={selected}
            isMulti={false}
            onChange={props.onChange}
            isDisabled={props.isDisabled}
            isOptionDisabled={props.isOptionDisabled}
            isLoading={props.isLoading}
            options={options}
            styles={customStyles}            
            getOptionLabel={opt => props.formatLabel(opt, options)}
            formatOptionLabel={opt => formatOptionLabel(opt, options)}
            formatGroupLabel={props.formatGroupLabel}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            menuPlacement="auto"            
        />
    );

};

export default SimpleSelector;