import { createApi } from '@reduxjs/toolkit/query/react';
import { MetricTypeSearchResultSet, SearchParams } from './Types';
import fetchBaseQuery from '../baseQuery';


const searchApi =   createApi({
    reducerPath: 'searchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/search'
    }),
    tagTypes: ['Search'],
    endpoints: (builder) => ({
        searchMetrics: builder.query<MetricTypeSearchResultSet, SearchParams>({
            query: params => ({
                url: 'metricTypePaginated',
                params
            })
        })
    })
});

export const {
    useLazySearchMetricsQuery
} = searchApi;

export default searchApi;