// https://stackoverflow.com/questions/58434389/typescript-deep-keyof-of-a-nested-object
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];
type Join<K, P> = K extends string | number ?
    P extends string | number ?
        `${K}${"" extends P ? "" : "."}${P}`
        : never : never;

export type Paths<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: K extends string | number ?
        `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never
    }[keyof T] : ""

export type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
    { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";


export type PathType<T, K extends string> =
    K extends `${infer P}.${infer R}`
        ? P extends keyof T
            ? PathType<T[P], R>
            : never
        : K extends keyof T
            ? T[K]
            : never;

export type ArrayPaths<T, D extends number = 10> = [D] extends [never]
    ? never 
    : T extends object 
        ? { 
        [K in keyof T]-?: K extends string | number 
            ?(T[K] extends (any[]|null) ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never) 
            : never
    }[keyof T] : ""



const getFromPathInner = <T extends Record<string, any>>(object: T, path: string[]): any => {
    if (path.length === 1) return object ? object[path[0]] : undefined;
    if (object === null || object === undefined) return object;
    return Object.getOwnPropertyNames(object).includes(path[0]) ? getFromPathInner(object[path[0]], path.slice(1)) : undefined;
}

export const getFromPath = <T extends Record<string, any>, TKey extends Paths<T>>(object: T, key: TKey): PathType<T, TKey> => {
    const path = key.split('.');
    return getFromPathInner(object, path);    
} 