import { FC, ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';
import { Button, Col, Row } from 'reactstrap';
import { PlusLg } from '../Icons';
import LongControlGroup from './LongControlGroup';
import { BaseProps, DetailedTableRow } from './BasicInput';
import classNames from 'classnames';


export type ListingSelectorProps<T> = {
    value?: T;
    onChange: (item?: T | null | undefined) => void;
};

export interface AnyListingSelectorFieldProp<T> extends BaseProps {
    label: ReactNode;
    children: ReactNode[];
    onRemoveItem: (index: number) => void;
    onItemAdded: (item: T) => void;
    disabled?: (item: T|null|undefined) => boolean;
    listingSelector: FC<ListingSelectorProps<T>>
}

export const AnyListingSelectorField = <T,>(props: AnyListingSelectorFieldProp<T>) => {
    const canUpdate: boolean = useSelector<RootState>(state => state.defaultModel.canUpdate) as boolean;
    const [selectedItem, setSelectedItem] = useState<T>();
    const disabled = props.disabled??((item: T|null|undefined) => !item)
    
    return (
        <>
            <DetailedTableRow required={props.required} label={props.label} hoverProps={props.hoverProps} row className={props.className} align={props.align} id={props.id}>
                {canUpdate ? <>
                    <Col>
                        <props.listingSelector onChange={item => setSelectedItem(item??undefined)} value={selectedItem} />
                    </Col>
                    <Col xs="auto">
                        <Button disabled={disabled(selectedItem)} color="link" className="text-success"
                                onClick={() => {
                                    props.onItemAdded(selectedItem!);
                                    setSelectedItem(undefined);
                                }}>
                            <PlusLg/>
                        </Button>
                    </Col>
                </> : null}
            </DetailedTableRow>

            <Row className={classNames('align-items-center py-1', props.className)}>
                <Col xs={6} sm={4} md={3} className="font-weight-bold d-flex align-items-center">
                    {' '}
                </Col>
                <Col xs={6} sm={8} md={9}>
                    <LongControlGroup 
                        canUpdate={canUpdate}
                        onRemoveItem={props.onRemoveItem} 
                    >
                        {props.children}
                    </LongControlGroup>                    
                </Col>
            </Row>
        </>
    );
}