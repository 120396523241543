import { createApi } from '@reduxjs/toolkit/query/react'
import { Licence } from './Types';
import { DetailsApiModel, UpdateResult } from '../Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const licenceApi = createApi({
    reducerPath: 'licenceApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/licence/'
    }),
    tagTypes: ['Licence'],
    endpoints: (builder) => ({
         getDetails: builder.query<DetailsApiModel<Licence>, string>({
            query: (dimensionIdentifier) => `details/${dimensionIdentifier}`,
            providesTags: (result?: DetailsApiModel<Licence>) => result ? [{ type: 'Licence', id: result.Item.Id }] : []
        }),
        updateDetails: builder.mutation<UpdateResult, Licence>({
            query: (licence) => ({
                url: 'update',
                method: 'POST',
                body: licence,
            }),
            invalidatesTags: (result, error, param) => result ? [{ type: 'Licence', id: param.Id }] : []

        })
    }),
});


export const {
    useGetDetailsQuery,
    useUpdateDetailsMutation
} = licenceApi;

export default licenceApi;
