import SimpleSelector, { SimpleSelectorProps } from './SimpleSelector';
import { TypedUseQueryHookResult } from '@reduxjs/toolkit/src/query/react/buildHooks';
import { GroupBase } from 'react-select';


export interface DropdownSelectorProps<
    TOption,
    TListing,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> extends Omit<SimpleSelectorProps<TOption, TListing, TGroup>, 'listing'|'isLoading'> {
    hookResult?: TypedUseQueryHookResult<TListing, any, any, any>;
}

const DropdownSelector = <TOption,TListing, TGroup extends GroupBase<TOption> = GroupBase<TOption>>(
    { hookResult, ...props }: DropdownSelectorProps<TOption,TListing, TGroup>) => {
    const { data, isLoading, isFetching } = hookResult??{};
    
    
    return (
        <SimpleSelector
            {...props}            
            listing={data}
            isLoading={isLoading || isFetching}
        />
    );

};

export default DropdownSelector;