import AreaTypeUpdateMethod from './AreaTypeUpdateMethod';
import { getAllChildAreaTypes } from './utils';
import { Dataset, DatasetApplicableGeoLevel } from '../../Store/Dataset/Types';
import { FC } from 'react';
import { FormikContextType } from 'formik';
import { GeoAreaLevel } from '../../Store/GeoArea/Types';
import { GeoAreaLevelLink, IndicatorInputMethod } from '../../Store/Lists/Types';
import { Alert } from 'reactstrap';
import { DatasetFormModel, DatasetFormApplicableGeoLevel } from '../../Store/Dataset/Types';

interface AreaTypeUpdateMethodTabProps {
    forMethod: IndicatorInputMethod;
    context: FormikContextType<DatasetFormModel>;
    canUpdate: boolean;
    areasResult?: GeoAreaLevel[];
    areaLinks?: GeoAreaLevelLink[];
}

const AreaTypeUpdateMethodTab: FC<AreaTypeUpdateMethodTabProps> = (props) => {
    const { canUpdate, areaLinks, areasResult, forMethod } = props;
    const applicableGeoLevel = props.context.values.ApplicableGeoLevel?.reduce<[DatasetFormApplicableGeoLevel,number][]>(
        (acc, areaType, index) => [
            ...acc,
            ...(areaType.InputType.Identifier === forMethod.Identifier ? [ [ areaType, index ] as [DatasetFormApplicableGeoLevel,number] ] : [])
        ], [])??[];
    
    return (
        applicableGeoLevel.length > 0 ?
                <ul className="added control-group long">
                    {applicableGeoLevel.map(([child, index]) => (                       
                        
                        <AreaTypeUpdateMethod
                            key={`${child.AreaType.Identifier}-${index}`}
                            canUpdate={canUpdate}
                            datasetAreaLevel={child}
                            childAreaTypes={getAllChildAreaTypes(child.AreaType, areasResult??[], areaLinks??[])}
                            onDiscontinueToggle={() => props.context.setFieldValue(`ApplicableGeoLevel.${index}.Discontinued`, !child.Discontinued)}
                            onRemoveOption={() => props.context.setFieldValue("ApplicableGeoLevel", props.context.values.ApplicableGeoLevel?.filter((level,idx) => index !== idx))}
                            onEditOption={newAggregation => props.context.setFieldValue(`ApplicableGeoLevel.${index}`, {
                                ...child,
                                AggregatedFromAreaType: newAggregation
                            } as DatasetFormApplicableGeoLevel)}
                        />
                    ))}
                </ul> 
            : <Alert color="info">No area types {props.forMethod.Label.toLowerCase()}</Alert>
    )
}

export default AreaTypeUpdateMethodTab;