import { createApi } from '@reduxjs/toolkit/query/react'
import {
    CalculationJsonModel,
    ChangeDatasetFormModel,
    ChangeDatasetFormRequest,
    IndicatorDetailsApiModel,
    LastUpdateDetails, MetricTypeFilterModel, MetricTypeJsonModel, Option, UploadEndpointParams
} from './Types';
import fetchBaseQuery from '../baseQuery';
import { AddResult, UpdateResult } from '../Types';


const indicatorApi = createApi({
    reducerPath: 'indicatorApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/metricType/'
    }),
    tagTypes: ['MetricType', 'MetricTypeCalculations' ],
    endpoints: (builder) => ({
        getMetricTypeDetails: builder.query<IndicatorDetailsApiModel, number>({
            query: (identifier) => `details/${identifier}`,
            providesTags: (result, err, number) => result ? [{ type: 'MetricType', id: `base-details-${number}` }] : []
        }),
        getMetricTypeLoadedDetails: builder.query<LastUpdateDetails, number>({
            query: (identifier) => `metricDataDetails/${identifier}`,
            providesTags: (result, error, arg) => result ? [{ type: 'MetricType', id: `update-details-${arg}` }] : []
        }),
        getMetricTypeAddModel: builder.query<Pick<IndicatorDetailsApiModel, 'CanEditSecurityType'|'SecurityTypeInput'|'HoverHelp'|'Polarity'>, void>({
            query: () => 'add',
            providesTags: result=> result
                ? [{ type: 'MetricType', id: `add-details` }] 
                : []
        }),
        getCalculationFieldDropdownOptions: builder.query<Option[], { methodNumber: number; fieldIdentifier: string; q?: string; page?: number; pageSize?: number }>({
            query: (params) => ({
                url: 'calculationFieldDropdown',
                params,
            }),
            providesTags: (res, err, p)=> res
                ? [{ type: 'MetricTypeCalculations' as const, id: `${p.methodNumber}-${p.fieldIdentifier}-${p.page??1}-${p.pageSize??50}-${p.q??''}` }]
                : []
        }),
        getMetricTypeCalculationData: builder.query<CalculationJsonModel|null, number>({
            query: (metricTypeNumber) => `populatedCalculationMethodParameters/${metricTypeNumber}`,            
            providesTags: (res, err, p)=> res
                ? [{ type: 'MetricType' as const, id: `calculations-${p}` }]
                : []
        }),
        getMetricTypeEmail: builder.mutation<{ message: string }, MetricTypeFilterModel>({
            query: (params) => ({ url: `emailTemplate`, params }),
        }),
        changeDataset: builder.mutation<void, ChangeDatasetFormModel>({
            query: (model) => ({
                url: 'changeDataset',
                method: 'POST',
                body: {
                    MetricTypeId: model.metricType.Id,
                    DatasetId: model.newDataset.DatasetId,
                    MeasureId: model.newMeasure?.Id,
                } as ChangeDatasetFormRequest
            })
        }),
        addMetricType: builder.mutation<AddResult, MetricTypeJsonModel>({
            query: (model) => ({
                url: 'add',
                method: 'POST',
                body: model
            })
        }),
        updateMetricType: builder.mutation<UpdateResult, MetricTypeJsonModel>({
            query: (model) => ({
                url: 'update',
                method: 'POST',
                body: model
            }),
            invalidatesTags: (res, err, p)=> (
                res?.success ? [
                    { type: 'MetricType', id: `base-details-${p.Number}` },
                    { type: 'MetricType', id: `update-details-${p.Number}` },
                    { type: 'MetricType', id: `calculations-${p.Number}` },
                ] : []
            )
        }),
        upload: builder.mutation<UpdateResult, UploadEndpointParams>({
            query: (model) => ({
                url: `upload${model.type}`,
                method: 'POST',
                body: model.type !== 'all' ? {
                    number: model.number
                } : {}
            }),
        })
    }),
});


export const {
    useGetMetricTypeAddModelQuery,
    useGetMetricTypeDetailsQuery,
    useGetMetricTypeCalculationDataQuery,
    useChangeDatasetMutation,
    useGetMetricTypeLoadedDetailsQuery,
    useLazyGetCalculationFieldDropdownOptionsQuery,
    useUpdateMetricTypeMutation,
    useAddMetricTypeMutation,
    useGetMetricTypeEmailMutation,
    useUploadMutation
} = indicatorApi;

export default indicatorApi;
