import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import queueApi from './Queue/queueApi';
import { defaultModelSlice } from './DefaultModel/defaultModelSlice';
import { DefaultModel } from '../Types/ViewModels';
import sourceApi from './Source/sourceApi';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import tasksApi from './Tasks/tasksApi';
import taskTableApi from './TaskTable/taskTableApi';
import collectionApi from './Collection/collectionApi';
import { tasksSlice } from './Tasks/tasksSlice';
import datasetApi from './Dataset/datasetApi';
import indicatorApi from './Indicators/indicatorApi';
import listApi from './Lists/listApi';
import measureApi from './Measure/measureApi';
import measurementUnitApi from './MeasurementUnit/measurementUnitApi';
import dimensionApi from './Dimension/dimensionApi';
import licenceApi from './Licence/licenceApi';
import searchApi from './Search/searchApi';
import statxploreApi from './StatXplore/statxploreApi';

declare global {
    var INITIAL_MODEL: DefaultModel;
}


export const store = configureStore({
    devTools: true,
    preloadedState: {
        [defaultModelSlice.reducerPath]: window.INITIAL_MODEL
    },
    reducer: {
        [queueApi.reducerPath]: queueApi.reducer,
        [sourceApi.reducerPath]: sourceApi.reducer,
        [defaultModelSlice.reducerPath]: defaultModelSlice.reducer,
        [tasksApi.reducerPath]: tasksApi.reducer,
        [taskTableApi.reducerPath]: taskTableApi.reducer,
        [collectionApi.reducerPath]: collectionApi.reducer,
        [tasksSlice.reducerPath]: tasksSlice.reducer,
        [datasetApi.reducerPath]: datasetApi.reducer,
        [indicatorApi.reducerPath]: indicatorApi.reducer,
        [listApi.reducerPath]: listApi.reducer,
        [measureApi.reducerPath]: measureApi.reducer,
        [measurementUnitApi.reducerPath]: measurementUnitApi.reducer,
        [dimensionApi.reducerPath]: dimensionApi.reducer,
        [licenceApi.reducerPath]: licenceApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [statxploreApi.reducerPath]: statxploreApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat([
            queueApi.middleware,  sourceApi.middleware,
            tasksApi.middleware, taskTableApi.middleware, 
            collectionApi.middleware, datasetApi.middleware,
            indicatorApi.middleware, listApi.middleware,
            measureApi.middleware, measurementUnitApi.middleware,
            dimensionApi.middleware, licenceApi.middleware,
            searchApi.middleware, statxploreApi.middleware
        ]),
});

setupListeners(store.dispatch)



// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;