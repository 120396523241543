import { FC } from 'react';
import QueueSection from './QueueSection';
import { useGetAllItemsQuery } from '../Store/Queue/queueApi';


const MetricsAndMarkersUpload : FC = () => {
    const { data, error, isLoading } = useGetAllItemsQuery()

    return (
        <QueueSection
            heading="Metric types and markers upload queue"
            subheading={
            <>
                Uploads of metric types and
                markers submitted via <a target="_blank" href="https://upload.esd.org.uk">
                    upload.esd.org.uk
                </a>
            </>
            }
            headers={['Description', 'Filename','Triggered by']}
            mapper={v => [ v.description, v.filename??'untitled', v.triggeredBy ]}
            loading={isLoading}
            values={data ? data.uploadItems : undefined}
            error={error}
        />
    );
}


export default MetricsAndMarkersUpload;