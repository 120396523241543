import { createSlice } from '@reduxjs/toolkit';
import { DefaultModel } from '../../Types/ViewModels';

const initialState : DefaultModel = {
    canUpdate: false,
    commonResourceLocation: 'https://resources.esd.org.uk'
}

export const defaultModelSlice = createSlice({
    name: 'defaultModel',
    initialState,
    reducers: {

    },
})