import { flexRender, Header } from '@tanstack/react-table';
import { FC } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { SortArrow, SortArrowAsc, SortArrowDesc } from '../Icons';


interface SortableHeaderProps<T> {
    header: Header<T, any>;
    isVisible?: boolean;
    onHideToggle?: () => void;
}

const SortableHeader = <T, >({ header, onHideToggle, isVisible = true }: SortableHeaderProps<T>) => (
    <Row className="justify-content-between align-items-baseline">
        <Col>
            {isVisible ? flexRender(
                header.column.columnDef.header,
                header.getContext()
            ) : ''}
        </Col>
        {isVisible && header.column.getCanSort() ? (
            <Col xs="auto">
                <Button color="link" onClick={header.column.getToggleSortingHandler()}  className="text-primary">
                    {header.column.getIsSorted() === false ? <SortArrow /> : (header.column.getIsSorted() === 'asc' ? <SortArrowAsc /> : <SortArrowDesc />)}
                </Button>
            </Col>
        ) : null}
    </Row>
);

export default SortableHeader;