import { FC } from 'react';
import Page from '../Common/Page';
import TaskTable from '../TaskTable';
import { DownloadAllMetricsButton } from '../Widgets/Buttons';
import {
    useGetLatestIndicatorTableForCollectionQuery,
    useGetLatestIndicatorTableForDatasetQuery,
    useGetLatestIndicatorTableForReportTagsQuery,
} from '../Store/TaskTable/taskTableApi';
import { useParams } from 'react-router';
import TaskTableHead from '../TaskTable/TaskTableHead';
import { useNavigateIf, useNavigateOnError } from '../hooks';

const MetricTypeReportTagTasks: FC = () => {
    const { id } = useParams<'id'>();
    const number = Number(id);
    const { data: loadedData, error, isLoading } = useGetLatestIndicatorTableForReportTagsQuery(number);
    useNavigateIf('/', !id || Number.isNaN(number) || number == 0, 'A tag identifier is required');
    useNavigateOnError('/', error, 'Error loading reports by tag');
    
    return (
        <Page wide loading={isLoading} buttons={[ 
            <DownloadAllMetricsButton reportTag={number} />
        ]}
              breadcrumbTrail={[
                  { name: 'Home', url: '/' },
                  { name: loadedData?.indicatorSubject?.Name??'' , active: true },
                  { name: 'Metric types with a report tag' , active: true },
              ]}
        >
            { !isLoading && loadedData ? <TaskTableHead loadedData={loadedData} /> : null }
            { !isLoading && loadedData ? <TaskTable data={loadedData} /> : null }
        </Page>
    );
}

export default MetricTypeReportTagTasks;
