import { createApi } from '@reduxjs/toolkit/query/react'
import fetchBaseQuery from '../baseQuery';
import { BulkScheduleImportPayload, UpdateAndScheduleImportPayload } from './Types';
import { UpdateResult } from '../Types';

// Define a service using a base URL and expected endpoints
export const statxploreApi = createApi({
    reducerPath: 'statxploreApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/statxplore'
    }),
    tagTypes: [ 'MetricType' ],
    endpoints: (builder) => ({
        scheduleImport: builder.mutation<UpdateResult,UpdateAndScheduleImportPayload>({
            query: (params) => ({
                url: 'UpdateAndScheduleImport',
                method: 'POST',
                body: params
            }),
            invalidatesTags: (res, error, params) => res ? [
                { type: 'MetricType', id: `base-details-${params.indicatorNumber}` },                
            ] : []
        }),
        bulkScheduleImport: builder.mutation<UpdateResult,BulkScheduleImportPayload>({
            query: (params) => ({
                url: 'scheduleImport',
                method: 'POST',
                body: params
            })
        }),
    }),
})

export const { useScheduleImportMutation, useBulkScheduleImportMutation } = statxploreApi;

export default statxploreApi;