import { DatasetListingEntry } from '../Store/Dataset/Types';
import { GeoAreaLevel } from '../Store/GeoArea/Types';
import { Indicator } from '../Store/Indicators/Types';

export const noDataset = {
    DatasetId: 0,
    DatasetLabel: 'None',
} as DatasetListingEntry;
export const noAreaType: GeoAreaLevel = {
    Identifier: 'None',
    Id: 0,
    Name: 'None'
} as GeoAreaLevel;
export const noIndicator = {
    Id: 0,
    Number: 0,
    Label: 'None'
} as Indicator;