import { NumberedEntity } from '../Store/Types';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { MetricTypeSearchResult } from '../Store/Search/Types';
import { useLazySearchMetricsQuery } from '../Store/Search/searchApi';
import { GroupBase, SingleValue } from 'react-select';
import { FC } from 'react';
import { customStyles } from './selectStyles';

interface AdditionalOptions {
    page: number;
}

interface MetricSelectorProps {
    value?: NumberedEntity;    
    onChange: (value: SingleValue<NumberedEntity>) => void;
    isClearable?: boolean;
    placeholder?: string;
    disabled?: boolean;
}


const loadOptions = (trigger: any): LoadOptions<NumberedEntity, any, AdditionalOptions> => async (
    q,
    prevOptions,
    additional
) => {
    
    const result = await trigger({
        page: additional?.page??1,
        q
    }).unwrap();

    return {
        options: result.Hits.map((mt: MetricTypeSearchResult) => ({ 
            Id: 0,
            Label: mt.MetricTypeLabel,
            Number: mt.MetricTypeNumber
        } as NumberedEntity)),
        hasMore: result.More,
        additional: {
            page: (additional?.page??1)+1,
        }
    }
}

const MetricSelector: FC<MetricSelectorProps> = (props) => {    
    const [ trigger ] = useLazySearchMetricsQuery();
    

    return (
        <AsyncPaginate<NumberedEntity, GroupBase<NumberedEntity>, AdditionalOptions>
            onChange={props.onChange}
            styles={customStyles}
            isOptionSelected={opt => props.value ? opt.Number === props.value.Number : false}
            loadOptions={loadOptions(trigger)}
            defaultOptions={props.value ? [ props.value ] : undefined}
            defaultAdditional={{
                page: 1
            }}
            value={props.value}
            isClearable={props.isClearable}
            placeholder={props.placeholder}
            getOptionLabel={(option) => option.Label}
            getOptionValue={(option) => option.Number.toString()}
            menuPortalTarget={window.document.body}
            isDisabled={props.disabled}
        />
    );

};

export default MetricSelector;