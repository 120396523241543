import { Button, ButtonProps, UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';
import { FC } from 'react';

interface ButtonWithTooltipProps extends Omit<ButtonProps, 'id'> {
    id: string;
    tooltip?: Omit<UncontrolledTooltipProps, 'target'>;
}

const ButtonWithTooltip : FC<ButtonWithTooltipProps> = ({ tooltip = {}, id, ...props }) => (
    <>
        <UncontrolledTooltip target={id} {...tooltip} />
        <Button id={id} {...props} />
    </>
);


export default ButtonWithTooltip;