import { FC, useState } from 'react';
import Page from '../Common/Page';
import { useGetAddPageQuery, useAddMutation } from '../Store/Measure/measureApi';
import { Alert } from 'reactstrap';
import { Link, useSearchParams } from 'react-router-dom';
import SubmitButton from '../Common/SubmitButton';
import MeasureEditForm from './MeasureEditForm';
import { useNavigateIf, useNavigateOnError, useSubmitHandler } from '../hooks';
import { AddResult } from '../Store/Types';
import { Measure } from '../Store/Measure/Types';


const AddMeasure: FC = () => {
    const [ query ] = useSearchParams();
    const dataset = query.get('dataset');
    const datasetNumber = Number(dataset);
    useNavigateIf('/dataset', Number.isNaN(datasetNumber) || datasetNumber == 0, 'A dataset is required')
    const { data, error, isLoading } = useGetAddPageQuery(datasetNumber);
    const [ redirectTo, setRedirectTo ] = useState('/measure/details/:id');
    
    const [
        add
    ] = useAddMutation();
    useNavigateOnError('/dataset',error,'Error loading measure add page');
    const handler = useSubmitHandler<Measure, AddResult>({
            success: 'Measure added',
            error: 'An error occured while adding the measure',
            init: 'Adding measure'
        }, add, (res) => redirectTo.replace(':id', res.id.toString())
    );

    return (
        <Page
            breadcrumbTrail={ data ? [
                { name: 'Home', url: '/' },
                { name: data.Item.Dataset.Collection!.Source.Label, url: `/source/${data.Item.Dataset.Collection!.Source.Number}` },
                { name: data.Item.Dataset.Collection!.Label, url: `/collection/${data.Item.Dataset.Collection!.Number}` },
                { name: data.Item.Dataset.Label, url: `/dataset/${data.Item.Dataset.Number}` },
                { name: 'Add Measure', active: true },
            ] : undefined }
            title="Add measure"
            loading={isLoading}
        >
            {data
                ? <MeasureEditForm isNew={true} measure={data} onSubmit={handler}>
                    {(formProps) => <>
                        <>
                            <SubmitButton formProps={formProps} />
                            <SubmitButton formProps={formProps}
                                          label="Save and add metric type" 
                                          onClick={() => setRedirectTo(`/metricType/add?dataset=${data.Item.Dataset.Number}&measure=:id`)}
                            />
                            <SubmitButton formProps={formProps}
                                          label="Save and add another"
                                          onClick={() => setRedirectTo(`/measure/add?dataset=${data.Item.Dataset.Number}`)}
                            />

                        </>
                    </>}
                </MeasureEditForm>
                : null}
        </Page>
    );
}


export default AddMeasure;
