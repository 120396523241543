import { Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FC, useContext, useState } from 'react';
import { FormikContextType } from 'formik';
import { DetailedTableRow } from '../../Common/DetailsForm/BasicInput';
import { MetricTypeJsonModel, PHEMetricTypeMapping, StatXploreParameters } from '../../Store/Indicators/Types';
import { useGetDetailsQuery } from '../../Store/Dataset/datasetApi';
import InfoLink from '../../Common/InfoLink';
import { DetailedTableEnumRow, DetailedTableFormRow } from '../../Common/DetailsForm/DetailedTable';
import { useScheduleImportMutation } from '../../Store/StatXplore/statxploreApi';
import { DetailsFormAdditionalContext } from '../../Common/DetailsForm/DetailsForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';

interface Props {
    context: FormikContextType<MetricTypeJsonModel>;
    datasetNumber: number;
}

const PHEConfiguration: FC<Pick<Props, 'context'>> = (props) => {
    const importButtonClick = () => {
        props.context.setFieldValue('PHEMetricTypeMapping', null);
    }
    const { canUpdate } = useContext(DetailsFormAdditionalContext);

    return (
        <Container fluid>
            <p>This metric type is currently imported from PHE</p>
            <Button color="link" className="px-0 pb-4" disabled={!canUpdate} onClick={importButtonClick}>Disable importing</Button>

            <DetailedTableFormRow
                context={props.context}
                label="PHE Metric Type Id"
                name="PHEMetricTypeMapping.PHEMetricTypeID"
                required
            />
            <DetailedTableFormRow
                context={props.context}
                label="PHE Sex"
                name="PHEMetricTypeMapping.PHESex"
            />
            <DetailedTableFormRow
                context={props.context}
                label="PHE Age"
                name="PHEMetricTypeMapping.PHEAge"
            />
            <DetailedTableFormRow
                context={props.context}
                label="PHE Category Type"
                name="PHEMetricTypeMapping.PHECategoryType"
            />
            <DetailedTableFormRow
                context={props.context}
                label="PHE Category"
                name="PHEMetricTypeMapping.PHECategory"
            />
            <DetailedTableFormRow
                context={props.context}
                label="PHE Value Type"
                name="PHEMetricTypeMapping.PHEValueType"
            />

        </Container>
    );
}


const StatXploreConfiguration: FC<Pick<Props, 'context'>> = (props) => {
    const importButtonClick = () => 
        props.context.setFieldValue('StatXploreParameters', null);
    
    const { isNew, canUpdate } = useContext(DetailsFormAdditionalContext); 
    const [ trigger ] = useScheduleImportMutation();
    const handleSchedule = () => {
        return trigger({
            indicatorNumber: props.context.values.Number,
            parameters: props.context.values.StatXploreParameters!
        });
    }

    return (
        <Container fluid>
            <p>This metric type is currently imported from Stat.Xplore.</p>
            <Button color="link" className="px-0 pb-4" disabled={!canUpdate} onClick={importButtonClick}>Disable importing</Button>

            <DetailedTableFormRow
                context={props.context}
                label="Database"
                name="StatXploreParameters.Database"
                required
            />
            <DetailedTableFormRow
                context={props.context}
                label="Measure"
                name="StatXploreParameters.Measure"
                required
            />
            <DetailedTableFormRow
                context={props.context}
                label="Dimensions"
                name="StatXploreParameters.Dimensions"
            />
            <DetailedTableFormRow
                context={props.context}
                label="Override date field"
                name="StatXploreParameters.OverrideDateField"
            />
            <DetailedTableFormRow
                context={props.context}
                label="Override date value"
                name="StatXploreParameters.OverrideDateValue"
            />
            <DetailedTableEnumRow
                context={props.context}
                label="Override import period"
                name="StatXploreParameters.OverrideImportPeriod"
                enumValues={(new Array(4)).fill(0).map((_, i) => ({ Id: i + 1, Name: `Latest ${i + 1}` }))}
            />
            { !isNew && canUpdate ? <DetailedTableRow label="Schedule import">
                { props.context.values.StatXploreParameters!.ProcessingStatus === 0 
                    ? ( 
                        <Button color="primary" onClick={handleSchedule} disabled={!props.context.values.StatXploreParameters!.OverrideImportPeriod}>
                            Schedule import
                        </Button> 
                    ) : (props.context.values.StatXploreParameters!.ProcessingStatus == 1 
                            ? <p>An import is queued</p>  
                            : <p>An import is in progress</p>)
                }
            </DetailedTableRow> : null
            }
        </Container>
    );
}


const ImportConfigurationModalRoot: FC<Pick<Props, 'context'>> = (props) => {
    const { canUpdate } = useContext(DetailsFormAdditionalContext);
    const importButtonClick = (source: 'phe'|'statxplore') => () => {
        if (source === 'statxplore') {
            const original = props.context.initialValues.StatXploreParameters;
            props.context.setFieldValue('StatXploreParameters', original ?? {
                Database: null,
                Dimensions: null,
                Measure: null,
                ProcessingStatus: 0
            } as StatXploreParameters).then();
        } else if (source === 'phe') {
            const original = props.context.initialValues.PHEMetricTypeMapping;
            props.context.setFieldValue('PHEMetricTypeMapping', original ?? {
                Id: 0,
                PHEMetricTypeID: null as any,
                PHESex: 'Persons',
                PHEAge: 'All ages',
                PHECategoryType: null,
                PHECategory: null,
                PHEValueType: 'Value',
                MetricTypeID: 0
            } as PHEMetricTypeMapping).then();
        }
        
    }

    if (props.context.values.IsImportedFromNomis) {
        return (
            <p>
                This metric type is partially imported from{' '}
                <a href="https://www.nomisweb.co.uk/" target="_blank">
                    Nomis
                </a>
            </p>
        );
    }
    if (props.context.values.StatXploreParameters !== null) {
        return (
            <StatXploreConfiguration {...props} />
        );
    }
    if (props.context.values.PHEMetricTypeMapping  !== null) {
        return (
            <PHEConfiguration context={props.context} />
        );
    }
    
    return (
        <Container fluid>
            <p>There is currently no import configuration set for this metric type</p>
            { canUpdate ? <>
                <div>
                    <Button color="link" className="px-0 pb-4" onClick={importButtonClick('statxplore')}>Import from Stat.Xplore</Button>
                </div>
                <div>
                    <Button color="link" className="px-0 pb-4" onClick={importButtonClick('phe')}>Import from PHE</Button>
                </div>
            </> : null }
        </Container>        
    );
}

const ImportConfiguration: FC<Props> = (props) => {
    const [show, setShow] = useState(false);
    const { data, isLoading } = useGetDetailsQuery(props.datasetNumber);

    if (isLoading || data === undefined) {
        return <div className="loading"/>;
    }
    const importedAreaTypes = data.Item.ApplicableGeoLevel?.filter(at => at.IndicatorInputMethod?.Identifier === 'Imported')??[];

    if (importedAreaTypes.length === 0) {
        return null;
    }


    return (
        <DetailedTableRow label="Import configuration">
            <Modal id="import-modal" isOpen={show} onClosed={() => setShow(false)} backdrop="static">
                <ModalHeader>
                    Import configuration
                </ModalHeader>
                <ModalBody>
                    <ImportConfigurationModalRoot context={props.context} />
                </ModalBody>
                <ModalFooter className="d-flex align-items-end">
                    <Button onClick={() => setShow(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            <p>
                There are {importedAreaTypes.length} <InfoLink
                alt={importedAreaTypes.map(at => at.GeoAreaLevel.Name).join(',')}/> area types that are imported in the dataset.
            </p>
            {props.context.values.CalculationParameters !== null 
                ? <>Import settings do not apply when the metric type is calculated</>
                : <Button onClick={() => setShow(true)} className="px-0" color="link">configure</Button>}
        </DetailedTableRow>
    );

}

export default ImportConfiguration;