import { FC, ReactNode } from 'react';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import { FormikProps } from 'formik';
import { MeasurementUnit, MeasurementUnitApiModel } from '../Store/MeasurementUnit/Types';
import DetailsForm from '../Common/DetailsForm/DetailsForm';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';

interface MeasurementUnitEditFormProps {
    measurementUnit: MeasurementUnitApiModel;
    children: (props: FormikProps<MeasurementUnit>) => ReactNode;
}


const MeasureEditForm: FC<MeasurementUnitEditFormProps> = (props) => {



    return (
        <DetailsForm isNew={false} hoverHelp={props.measurementUnit.HoverHelp} initialValues={props.measurementUnit.Item} onSubmit={() => {}}>
            {formProps => (
                <>
                    <DetailedTableHeader
                        label="Measurement unit details"
                        hoverProps={{ id: 'measurement-unit-heading' }}
                    />
                    <Container>                        
                        <DetailedTableFormRow
                            context={formProps}
                            editable={false}
                            label="Identifier"
                            name="Identifier"                            
                        />
                        <DetailedTableFormRow
                            context={formProps}                                 
                            name="Label"
                            label="Label"                            
                            editable={false}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            name="ShortLabel"
                            label="Short Label"
                            editable={false}
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            name="DimensionLabel"
                            label="Dimension Label"
                            editable={false}
                        />
                        <DetailedTableRow
                            label="Measurement unit type"                            
                            hoverProps={{ id: 'measurement-unit-type-heading' }}
                        >
                            {formProps.values.MeasurementUnitType.Label}
                        </DetailedTableRow>
                        <DetailedTableRow
                            label="Measurement unit data type"
                            hoverProps={{ id: 'measurement-unit-data-type-heading' }}
                        >
                            {formProps.values.DataType.Label}
                        </DetailedTableRow>
                    </Container>
                </>
            )
            }
        </DetailsForm>
    );
};

export default MeasureEditForm;