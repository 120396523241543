import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, SingleValue } from 'react-select';
import { customStyles } from '../../../Common/selectStyles';
import { FC } from 'react';
import { useLazyGetCalculationFieldDropdownOptionsQuery } from '../../../Store/Indicators/indicatorApi';
import { NumericOption } from '../../../Store/Indicators/Types';

interface AdditionalOptions {
    page: number;
    pageSize?: number
}

const defaultPageSize = 50;

interface PaginatedSelectorProps {
    methodNumber: number;
    fieldIdentifier: string;
    value?: NumericOption;
    onChange: (value: SingleValue<NumericOption>) => void;
    isClearable?: boolean;
    placeholder?: string;
}


const loadOptions = (trigger: any, methodNumber: number, fieldIdentifier: string): LoadOptions<NumericOption, any, AdditionalOptions> => async (
    q,
    prevOptions,
    additional
) => {
    
    const result = await trigger({
        page: additional?.page??1,
        pageSize: additional?.pageSize??defaultPageSize,
        methodNumber,
        fieldIdentifier,
        q
    }).unwrap();

    return {
        options: result,
        hasMore: result.length === (additional?.pageSize??defaultPageSize),
        additional: {
            page: (additional?.page??1)+1,
            pageSize: additional?.pageSize??defaultPageSize,
        }
    }
}

const PaginatedCalculationSelector: FC<PaginatedSelectorProps> = (props) => {    
    const [ trigger ] = useLazyGetCalculationFieldDropdownOptionsQuery();
    return (
        <AsyncPaginate<NumericOption, GroupBase<NumericOption>, AdditionalOptions>
            onChange={props.onChange}
            styles={customStyles}
            isOptionSelected={opt => props.value ? opt.Id === props.value.Id : false}
            value={props.value}
            loadOptions={loadOptions(trigger, props.methodNumber, props.fieldIdentifier)}
            defaultOptions={props.value ? [ props.value ] : undefined}
            defaultAdditional={{
                page: 1,
                pageSize: defaultPageSize,
            }}
            filterOption={opt => true}
            isClearable={props.isClearable}
            placeholder={props.placeholder}            
            menuPortalTarget={window.document.body}
            getOptionLabel={opt => opt.Name}
        />
    );

};

export default PaginatedCalculationSelector;