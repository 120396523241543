import { FC, useRef } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { HoverHelpEntry } from '../Store/Types';

export const HelpPopup : FC<{ id: string; text: string; }> = (props) => (
    <>
        <span id={props.id} className="hover-help ml-2" />
        <UncontrolledTooltip target={props.id}>
            {props.text}
        </UncontrolledTooltip>
    </>
);

export type HoverProps = {
    id?: string;
    name?: string;
    text?: string;
};

interface HoverHelpPopupProps extends HoverProps {     
    hoverHelp: HoverHelpEntry[]; 
}

export const HoverHelpPopup : FC<HoverHelpPopupProps> = (props) => {
    const entry = props.hoverHelp.find(e => (props.id && e.id === props.id) || (props.name && e.columnName === props.name));
    const text = entry?.help??props.text;
    const idref = useRef<string>(
        `hover-text-${props.id??props.name??window.crypto.randomUUID()}`
    );
    if (text) {
        return <HelpPopup id={idref.current} text={text} />;
    }
    return null;
};
