import { createColumnHelper, RowData } from '@tanstack/react-table';
import { MetricTypeDimensionIndicator, MetricTypeDimensionsModel } from '../../Store/Dimension/Types';
import { useMemo } from 'react';
import MetricSelector from './MetricSelector';
import DimensionSelector from './DimensionSelector';
import DimensionMemberSelector from './DimensionMemberSelector';

declare module '@tanstack/react-table' {
    //allows us to define custom properties for our columns
    interface ColumnMeta<TData extends RowData, TValue> {
        sticky?: boolean;
        stickyStart?: number;
        stickyShadow?: boolean;
    }
}

const columnHelper = createColumnHelper<MetricTypeDimensionIndicator>();

const useMetricTypeDimensionColumns = (result: MetricTypeDimensionsModel) => {
    return useMemo(() => {
        return [
            columnHelper.group({
                id: 'metric',
                header: 'Metric Type',
                meta: {
                    sticky: true,
                    stickyShadow: true
                },
                minSize: 450,
                columns: [
                    columnHelper.group({
                        id: 'metric-identifier-top',
                        enablePinning: true,
                        meta: {
                            sticky: true
                        },
                        minSize: 100,
                        header: 'Identifier',
                        columns: [
                            columnHelper.accessor('Indicator.Number', {
                                header: '',
                                minSize: 100,
                                size: 100,
                                id: 'metric-identifier',
                                meta: {
                                    sticky: true
                                },
                                enableSorting: true,
                                cell: props => props.getValue()
                            }),
                        ]
                    }),
                    columnHelper.group({
                        minSize: 350,
                        id: 'metric-label-top',
                        meta: {
                            sticky: true,
                            stickyStart: 100,
                            stickyShadow: true
                        },
                        header: 'Label',
                        columns: [
                            columnHelper.accessor('Indicator.Label', {
                                size: 350,
                                minSize: 350,
                                id: 'metric-label',
                                header: 'Total metric type (override)',
                                enableSorting: true,
                                meta: {
                                    sticky: true,
                                    stickyStart: 100,
                                    stickyShadow: true
                                },
                                cell: props => props.getValue()
                            })
                        ]
                    })
                ]
            }),

            ...result.Dimensions.map((d,index) => columnHelper.group({
                id: `dimension-${d.Id}`,
                header: `Circumstance ${index+1}`,
                size: 200,
                minSize: 200,
                columns: [
                    columnHelper.group({
                        id: `dimension-${d.Id}-selector`,
                        size: 200,
                        minSize: 200,
                        header: ctx => <DimensionSelector index={index} />, 
                        columns: [
                            columnHelper.accessor(accessor => accessor.DimensionMembers.find(dm => dm.Dimension.Id === d.Id), {
                                id: `dimension-${d.Id}-metricSelector`,
                                size: 200,
                                minSize: 200,
                                header: ctx => <MetricSelector dimension={d} value={result.TotalsOverride[d.Identifier]} />,
                                cell: props => <DimensionMemberSelector dimension={d} indicator={props.row.original.Indicator} />,
                            })
                        ]
                    })
                ]
            })),
            columnHelper.group({
                id: `new-dimension`,
                header: `Circumstance ${result.Dimensions.length+1}`,
                size: 200,
                minSize: 200,

                columns: [
                    columnHelper.group({
                        id: `new-dimension-selector`,
                        header: ctx => <DimensionSelector index={result.Dimensions.length+1} />,
                        size: 200,
                        minSize: 200,

                        columns: [
                            columnHelper.display({
                                id: `new-dimension-metricSelector`,
                                size: 200,
                                minSize: 200,

                                header: ctx => null,
                                cell: props => null,
                            })
                        ]
                    })
                ]
            })
        ];
        
    }, [ result ]);
}

export default useMetricTypeDimensionColumns;