import { createApi } from '@reduxjs/toolkit/query/react'
import { DimensionMember, DimensionMemberHierarchyItem, MetricTypeDimensionsModel } from './Types';
import { DetailsApiModel, UpdateResult } from '../Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const dimensionApi = createApi({
    reducerPath: 'dimensionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/dimension/'
    }),
    tagTypes: ['Dimension','DimensionMember','DimensionMetricConfiguration'],
    endpoints: (builder) => ({
         getDetails: builder.query<DetailsApiModel<DimensionMember>, string>({
            query: (dimensionIdentifier) => `details/${dimensionIdentifier}`,
            providesTags: (result?: DetailsApiModel<DimensionMember>) => result ? [{ type: 'DimensionMember', id: result.Item.Id }] : []
        }),
        getDimensionConfiguration: builder.query<MetricTypeDimensionsModel, string>({
            query: (measureIdentifier) => ({
               url: 'metricTypes',
               params: {
                   measureIdentifier
               }
            }),
            providesTags: (result?: MetricTypeDimensionsModel) => result ? [{ type: 'DimensionMetricConfiguration', id: result.Measure.Identifier }] : []
        }),
        updateDimensionConfiguration: builder.mutation<UpdateResult, MetricTypeDimensionsModel>({
            query: (measureIdentifier) => ({
                url: 'updateMetricTypes',
                method: 'POST',
                body: measureIdentifier
            }),
            invalidatesTags: (result, error, param) => result ? [{ type: 'DimensionMetricConfiguration', id: param.Measure.Identifier }] : []
        }),
        getMemberHierarchy: builder.query<DimensionMemberHierarchyItem[], string>({
            query: (measureIdentifier) => `memberHierarchy/${measureIdentifier}`,
            providesTags: (result, error, idenifier) => result ? [{ type: 'DimensionMember', id: `Hierarchy-${idenifier}` }] : []
        }),
        updateDetails: builder.mutation<void, DimensionMember>({
            query: (dimension) => ({
                url: 'update',
                method: 'POST',
                body: dimension,
            }),
        })
    }),
});


export const {
    useGetDetailsQuery,
    useUpdateDetailsMutation,
    useGetDimensionConfigurationQuery,
    useGetMemberHierarchyQuery,
    useUpdateDimensionConfigurationMutation
} = dimensionApi;

export default dimensionApi;
