import { FC } from 'react';
import { Button } from 'reactstrap';
import { FormikProps } from 'formik';



const SubmitButton: FC<{ 
    formProps: FormikProps<any>;
    onClick?: () => void
    label?: string;
}> = ({ formProps, onClick, label = 'Save' }) => (
    <Button
        type="submit"
        disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
        color="primary"         
        onClick={onClick}
    >
        {label}
    </Button>
);

export default SubmitButton;