import { FC, ReactNode, useContext } from 'react';
import { Input } from 'reactstrap';
import { HoverHelpPopup } from '../HelpPopup';
import { FormikContextType } from 'formik';
import { getFromPath, Paths, PathType } from './UtilityTypes';
import { DetailsFormAdditionalContext } from './DetailsForm';
import { NumericOption, Option } from '../../Store/Indicators/Types';
import {
    BaseProps,
    DetailedTableEditableRow,
    InputField
} from './BasicInput';


export interface DetailedTableEnumRowProps<T extends Record<string, any>, TKey extends Paths<T>> extends Omit<DetailedTableFormRowProps<T, TKey>,'inputField'|'formatValue'> {
    enumValues: (Option|NumericOption)[];
}


export interface DetailedTableFormRowProps<T extends Record<string, any>, TKey extends Paths<T>> extends BaseProps {
    context: FormikContextType<T>;
    name: TKey;
    formatValue?: (value: PathType<T, TKey>|null|undefined) => ReactNode;
    editable?: boolean;
    alwaysEditing?: boolean;
    onChange?: (newVal: PathType<T, TKey>) => void;
    inputField?: InputField<PathType<T, TKey>>;
    inputFieldChangePropagates?: boolean;
    useNumberForNumericField?: boolean;
    append?: ReactNode;
}


export const DetailedTableFormRow = <T extends Record<string, any>, TKey extends Paths<T>>(props: DetailedTableFormRowProps<T, TKey>) => {    
    const { hoverHelp } = useContext(DetailsFormAdditionalContext);
    const {
        context: { values },
    } = props;
    const value = getFromPath(values, props.name);
    const changeHandler = (newVal: T[TKey]|null|undefined) => {
        if (props.inputField && !props.inputFieldChangePropagates) {
            props.onChange?.(newVal as any)
        } else {
            props.context.setFieldValue(props.name, newVal)
                .then(() => props.onChange?.(newVal as any));
        }
    } 
    
    return (
        <DetailedTableEditableRow<PathType<T, TKey>>            
            label={props.label}
            hoverProps={props.hoverProps??hoverHelp?.find(prop => prop.columnName === props.name || (prop.id === props.id && props.id !== undefined))}
            row={props.row}
            className={props.className}
            editable={props.editable}
            onChange={changeHandler}
            value={value}
            useNumberForNumericField={props.useNumberForNumericField}
            formatValue={props.formatValue}
            name={props.name}
            align={props.align}
            inputField={props.inputField}
            id={props.id}
            alwaysEditing={props.alwaysEditing}
            error={getFromPath<any, any>(props.context.errors, props.name)?.toString() ?? undefined}
            append={props.append}
            required={props.required}
        />
    )
}

export const DetailedTableEnumRow = <T extends Record<string, any>, TKey extends Paths<T>>(
    { enumValues, ...props }: DetailedTableEnumRowProps<T, TKey>
) => {
    const value = getFromPath(props.context.values, props.name);
    const selected = enumValues.find(v => v.Id === value);
    
    return (
        <DetailedTableFormRow
            {...props}
            formatValue={() => selected?.Name}
            inputFieldChangePropagates
            inputField={inputProps => (
                <Input
                    type="select"
                    onChange={e => {
                        const newOption = enumValues.find(v => v.Id == e.currentTarget.value);
                        if (newOption) {
                            inputProps.onChange?.(newOption.Id as PathType<T, TKey>);
                            inputProps.onDoneEditing();
                        }
                    }}
                    onBlur={inputProps.onDoneEditing}
                    value={selected?.Id??''}
                >   
                    {enumValues.map(v => (
                        <option key={v.Id??''} value={v.Id??''}>{v.Name}</option>
                    ))}
                </Input>
            )}
        />
    )
}


type DetailedTableHeaderProps =  BaseProps;


export const DetailedTableHeader: FC<DetailedTableHeaderProps> = (props) => {
    const { hoverHelp } = useContext(DetailsFormAdditionalContext);
    return (
        <h2 className="d-flex align-items-center detailed-table-header">{props.label} {props.hoverProps && hoverHelp ?
            <HoverHelpPopup hoverHelp={hoverHelp} {...props.hoverProps} /> : null}</h2>
    );
}