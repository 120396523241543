import { FC, ReactNode } from 'react';
import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import { FormikProps } from 'formik';
import { DimensionMember } from '../Store/Dimension/Types';
import { DetailsApiModel } from '../Store/Types';
import { useUpdateDetailsMutation } from '../Store/Dimension/dimensionApi';
import DetailsForm from '../Common/DetailsForm/DetailsForm';
import { DetailedTableRow } from '../Common/DetailsForm/BasicInput';

interface MeasurementUnitEditFormProps {
    dimension: DetailsApiModel<DimensionMember>;
    children: (props: FormikProps<DimensionMember>) => ReactNode;
}


const DimensionEditForm: FC<MeasurementUnitEditFormProps> = (props) => {

    const [ update ] = useUpdateDetailsMutation();

    return (
        <DetailsForm isNew={false} hoverHelp={props.dimension.HoverHelp} initialValues={props.dimension.Item} onSubmit={(values) => update(values)}>
            {formProps => (
                <>
                    <DetailedTableHeader
                        label="Dimension member details"
                        hoverProps={{ id: 'dimension-member-heading' }}
                    />
                    <Container>
                        <DetailedTableRow 
                            label={<span style={{ fontSize: 'large' }}>Circumstance collection</span>}
                            hoverProps={{ id: 'dimension-sub-heading' }}
                        >
                            &nbsp;
                        </DetailedTableRow>
                        <DetailedTableFormRow
                            context={formProps}
                            editable={false}
                            label="Label"
                            name="Dimension.Label"                            
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Description"
                            name="Dimension.Description"                            
                        />
                        <DetailedTableRow
                            label={<span style={{ fontSize: 'large' }}>Circumstance</span>}
                            hoverProps={{ id: 'dimension-member-sub-heading' }}
                        >
                            &nbsp;
                        </DetailedTableRow>
                        <DetailedTableFormRow
                            context={formProps}
                            editable={false}
                            label="Identifier"
                            name="Identifier"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            editable={false}
                            label="Label"
                            name="Label"
                        />
                        <DetailedTableFormRow
                            context={formProps}
                            label="Description"
                            name="Description"
                        />
                        <FormToolkit>
                            {props.children(formProps)}
                        </FormToolkit>
                    </Container>
                </>
            )
            }
        </DetailsForm>
    );
};

export default DimensionEditForm;