import { FC, useContext, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { DetailedTableRow, EditButton } from '../../Common/DetailsForm/BasicInput';
import { Indicator } from '../../Store/Indicators/Types';
import { useFormikContext } from 'formik';
import { DetailsFormAdditionalContext } from '../../Common/DetailsForm/DetailsForm';

const FormattedTextEditor : FC = () => {
    const { canUpdate, isNew } = useContext(DetailsFormAdditionalContext);
    const [ editing, setEditing ] = useState(isNew);
    const ctx = useFormikContext<Indicator>();
    const [ initialValue, setIntialValue ] = useState(ctx.values.FormattedHelpText); 
    
    return (
        <DetailedTableRow label="Help text" required error={ctx.errors.FormattedHelpText}>
            { editing ? 
            <Editor
                initialValue={initialValue}
                onBlur={() => !isNew ? setEditing(false) : undefined}
                tinymceScriptSrc="/scripts/tinymce/tinymce.js"
                init={{
                    menu: undefined,
                    menubar: false,
                    plugins: [ "link", "code" ],
                    toolbar: 'bold italic underline | bullist | link | undo redo | code',
                    browser_spellcheck: true,
                    force_br_newlines: true,
                    force_p_newlines: false,
                    forced_root_block: ''
                }}
                onEditorChange={newValue => ctx.setFieldValue('FormattedHelpText', newValue)}
            /> : (
                <>
                    <span dangerouslySetInnerHTML={{ __html: ctx.values.FormattedHelpText }} /> <EditButton canUpdate={canUpdate} editable onSetEditing={() => {
                        setIntialValue(ctx.values.FormattedHelpText);
                        setEditing(true);
                }} />
                </>
            ) }
        </DetailedTableRow>
        
    );
}

export default FormattedTextEditor;