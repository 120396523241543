import { useAppDispatch, useAppSelector } from '../../Store/store';
import { tasksSlice } from '../../Store/Tasks/tasksSlice';
import { queryable } from '../../Utils';
import { FC } from 'react';
import { CellContext } from '@tanstack/react-table';
import { TaskGroup } from '../../Store/Tasks/Types';
import NextToBeLoadedValue from './NextToBeLoaded';

const NextToBeLoadedColumn: FC<{ val: CellContext<TaskGroup, string> }> = ({ val }) => {
    const dispatch = useAppDispatch();
    const editingTask = useAppSelector(state => state.taskSlice?.task);
    const isEditing = editingTask?.Id === val.row.original.Id;
    return (
        <NextToBeLoadedValue
            onChange={(date: Date|null) => {
                dispatch(tasksSlice.actions.changeDateProperty({
                    property:'NextCollected',
                    newValue: date?.toISOString()??null
                }))
            }}
            isEditing={isEditing}
            id={`NL-${queryable(val.row.id)}`}
            task={editingTask ?? val.row.original}
        />
    );
}

export default NextToBeLoadedColumn;