import { Fragment, HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import {
    ColumnDef,
    Row,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
    VisibilityState,
    SortingState,
    Updater,
    getFacetedMinMaxValues,
    ColumnFiltersState,
    getPaginationRowModel
} from '@tanstack/react-table'
import { Table } from 'reactstrap';
import SortableHeader from './SortableHeader';
import FilterableHeader from './FilterableHeader';
import { useSearchParams } from 'react-router-dom';
import PaginationControl from './PaginationControl';


interface DSLibTableProps<T> {
    className?: string;
    defaultInitialSearch?: Record<string, string>;
    data: T[];
    columns: ColumnDef<T, any>[];
    initialVisibilityState?: VisibilityState;
    initialFilters?: ColumnFiltersState;
    getSubRows?: (item: T) => T[]|undefined;
    getRowProps?: (row: Row<T>) => HTMLAttributes<HTMLTableRowElement>;
    paginate?:boolean;
}

const DSLibTable = <T, >(props: DSLibTableProps<T>): ReactNode => {
    const {
        columns,
        data,
        initialVisibilityState,
        initialFilters,
        getSubRows,
        getRowProps,
        className,
        defaultInitialSearch,
        paginate = true
    } = props;

    const [searchParams, setSearchParams] = useSearchParams(defaultInitialSearch);
    const [visibleState, setVisibleState] = useState<VisibilityState>(initialVisibilityState ?? {});

    const sorting = useMemo(() => {
        const currentSort = searchParams.get('sort')??defaultInitialSearch?.sort;
        if (!currentSort) {
            return [];
        }
        const allSorts = currentSort.split(',');
        return allSorts.filter(s => !!s).map(s => {
            const [ id, direction = 'asc' ] = s.split(' ');
            return {
                id,
                desc: direction.localeCompare('desc') === 0
            };
        });

    }, [ searchParams.get('sort'), defaultInitialSearch?.sort ])

    const setSorting = (updater: Updater<SortingState>) => {
        const newValue = typeof updater === 'function' ? updater(sorting) : sorting;
        const newSearchParam = new URLSearchParams(searchParams);
        newSearchParam.delete('sort');
        if (newValue.length > 0 ) {
            newSearchParam.set('sort', `${newValue[0].id} ${newValue[0].desc?'desc':'asc'}`);
        }
        setSearchParams(newSearchParam);
    }


    const table = useReactTable({
        data,
        columns,
        
        
        initialState: {
            columnFilters: initialFilters
        },
        state: {
            columnVisibility: visibleState,
            sorting: sorting
        },
        onSortingChange: setSorting,
        ...(getSubRows ? { getSubRows } : {}),
        onColumnVisibilityChange: setVisibleState,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        getPaginationRowModel: paginate ? getPaginationRowModel() : undefined
    });

    return (
        <>
        <Table className={className}>
            <thead>
            {table.getHeaderGroups().map(headerGroup => (
                <Fragment key={headerGroup.id}>
                    <tr>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} colSpan={header.colSpan}>
                                {header.isPlaceholder
                                    ? null
                                    : <SortableHeader header={header}
                                                      isVisible={visibleState[header.column.id] ?? true}
                                                      onHideToggle={() => setVisibleState({
                                                          ...visibleState,
                                                          [header.id]: !(visibleState[header.id] ?? true)
                                                      })}/>}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {headerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder ? null :
                                    <FilterableHeader column={header.column}/>}
                            </th>
                        ))}
                    </tr>
                </Fragment>
            ))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (
                <tr key={row.id} {...(getRowProps?.(row)??{})}>
                    {row.getVisibleCells().map(cell => (
                        <td key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </Table>
            { paginate ? <PaginationControl isTop={false} table={table} totalTotal={data.length} /> : null}
        </>
    );
}

export default DSLibTable;
