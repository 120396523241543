import { DetailedTableFormRow, DetailedTableHeader } from '../Common/DetailsForm/DetailedTable';
import { Container } from 'reactstrap';
import { Source, SourceModel } from '../Store/Source/Types';
import { FC, ReactNode } from 'react';
import ChangeDetails from '../Common/DetailsForm/ChangeDetails';
import FormToolkit from '../Common/DetailsForm/FormToolkit';
import { NewDetailsApiModel } from '../Store/Types';
import { FormikProps, Form } from 'formik';
import FormikDetailsForm from '../Common/DetailsForm/DetailsForm';

interface SourceEditFormPropsBase {
    onSubmit: (values: Source) => void|Promise<void>;
    children: (props: FormikProps<Source>) => ReactNode;
}

interface NewSourceEditFormProps extends SourceEditFormPropsBase {
    isNew: true;
    source: NewDetailsApiModel<Source>;
}

interface ExistingSourceEditFormProps extends SourceEditFormPropsBase {
    isNew: false;
    source: SourceModel;
}

type SourceEditFormProps = NewSourceEditFormProps|ExistingSourceEditFormProps;



const SourceEditForm : FC<SourceEditFormProps> = (props) => {

    let emptySource: Source = {
        Label: '',
        Id: '<determined after adding>' as any,
        Created: '',
        ContributorId: null,
        CreatorId: null,
        Number: '<determined after adding>' as any,
        Modified: ''
    };





    return (
        <FormikDetailsForm
            isNew={props.isNew}            
            hoverHelp={props.source.HoverHelp}
            initialValues={!props.isNew ? props.source.Item : emptySource}
            onSubmit={props.onSubmit}
            validate={values => !values.Label ? { Label: 'Label must not be empty' } : undefined }
            validateOnMount={!props.isNew}
            enableReinitialize
        >
            {formProps => (
                <Form>
                    <DetailedTableHeader
                        label="Source details"
                        hoverProps={{ id: 'source-heading' }}
                    />
                    <Container>
                        {!props.isNew ? <ChangeDetails {...props.source} /> : null}
                        {!props.isNew ? <DetailedTableFormRow
                            context={formProps}
                            name="Number"
                            label="Identifier"
                            hoverProps={{ id: 'identifier-heading' }}
                            editable={false}
                        /> : null }
                        <DetailedTableFormRow
                            context={formProps}
                            label="Label"
                            name="Label"
                            hoverProps={{ id: 'label-heading' }}
                            editable
                            alwaysEditing={props.isNew}
                        />
                    </Container>
                    <FormToolkit>
                        {props.children(formProps)}
                    </FormToolkit>
                </Form>
            )
            }
        </FormikDetailsForm>
    );
};

export default SourceEditForm;