import { FC, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Page from '../Common/Page';
import { SourceListingEntry } from '../Store/Source/Types';
import InfoLink from '../Common/InfoLink';
import Table from '../Common/Table';
import { useGetSourceListQuery } from '../Store/Lists/listApi';
import { toast } from 'react-toastify';



const columnHelper = createColumnHelper<SourceListingEntry>();

const columns = [
    columnHelper.accessor(row => row.Label.trim(), {
        id: 'label',
        cell: val => <Link
            to={`/source/${val.row.original.Number}`}>{val.row.original.Label} ({val.row.original.Number})</Link>,
        header: 'Source',
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'details',
        cell: (context) => <Link to={`/source/details/${context.row.original.Number}`}><InfoLink
            alt={`View details for source ${context.row.original.Number}`}/> </Link>,
        header: () => 'Details',
        enableColumnFilter: false,
    }),
    columnHelper.accessor('NumberOfCollections', {
        cell: val => val.getValue(),
        header: () => 'Number of collections',
        enableColumnFilter: true,
        meta: {
            filterVariant: 'range'
        }
    }),

];


const List: FC = () => {
    const { data: loadedData, error, isLoading } = useGetSourceListQuery();

    const data = useMemo(() => {
        if (loadedData && !error && !isLoading) {
            return loadedData.Sources;
        }
        return [] as SourceListingEntry[];
    }, [loadedData !== undefined, error, isLoading]);

    if (error) {
        toast.error('Error loading collection list');
    }


    return (
        <Page loading={isLoading}>
            {!isLoading ? <Table data={data} columns={columns}  /> : null}
        </Page>
    );
}

export default List;
