import { GeoAreaLevel } from '../../Store/GeoArea/Types';
import { GeoAreaLevelLink } from '../../Store/Lists/Types';

export const getAllChildAreaTypes = (
    parentArea: GeoAreaLevel,
    areaTypes: GeoAreaLevel[],
    areaLinks: GeoAreaLevelLink[]
) => {
    const childLinks = areaLinks.filter(link => link.ParentLevelID === parentArea.Id);
    return areaTypes.filter(areaType => childLinks.some(link => link.ChildLevelID === areaType.Id));
}