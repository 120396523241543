import { FC, ReactNode } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classNames from 'classnames';

interface IconProps {
    id?: string;
    fill?: string
    width?: number;
    height?: number;
    className?: string;
}


export const PlusCircle: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-plus-circle', className)} 
         viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
    </svg>
);

export const MinusCircle: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-dash-circle', className)}
         viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
    </svg>
);


export const SortArrow: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-arrow-down-up', className)}
         viewBox="0 0 16 16">
        <path fillRule="evenodd"
              d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5m-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5"/>
    </svg>
);

export const SortArrowAsc: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-arrow-up', className)}
         viewBox="0 0 16 16">
        <path fillRule="evenodd"
              d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5"/>
    </svg>
);

export const SortArrowDesc: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-arrow-down', className)}
         viewBox="0 0 16 16">
        <path fillRule="evenodd"
              d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
    </svg>
);

export const ArrowsCollapseVertical: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'}
         className={classNames('bi bi-arrows-collapse-vertical', className)} viewBox="0 0 16 16">
        <path
            d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M0 8a.5.5 0 0 1 .5-.5h3.793L3.146 6.354a.5.5 0 1 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L4.293 8.5H.5A.5.5 0 0 1 0 8m11.707.5 1.147 1.146a.5.5 0 0 1-.708.708l-2-2a.5.5 0 0 1 0-.708l2-2a.5.5 0 0 1 .708.708L11.707 7.5H15.5a.5.5 0 0 1 0 1z"/>
    </svg>
);

export const ArrowsExpandVertical: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'}
         className={classNames('bi bi-arrows-expand-vertical', className)} viewBox="0 0 16 16">
        <path
            d="M8 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5M.146 8.354a.5.5 0 0 1 0-.708l2-2a.5.5 0 1 1 .708.708L1.707 7.5H5.5a.5.5 0 0 1 0 1H1.707l1.147 1.146a.5.5 0 0 1-.708.708zM10 8a.5.5 0 0 1 .5-.5h3.793l-1.147-1.146a.5.5 0 0 1 .708-.708l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 0 1-.708-.708L14.293 8.5H10.5A.5.5 0 0 1 10 8"/>
    </svg>
);

export const Gear: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-gear', className)}
         viewBox="0 0 16 16">
        <path
            d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0"/>
        <path
            d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z"/>
    </svg>
);

export const ArrowCounterclockwise: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'}
         className={classNames('bi bi-arrow-counterclockwise', className)} viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2z"/>
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466"/>
    </svg>
);

export const InfoCircle: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} xmlns="http://www.w3.org/2000/svg" width={width ?? 24} height={height ?? 24} fill={fill ?? 'black'}
         className={classNames('bi bi-info-circle', className)}
         viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path
            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
    </svg>
);

export const PlusLg: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-plus-lg', className)}
         viewBox="0 0 16 16">
        <path fillRule="evenodd"
              d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
    </svg>
);

export const DashLg: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg id={id} width={width ?? 24} height={height ?? 24} fill={fill ?? 'currentColor'} className={classNames('bi bi-dash-lg', className)}
         viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8"/>
    </svg>
);

export const Clock: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'} className={classNames('bi bi-clock', className)}
         viewBox="0 0 16 16">
        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
    </svg>
);


export const CalendarEventFill: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'}
         className={classNames('bi bi-calendar-event-fill', className)} viewBox="0 0 16 16">
        <path
            d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-3.5-7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5"/>
    </svg>
);


export const NodePlusFill: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'}
         className={classNames('bi bi-node-plus-fill', className)}
         viewBox="0 0 16 16">
        <path
            d="M11 13a5 5 0 1 0-4.975-5.5H4A1.5 1.5 0 0 0 2.5 6h-1A1.5 1.5 0 0 0 0 7.5v1A1.5 1.5 0 0 0 1.5 10h1A1.5 1.5 0 0 0 4 8.5h2.025A5 5 0 0 0 11 13m.5-7.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2a.5.5 0 0 1 1 0"/>
    </svg>
);

export const PencilFill: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'}
         className={classNames('bi bi-pencil-fill', className)}
         viewBox="0 0 16 16">
        <path
            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z"/>
    </svg>
);

export const Floppy: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'}
         className={classNames('bi bi-floppy2', className)}
         viewBox="0 0 16 16">
        <path
            d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
    </svg>
);

export const XCircle: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         className={classNames('bi bi-x-circle', className)}
         fill={fill ?? 'currentColor'}
         viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
        <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
    </svg>
);

export const Padlock: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg" id={id} width={width ?? 24} height={height ?? 24}
         className={classNames('bi bi-lock', className)}
         fill={fill ?? 'currentColor'}
         viewBox="0 0 16 16">
        <path
            d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
    </svg>
);

export const Funnel: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'}     
         className={classNames('bi bi-funnel-fill', className)}
         viewBox="0 0 16 16">
        <path
            d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z"/>
    </svg>
)

export const CsvFile: FC<IconProps> = ({ id, fill, width, height, className }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         id={id} width={width ?? 24} height={height ?? 24}
         fill={fill ?? 'currentColor'}
         className={classNames('bi bi-filetype-csv', className)}
         viewBox="0 0 16 16"
    >
        <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z" />
    </svg>
)

export type IconWithTooltipProps = (Omit<IconProps,'id'>&{id: string})&{
    tooltip: ReactNode;
    icon: FC<IconProps>;
}

export const IconWithTooltip: FC<IconWithTooltipProps> = ({ tooltip, ...props }) => (
    <>
        <UncontrolledTooltip target={props.id}>{tooltip}</UncontrolledTooltip>
        {props.icon(props)}
    </>
);

export const ChevronDown: FC<IconProps> = ({ id, width, height, fill, className }) => (
    <svg
        id={id} width={width ?? 24} height={height ?? 24}
        fill={fill ?? 'currentColor'}
        className={classNames('bi bi-chevron-down', className)}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"/>
    </svg>
);

export const ChevronUp: FC<IconProps> = ({ id, width, height, fill, className }) => (
    <svg
        id={id} width={width ?? 24} height={height ?? 24}
        fill={fill ?? 'currentColor'}
        className={classNames('bi bi-chevron-up', className)}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>

    </svg>
);

export const PersonUp : FC<IconProps> = ({ id, width, height, fill, className }) =>  (
    <svg  id={id} width={width ?? 24} height={height ?? 24}
          fill={fill ?? 'black'}
          className={classNames('bi bi-chevron-up', className)}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
        <path
            d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
    </svg>
);

export const Upload : FC<IconProps> = ({ id, width, height, fill, className }) => (
    <svg  id={id} width={width ?? 24} height={height ?? 24}
          fill={fill ?? 'black'}
          className={classNames('bi bi-upload', className)}
          viewBox="0 0 16 16"
    >
        <path
            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
        <path
            d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z"/>
    </svg>
)