import { FC } from 'react';
import { Task, TaskGroup } from '../Store/Tasks/Types';
import { Button, Col, UncontrolledTooltip } from 'reactstrap';
import { queryable, resourcesLink } from '../Utils';
import { useAppSelector } from '../Store/store';
import { TaskTableScope } from '../Store/TaskTable/Types';
import InfoLink from '../Common/InfoLink';
import ButtonWithTooltip from '../Common/ButtonWithTooltip';
import { ArrowCounterclockwise } from '../Common/Icons';
import { Link } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import { useRefreshTaskMutation } from '../Store/Tasks/tasksApi';
import { Source } from '../Store/Source/Types';
import { toast } from 'react-toastify';
import TaskToolbar from './TaskToolbar';
import NonTaskToolbar from './NonTaskToolbar';



export interface ToolbarProps {
    scope: TaskTableScope;
    row: Row<TaskGroup>;
}


const Toolbar: FC<ToolbarProps> = (props) => {
    return (
        props.scope === 'Task' ? <TaskToolbar {...props} /> : <NonTaskToolbar {...props} />
    );
}

export default Toolbar;