import { FC } from 'react';
import Page from '../Common/Page';
import { useGetAllTasksQuery, useGetLatestIndicatorTableForSourceQuery } from '../Store/TaskTable/taskTableApi';
import TaskTable from '../TaskTable';
import { AddSourceButton, DownloadAllMetricsButton } from '../Widgets/Buttons';
import { useNavigateIf, useNavigateOnError } from '../hooks';
import { toast } from 'react-toastify';
import { formatErrorMessage } from '../Utils';

const Home: FC = () => {
    const { data: loadedData, error, isLoading } = useGetAllTasksQuery();
    if (error) {
        toast.error(formatErrorMessage(error, 'An error occured loading task data'));
    }
    return (
        <Page wide loading={isLoading} buttons={[ 
            <DownloadAllMetricsButton allMetricTypes />, 
            <AddSourceButton /> 
        ]}>
            { !isLoading && loadedData ? <TaskTable data={loadedData} /> : null }
        </Page>
    );
}

export default Home;
