import { ListingSelectorField, ListingSelectorFieldProp } from './ListingSelectorField';
import { FormikContextType } from 'formik';
import { getFromPath, Paths, PathType } from './UtilityTypes';
import { Fragment, useMemo } from 'react';
import { GroupBase } from 'react-select';


export interface ListingSelectorFormFieldProps<T, TKey extends Paths<T>, TOption extends Record<string,any>, TListing,TGroup extends GroupBase<TOption> = GroupBase<TOption>> extends Omit<ListingSelectorFieldProp<TOption, TListing, TGroup>, 'onItemAdded'|'onRemoveItem'|'children'|'areEqual'> {
    context: FormikContextType<T>;
    name: TKey;
    areEqual?: (a: TOption, b: TOption) => boolean;
    identify: (a: TOption) => string;
}


const ListingSelectorFormField = <T extends Record<string,any>, TKey extends Paths<T>, TOption extends Record<string,any>, TListing, TGroup extends GroupBase<TOption> = GroupBase<TOption>>(
    { 
        name,
        context,      
        isOptionDisabled,
        ...props 
    }: ListingSelectorFormFieldProps<T, TKey, TOption, TListing, TGroup>
) => {     
    const value: TOption[] = getFromPath(context.values, name);
    const areEqual = props.areEqual ?? ((a: TOption, b: TOption) => props.identify(a) === props.identify(b));
    const options = useMemo(() => {
        return !props.hookResult.isLoading && props.hookResult.data
            ? props.getDataFromListing(props.hookResult.data)
            : undefined;
    }, [ props.getDataFromListing, props.hookResult.isLoading, props.hookResult.data ]);
    const isOptionDisabledCombined = (option: TOption) => {
        return value.some((selected: TOption) => areEqual(option, selected)) || (isOptionDisabled?.(option)??false);
    }
    
    const onItemAdded = (item: TOption) => {
        if (value.some((selected: TOption) => areEqual(item, selected))) {
            return;
        }
        context.setFieldValue(name, [ ...value, item ]).then();
    }
    const onRemoveItem = (item: number) => {
        context.setFieldValue(name, [ ...value.slice(0, item), ...value.slice(item+1) ]).then();
    }
    const formatter = props.formatLabelNode??props.formatLabel;
    
    return (
        <ListingSelectorField<TOption, TListing, TGroup> 
            {...props}
            areEqual={areEqual}
            onItemAdded={onItemAdded} 
            onRemoveItem={onRemoveItem}
            isOptionDisabled={isOptionDisabledCombined}
        >
            {value.map(item => <Fragment key={props.identify(item)}>{formatter(item, options)}</Fragment>)}
        </ListingSelectorField>    
    )
}

export default ListingSelectorFormField;