import { getFromPath, Paths } from './UtilityTypes';
import { ChangeEvent, ReactNode } from 'react';
import { FormFeedback, Input } from 'reactstrap';
import { ErrorMessage, FormikContextType } from 'formik';
import { detailedTableTextGetter } from './BasicInput';


export interface DetailedTableInputProps<T extends Record<string, any>, TKey extends keyof T> {
    context: FormikContextType<T>;
    name: TKey;
    onDoneEditing: () => void;
    useNumberForNumericField?: boolean;
}

export interface DetailedTableBasicInputProps<TValue> {
    id?: string;
    name?: string;
    busy?: boolean;
    value?: TValue;
    error?: ReactNode;    
    onDoneEditing?: () => void;
    useNumberForNumericField?: boolean;
    onChange?: (value: TValue|null|undefined) => void;
}

export const DetailedTableBasicInput = <TValue,>(props: DetailedTableBasicInputProps<TValue>) => {    
    const invalid = !!props.error;
    const onBlur = () => {
        if (!invalid) {
            props.onDoneEditing?.();
        }
    };
    
    const inputType = typeof props.value! === 'boolean'
        ? 'select'
        : (
            typeof props.value! === 'number' && props.useNumberForNumericField ?  'number' : 'text'
        );
    const myHandleChange = (e: ChangeEvent<any>) => {
        if (inputType === 'select' && typeof props.value! === 'boolean') {
            return props.onChange?.((e.currentTarget.value === 'Yes') as TValue|null|undefined);
        }
        return props.onChange?.(e.currentTarget.value as TValue|null|undefined);
    }
    const value = detailedTableTextGetter(props.value);


    return (
        <>
            <Input
                id={props.id}
                type={inputType}
                invalid={invalid}
                disabled={props.busy}
                name={props.name}
                value={value}
                onChange={myHandleChange}
                onBlur={onBlur}
            >
                { inputType === 'select' ? (
                    <>
                        <option>No</option>
                        <option>Yes</option>
                    </>
                ) : null}
            </Input>
            <FormFeedback>
                {props.error ? <p>{props.error}</p> : null} 
            </FormFeedback>
        </>
    );
}

export const DetailedTableDefaultInput = <T extends Record<string, any>, TKey extends Paths<T>>(props: DetailedTableInputProps<T, TKey>) => {
    const { setFieldValue, values, isSubmitting } = props.context;
    const value = detailedTableTextGetter(getFromPath(values, props.name));

    return (
        <DetailedTableBasicInput
            name={props.name}
            value={value}
            onChange={newVal => setFieldValue(props.name, newVal)}
            busy={isSubmitting}
            onDoneEditing={props.onDoneEditing}
            useNumberForNumericField={props.useNumberForNumericField}
            error={props.context.errors[props.name] ? props.context.errors[props.name]!.toString():undefined}
        />
    );
}
