import { FC } from 'react';
import { RouterProvider } from 'react-router-dom';
import Router from './Router';
import { Provider } from 'react-redux';
import { store } from './Store/store';
import { ToastContainer } from 'react-toastify';
import NotFound from './NotFound';


const App: FC = () => {

    return (
        <Provider store={store}>
            <ToastContainer />
            <RouterProvider router={Router} />
        </Provider>
    );
}

export default App;