import { FC } from 'react';
import { ChangeDetails } from '../../Store/Types';
import { DetailedTableRow } from './BasicInput';

const ChangeDetails : FC<ChangeDetails> = (props) => (
    <>
        <DetailedTableRow label="Created">{props.CreatedDescription}</DetailedTableRow>
        <DetailedTableRow label="Last modified">{props.ModifiedDescription}</DetailedTableRow> 
    </>
);

export default ChangeDetails;