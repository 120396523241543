import { FC, ReactNode, useMemo } from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { useNavigate } from 'react-router';
import { useGetMetricTypeEmailMutation, useUploadMutation } from '../Store/Indicators/indicatorApi';
import { MetricTypeFilterModel, UploadEndpointParams } from '../Store/Indicators/Types';
import { toast } from 'react-toastify';
import { useSubmitHandler } from '../hooks';
import { UpdateResult } from '../Store/Types';
import { ucfirst } from '../Utils';
import { useRefreshCollectionMutation } from '../Store/Collection/collectionApi';



export const UploadButton: FC<UploadEndpointParams> = (props) => {
    const mapType = props.type !== 'metricType' 
        ?  ucfirst(props.type) : 'Metric Type';
    
    const [ trigger ] = useUploadMutation();
    const handler = useSubmitHandler<UploadEndpointParams, UpdateResult>({
        init: 'Adding immediate to queue upload',
        error: 'Error adding immediate upload to queue',
        success: `An immediate upload for ${props.type !== 'all' ? `the selected ${mapType}` : 'everything'} has been queued` 
    }, trigger);
    
    
    return <Button onClick={e => handler(props)}>Upload {mapType}</Button>
}


export const DownloadAllMetricsButton: FC<MetricTypeFilterModel&{ children?:ReactNode }> = (params) => {    
    const [ allTrigger ] = useGetMetricTypeEmailMutation();
    const handleClick = async () => {
        const id = toast.loading('Starting metric type download...');
        try {
            const result = await allTrigger(params);
            if (result.error) {
                toast.error('Failed to start download');
            } else {
                toast.success(result.data.message);
            }
        } catch (e) {
            toast.error('Failed to start download');
        } finally {
            toast.done(id);
        }    
    }
    if (params.allMetricTypes) {
        return (
            <Button color="secondary" onClick={handleClick}>
                {params.children??'Download all metric types'}
            </Button>
        );
    } 
    const url = new URL('/api/metricType/CentralMetricTypeTemplate', (
        typeof window !== 'undefined' ? window.location.href : 'https://datasetlibrary.esd.org.uk'
    ));
    url.search = '';
    for (let entry of Object.entries(params)) {
        if (entry[0] !== 'children') {
            url.searchParams.set(entry[0], entry[1]);
        }
    }
    
    return (
        <Button color="secondary" href={url.toString()}>
            {params.children??'Download metric types'}
        </Button>
    )
}

export const AddSourceButton: FC = () => {
    const nav = useNavigate();
    const onclick: ButtonProps['onClick'] = (e) => {
        e.preventDefault();
        nav('/source/add');
    }
    
    return (
        <Button href="/source/add" color="secondary" onClick={onclick}>
            Add source
        </Button>
    );
}