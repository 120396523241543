import { createApi } from '@reduxjs/toolkit/query/react'
import { AddResult, NewDetailsApiModel, UpdateResult } from '../Types';
import { Measure, MeasureApiModel } from './Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const measureApi = createApi({
    reducerPath: 'measureApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/measure/'
    }),
    tagTypes: ['Measure'],
    endpoints: (builder) => ({
        getAddPage: builder.query<NewDetailsApiModel<Measure, 'Dataset'|'DatasetId'>, number>({
            query: (dataset: number) => ({
                url: `add`,
                params: {
                    dataset
                }
            }),
            providesTags: (res, err, measure) =>  [
                { type: 'Measure', id: `Add-${measure}` }
            ]
        }),
        getDetails: builder.query<MeasureApiModel, string>({
            query: (measure) => `details/${measure}`,
            providesTags: (result?: MeasureApiModel) => result ? [{ type: 'Measure', id: result.Item.Identifier }] : []
        }),
      
        updateDetails: builder.mutation<UpdateResult, Measure>({
            query: (data) => ({
                url: `update`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, param) => result ? [{ type: 'Measure', id: param.Identifier }] : []
        }),
        add: builder.mutation<AddResult, Measure>({
            query: (data) => ({
                url: 'add',
                method: 'POST',
                body: data,
            })
        }),
        delete: builder.mutation<void, Measure>({
            query: (data) => ({
                url: `delete/${data.Identifier}`,
                method: 'DELETE'
            })
        })
    }),
});


export const {
    useGetAddPageQuery,
    useGetDetailsQuery,
    useUpdateDetailsMutation,
    useAddMutation,
} = measureApi;

export default measureApi;
