import {
    CalculationFieldValue, IndicatorCalculationMethod,
    IndicatorCalculationMethodField,
    NumericOption,
    ValueWithMetadata,
} from '../../../Store/Indicators/Types';
import { FC, Fragment, ReactNode, useCallback, useContext, useMemo } from 'react';
import { AnyListingSelectorField } from '../../../Common/DetailsForm/AnyListingSelectorField';
import { Col, Input } from 'reactstrap';
import PaginatedCalculationSelector from './PaginatedCalculationSelector';
import { DetailedTableBasicInput } from '../../../Common/DetailsForm/Inputs';
import { DetailedTableEditableRow, InputField, InputFieldProps } from '../../../Common/DetailsForm/BasicInput';
import { DetailsFormAdditionalContext } from '../../../Common/DetailsForm/DetailsForm';

interface Props {
    method: IndicatorCalculationMethod;
    field: IndicatorCalculationMethodField;
    onUpdate: (updatedValue: ValueWithMetadata) => void;
    onAdd: (value: Omit<ValueWithMetadata, 'UniqueIdentifier'>) => void;
    onRemove: (uniqueId: string) => void;
}

interface CalculationFieldProps extends Props {    
    values: ValueWithMetadata[];
}

const typeToName = (props: CalculationFieldValue['MetricTypeType']): string => (
    props === 0 ? 'not a metric type' : (
        props === 1 ? 'a numerator' : (
            props === 2 ? 'a denominator': 'another metric type'
        )
    )
);

const isOption = (opt: number|NumericOption): opt is NumericOption => (opt as NumericOption).Id !== undefined;

type FieldListingSelectorProps = {
    method: IndicatorCalculationMethod;
    field: IndicatorCalculationMethodField;
    value?: Omit<ValueWithMetadata, 'UniqueIdentifier'>;
    onChange: (item?: Omit<ValueWithMetadata, 'UniqueIdentifier'>) => void;
    onDoneEditing?: () => void;
}

const FieldListingSelector: FC<FieldListingSelectorProps> = (props) => {
    const Selector: FC<FieldListingSelectorProps> = useCallback((props: FieldListingSelectorProps) => {
        if (!!props.field.LookupTable || props.field.IsMetricTypeId) {
            return (
                <PaginatedCalculationSelector
                    fieldIdentifier={props.field.Identifier}
                    methodNumber={props.method.CalculationMethod}
                    onChange={newVal => {
                        props.onChange({
                            ...props.value,
                            Value: {
                                IsNegative: false,
                                ...props.value?.Value,
                                Value: newVal?.Id,
                                CalculationMethodFieldId: props.field.Id
                            },
                            Option: newVal ?? undefined
                        });
                        props.onDoneEditing?.();
                    }}
                    value={props.value?.Option}
                />
            );
        }
        return (
            <DetailedTableBasicInput<number>
                value={props.value?.Value.Value}
                onChange={newVal => props.onChange({
                    ...props.value,
                    Value: {
                        IsNegative: false,
                        ...props.value?.Value,
                        Value: newVal??undefined,
                        CalculationMethodFieldId: props.field.Id

                    },
                })}
                onDoneEditing={props.onDoneEditing}
            />
        );

    }, [ props.field.LookupTable, props.field.IsMetricTypeId ]);
    
    
    return <Selector {...props} />;
}

interface NegativeSelectorProps {
    field: IndicatorCalculationMethodField;
    isNegative: boolean;
    onUpdate: (isNegative: boolean) => void;
}

const NegativeSelector: FC<NegativeSelectorProps> = (props) => {
    const { canUpdate } = useContext(DetailsFormAdditionalContext);
    return (
        props.field.AllowNegatives ? (
            <Col xs="auto">
                <Input
                    disabled={!canUpdate}
                    type="select"
                    value={props.isNegative ? '-' : '+'}
                    onChange={e => props.onUpdate(e.currentTarget.value === '-')}
                >
                    <option value="+">+</option>
                    <option value="-">-</option>
                </Input>
            </Col>
        ) : null
    );
}

const CalculationFieldMultipleValues : FC<CalculationFieldProps> = (props) => {
    return (
        <AnyListingSelectorField<Omit<ValueWithMetadata, 'UniqueIdentifier'>>
            label={props.field.Label}
            hoverProps={{ text: props.field.Description }}
            onRemoveItem={idx => props.onRemove(props.values[idx].UniqueIdentifier)}
            listingSelector={innerProps => (
                <FieldListingSelector         
                    method={props.method}    
                    field={props.field}
                    value={innerProps.value}
                    onChange={innerProps.onChange}                     
                />
            )}
            onItemAdded={item => props.onAdd(item)}
            className="calculation-fields-row"
        >
            {props.values.map(p => {
               
                return (
                    <Fragment key={p.UniqueIdentifier}>
                        <NegativeSelector 
                            field={props.field}
                            isNegative={p.Value.IsNegative??false}
                            onUpdate={IsNegative => props.onUpdate({ ...p, Value: { ...p.Value, IsNegative } })}
                        />
                        <Col>
                            {p.Option?.Name ?? p.Value?.Value}
                        </Col>
                    </Fragment>
                );
            })}
        </AnyListingSelectorField>
    );    
}

interface SingleInputFieldValue {
    value?: Omit<ValueWithMetadata, 'UniqueIdentifier'>;
    method: IndicatorCalculationMethod;
    field: IndicatorCalculationMethodField;
}

const CalculationFieldSingleValueInput : InputField<SingleInputFieldValue> = (innerProps) => (
    <>
        <NegativeSelector
            field={innerProps.value!.field}
            isNegative={innerProps.value!.value?.Value?.IsNegative??false}
            onUpdate={IsNegative => innerProps.onChange?.({
                ...innerProps.value!,
                value: {
                    ...innerProps.value!.value!,
                    Value: {
                        ...innerProps.value!.value!.Value,
                        IsNegative,
                    }
                }
            })}
        />
        <Col>
            <FieldListingSelector
                method={innerProps.value!.method}
                field={innerProps.value!.field}
                value={innerProps.value!.value??undefined}
                onChange={newVal => newVal ? innerProps.onChange?.({
                    ...innerProps.value!,
                    value: newVal
                }) : undefined}
                onDoneEditing={innerProps.onDoneEditing}
            />
        </Col>
    </>
);


const CalculationFieldSingleValue : FC<CalculationFieldProps> = (props) => {
    const value = props.values && props.values.length > 0 ? props.values[0] : {
        UniqueIdentifier: crypto.randomUUID(),            
        Value: {
            IsNegative: false,
            CalculationMethodFieldId: props.field.Id
        }
    } as ValueWithMetadata;
    
    const inputValue: SingleInputFieldValue = useMemo(() => ({
        field: props.field,
        method: props.method,
        value: value
    } as SingleInputFieldValue), [ props.field, props.method, value.Value.IsNegative, value.Value.Value, value.Option ])
    
    const formatValue = useCallback((val: SingleInputFieldValue|null|undefined): ReactNode => {
        if (!val?.value) {
            return '';
        }
        if (val.value.Option) {
            return val.value.Option.Name;
        }
        return `${val.value.Value.IsNegative?'-':''}${val.value.Value.Value??''}`;
    }, []);
    
    const changeHandler = useCallback((val: SingleInputFieldValue|null|undefined) => {
        props.onUpdate({
            ...value,
            ...(val?.value??{})
        });
    },[ value ]);
    
    return (
        <DetailedTableEditableRow<SingleInputFieldValue>          
            className="calculation-fields-row align-items-center d-flex align-items-center"
            label={props.field.Label}
            hoverProps={{ text: props.field.Description }}
            value={inputValue}
            formatValue={formatValue}
            inputField={CalculationFieldSingleValueInput}
            onChange={changeHandler}
        />
        
    );
}

const CalculationField : FC<CalculationFieldProps> = (props) => {
    const Editor: FC<CalculationFieldProps> = useCallback((props: CalculationFieldProps) => (
        props.field.AllowMultiple
            ? <CalculationFieldMultipleValues {...props} />
            : <CalculationFieldSingleValue {...props} />
    ), [ props.field.AllowMultiple ]);
    
    return <Editor {...props} />
    
}

export default CalculationField;