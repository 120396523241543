import { Column, RowData } from '@tanstack/react-table';
import { FC, useEffect, useState } from 'react';
import { Col, Input, InputProps, Row } from 'reactstrap';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB } from 'date-fns/locale/en-GB';
import DateRangeFilter from './DateRangeFilter';

registerLocale('enGB', enGB);
setDefaultLocale('enGB');

declare module '@tanstack/react-table' {
    //allows us to define custom properties for our columns
    interface ColumnMeta<TData extends RowData, TValue> {
        filterVariant?: 'text' | 'range' | 'boolean' | 'daterange' | 'enum' | 'daterangewithdiscontinue';
        filterParameters?: any;
    }
}

export interface FilterProps {
    column: Column<any, unknown>;
}


/* See faceted column filters example for min max values functionality */
const RangeFilter: FC<FilterProps> = ({ column }) => (
    <>
        <Col>
            {/* See faceted column filters example for min max values functionality */}
            <DebouncedInput
                type="number"
                value={(column.getFilterValue() as [number, number])?.[0] ?? ''}
                onChange={value =>
                    column.setFilterValue((old: [number, number]) => [value, old?.[1]])
                }
                placeholder={`Min`}
                className="w-24 border shadow rounded"
            />
        </Col>
        <Col>
            <DebouncedInput
                type="number"
                value={(column.getFilterValue() as [number, number])?.[1] ?? ''}
                onChange={value =>
                    column.setFilterValue((old: [number, number]) => [old?.[0], value])
                }
                placeholder={`Max`}
                className="w-24 border shadow rounded"
            />
        </Col>
    </>
);


const TextFilter: FC<FilterProps> = ({ column }) => (
    <Col>
        <DebouncedInput
            className="w-36 border shadow rounded"
            onChange={value => column.setFilterValue(value)}
            placeholder={`Search...`}
            type="text"
            value={(column.getFilterValue() ?? '') as string}
        />
    </Col>
);

const EnumFilter: FC<FilterProps> = ({ column }) => (
    <Col>
        <Input
            type="select"
            className="w-36 border shadow rounded"
            onChange={e => column.setFilterValue(e.currentTarget.value)}
            value={(column.getFilterValue() ?? '') as string}
        >
            <option value="">-- No filter --</option>
            {(column.columnDef.meta?.filterParameters as string[]).map(v => (
                <option value={v}>{v}</option>
            ))}
        </Input>
    </Col>
);

function Filter({ column }: FilterProps) {
    if (!column.getCanFilter()) {
        return null;
    }
    const { filterVariant, filterParameters } = column.columnDef.meta ?? {}
    let filter = <TextFilter column={column}/>;
    if (filterVariant === 'range') {
        filter = <RangeFilter column={column}/>
    } else if (filterVariant === 'enum' && Array.isArray(filterParameters)) {
        filter = <EnumFilter column={column}/>;
    } else if (filterVariant === 'daterange') {
        filter = <DateRangeFilter column={column}/>;
    } else if (filterVariant === 'daterangewithdiscontinue') {
        filter = <DateRangeFilter column={column} withDiscontinuedOption/>;
    }

    return (
        <Row>{filter}</Row>
    );
}

type DebouncedInputProps = {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<InputProps, 'onChange'>;

// A typical debounced input react component
export const DebouncedInput: FC<DebouncedInputProps> = (allProps) => {
    const {
        value: initialValue,
        onChange,
        debounce = 500,
        ...props
    } = allProps;
    const [value, setValue] = useState(initialValue)

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <Input {...props} value={value} onChange={e => setValue(e.target.value)}/>
    )
}

export default Filter;