import { UncontrolledTooltip, UncontrolledTooltipProps } from 'reactstrap';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ButtonWithTooltipProps extends Omit<LinkProps, 'id'> {
    id: string;
    tooltip?: Omit<UncontrolledTooltipProps, 'target'>;
}

const ButtonWithTooltip : FC<ButtonWithTooltipProps> = ({ tooltip = {}, id, ...props }) => (
    <>
        <UncontrolledTooltip target={id} {...tooltip} />
        <Link id={id} {...props} />
    </>
);


export default ButtonWithTooltip;