import { FC, useEffect, useMemo, useState } from 'react';
import {
    areaTypeSelector,
    useGetAllAreaTypesQuery,
    useGetAreaTypeLinksQuery,
    useGetUpdateMethodsListQuery
} from '../../Store/Lists/listApi';
import { DetailedTableRow, DetailedTableRowProps } from '../../Common/DetailsForm/BasicInput';
import { FormikContextType } from 'formik';
import { IndicatorInputMethod } from '../../Store/Lists/Types';
import Select, { SingleValue } from 'react-select';
import { customStyles } from '../../Common/selectStyles';
import { GeoAreaLevel } from '../../Store/GeoArea/Types';
import { PlusLg } from '../../Common/Icons';
import { Badge, Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';
import classNames from 'classnames';
import AreaTypeUpdateMethodTab from './AreaTypeUpdateMethodTab';
import SuggestionsTab from './SuggestionsTab';
import { DatasetApplicableGeoLevel, DatasetFormApplicableGeoLevel, DatasetFormModel } from '../../Store/Dataset/Types';

interface AreaTypeUpdateMethodsProps extends Omit<DetailedTableRowProps, 'children'> {
    context: FormikContextType<DatasetFormModel>;
}


const Index: FC<AreaTypeUpdateMethodsProps> = (props) => {
    const canUpdate: boolean = useSelector<RootState>(state => state.defaultModel.canUpdate) as boolean;
    const updateMethodsResult = useGetUpdateMethodsListQuery();
    const [updateMethod, setUpdateMethod] = useState<SingleValue<IndicatorInputMethod>>();
    const [activeTab, setActiveTab] = useState<number | 'suggestions'>();

    const areasResult = useGetAllAreaTypesQuery(undefined, {
        selectFromResult: result => ({
            ...result,
            nonGroups: areaTypeSelector(result, true, false)
        })
    });
    const areaLinks = useGetAreaTypeLinksQuery();

    const [areaType, setAreaType] = useState<SingleValue<GeoAreaLevel>>(null);
    const addUpdateMethod = async () => {
        if (areaType && updateMethod) {
            await props.context.setFieldValue('ApplicableGeoLevel', [
                ...(props.context.values.ApplicableGeoLevel?.filter(v => v.AreaType.Id !== areaType.Id) ?? []),
                {
                    AreaType: areaType,
                    InputType: updateMethod,
                    AggregatedFromAreaType: null
                } as DatasetFormModel['ApplicableGeoLevel'][number]
            ]);
            setActiveTab(updateMethod.Id);
            setUpdateMethod(null);
            setAreaType(null);
        }
    }


    useEffect(() => {
        if (updateMethodsResult.data && updateMethodsResult.data.length > 0) {
            setUpdateMethod(updateMethodsResult.data[0]);
            setActiveTab(updateMethodsResult.data[0].Id);
        }
    }, [updateMethodsResult.data]);

    const applicableGeoLevel = props.context.values.ApplicableGeoLevel;
    
    const onAdd = async (entry: DatasetApplicableGeoLevel[]) => {
        await props.context.setFieldValue('ApplicableGeoLevel', [
            ...(props.context.values.ApplicableGeoLevel ?? []),
            ...entry.map(e => ({
                AreaType: e.GeoAreaLevel,
                InputType: e.IndicatorInputMethod,
                AggregatedFromAreaType: e.AggregatedFromLevel,
                Discontinued: false
            } as DatasetFormApplicableGeoLevel))
        ]);
    };
    
    const badges = useMemo(() => {
        return props.context.values.ApplicableGeoLevel.reduce<Record<string, number>>((acc, item) => ({
            ...acc,
            [item.InputType.Identifier]: (acc[item.InputType.Identifier]??0)+1
        }), {});   
    }, [ props.context.values.ApplicableGeoLevel ])


    const onRemove = async (remove: DatasetApplicableGeoLevel[]) => {
        const matcher = (a: DatasetFormApplicableGeoLevel, b: DatasetApplicableGeoLevel) => (
            a.AreaType.Identifier !== b.GeoAreaLevel.Identifier && a.AggregatedFromAreaType?.Identifier !== b.AggregatedFromLevel?.Identifier && a.InputType.Identifier !== b.IndicatorInputMethod?.Identifier
        )
        await props.context.setFieldValue('ApplicableGeoLevel', (
            props.context.values.ApplicableGeoLevel!
                .filter(existing => remove.every(r => !matcher(existing, r))))
        );
    };
    return (
        <>
            {canUpdate ? <DetailedTableRow label={props.label} hoverProps={props.hoverProps}>
                <Row>
                    <Col>
                        <Select<GeoAreaLevel>
                            isMulti={false}
                            isLoading={updateMethodsResult.isLoading || updateMethodsResult.isFetching}
                            options={areasResult.nonGroups}
                            value={areaType}
                            placeholder="-- Select --"
                            styles={customStyles}
                            getOptionLabel={opt => opt.Name}
                            getOptionValue={opt => opt.Identifier}
                            onChange={opt => opt ? setAreaType(opt) : null}
                            isOptionDisabled={geoLevel => (applicableGeoLevel?.some(existing => existing.AreaType.Identifier === geoLevel.Identifier)) ?? false}
                        />
                    </Col>
                    <Col>
                        <Select<IndicatorInputMethod>
                            isMulti={false}
                            isLoading={updateMethodsResult.isLoading || updateMethodsResult.isFetching}
                            options={updateMethodsResult.data}
                            value={updateMethod}
                            styles={customStyles}
                            getOptionLabel={opt => opt.Label}
                            getOptionValue={opt => opt.Id.toString()}
                            onChange={opt => opt ? setUpdateMethod(opt) : null}
                        />
                    </Col>
                    <Col xs="auto">
                        <Button disabled={!updateMethod || !areaType} color="link" className="text-success"
                                onClick={addUpdateMethod}>
                            <PlusLg/>
                        </Button>
                    </Col>
                </Row>
            </DetailedTableRow> : null}
            <Row className="align-items-center py-1">
                <Col xs={6} sm={4} md={3} className="font-weight-bold d-flex align-items-center">
                    {' '}
                </Col>

                <Col xs={6} sm={8} md={9}>
                    <Nav tabs>
                        {updateMethodsResult.data?.map(res => (
                            <NavItem key={res.Id}>
                                <NavLink
                                    className={classNames('p-3', { active: activeTab === res.Id })}
                                    onClick={() => setActiveTab(res.Id)}
                                >
                                    {res.Label}
                                    <Badge pill className="px-1 py-1" style={{ position: 'relative', top: '-0.5rem'}}>{badges[res.Identifier]??0}</Badge>
                                </NavLink>
                            </NavItem>
                        )) ?? null}
                        { canUpdate ? <NavItem>
                            <NavLink
                                className={classNames('p-3', { active: activeTab === 'suggestions' })}
                                onClick={() => setActiveTab('suggestions')}
                            >
                                Suggestions
                            </NavLink>
                        </NavItem> : null }
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {updateMethodsResult.data?.map(res => (
                            <TabPane tabId={res.Id} key={res.Id}>
                                <AreaTypeUpdateMethodTab
                                    context={props.context}
                                    canUpdate={canUpdate}
                                    forMethod={res}
                                    areasResult={areasResult.data}
                                    areaLinks={areaLinks.data}
                                />
                            </TabPane>
                        ))}
                        <TabPane tabId="suggestions">
                            <SuggestionsTab
                                isActive={activeTab === 'suggestions'}
                                currentLinks={props.context.values.ApplicableGeoLevel ?? []}
                                canUpdate={canUpdate}
                                onAdd={onAdd}
                                onRemove={onRemove}
                            />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );

}

export default Index;