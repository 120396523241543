import { createBrowserRouter } from 'react-router-dom';
import Queue from './Queue';
import SourceDetails from './Source/Details';
import TaskDetails from './Tasks/Details';
import CreateTask from './Tasks/Create';

import AddSource from './Source/Add';
import SourceList from './Source/List';
import Home from './Tasks/Home';
import SourceTasks from './Source/SourceTasks';
import NotFound from './NotFound';
import CollectionTasks from './Collection/CollectionTasks';
import CollectionList from './Collection/CollectionList';
import CollectionDetails from './Collection/Details';
import AddCollection from './Collection/Add';
import DatasetTasks from './Dataset/DatasetTasks';
import DatasetList from './Dataset/DatasetList';
import DatasetDetails from './Dataset/Details';
import AddDataset from './Dataset/Add';
import MeasureDetails from './Measure/Details';
import AddMeasure from './Measure/Add';
import MeasurementUnitDetails from './MeasurementUnit/Details';
import MetricTypeReportTagTasks from './MetricType/MetricTypeReportTagTasks';
import DimensionDetails from './Dimension/Details';
import LicenceDetails from './Licence/Details';
import ChangeDataset from './MetricType/ChangeDataset';
import DimensionConfiguration from './MetricType/Dimensions';
import MetricTypeDetails from './MetricType/Details';
import AddMetricType from './MetricType/Add';
import Search from './Search';
import { ErrorBoundary } from './ErrorElement';

const error = {
    ErrorBoundary
}


export default createBrowserRouter([
    {
        ...error,
        path: '*',
        element: <NotFound />,
    },
    {
        ...error,
        path: '/',
        element: <Home/>,
    },
    {
        ...error,
        path: 'task/details/:id',
        element: <TaskDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'task/add',
        element: <CreateTask />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'queue',
        element: <Queue/>,
        caseSensitive: false
    }, {
        ...error,
        path: 'source',
        element: <SourceList />,
        caseSensitive: false
    }, {
        ...error,
        path: 'source/:id',
        element: <SourceTasks />,
        caseSensitive: false
    },  {
        ...error,
        path: 'source/details/:id',
        element: <SourceDetails />,
        caseSensitive: false
    }, {
        ...error,
        path: 'source/add',
        element: <AddSource/>,
        caseSensitive: false
    },
    {
        ...error,
        path: 'collection',
        element: <CollectionList />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'collection/add',
        element: <AddCollection />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'collection/:id',
        element: <CollectionTasks />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'collection/details/:id',
        element: <CollectionDetails />,
        caseSensitive: false
    },

    {
        ...error,
        path: 'dataset',
        element: <DatasetList />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'dataset/add',
        element: <AddDataset />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'dataset/:id',
        element: <DatasetTasks />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'dataset/details/:id',
        element: <DatasetDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'measure/details/:id',
        element: <MeasureDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'measure/add',
        element: <AddMeasure />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'measurementUnit/details/:id',
        element: <MeasurementUnitDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'metricType/reportTags/:id',
        element: <MetricTypeReportTagTasks />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'dimension/details/:id',
        element: <DimensionDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'licence/details/:id',
        element: <LicenceDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'metricType/changeDataset/:id',
        element: <ChangeDataset />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'metricType/dimension',
        element: <DimensionConfiguration />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'metricType/details/:id',
        element: <MetricTypeDetails />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'metricType/add',
        element: <AddMetricType />,
        caseSensitive: false
    },
    {
        ...error,
        path: 'search/results',
        element: <Search />,
        caseSensitive: false
    },
]);