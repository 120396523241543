import { createApi } from '@reduxjs/toolkit/query/react'
import { MeasurementUnitApiModel } from './Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const measurementUnitApi = createApi({
    reducerPath: 'measurementUnitApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/measurementUnit/'
    }),
    tagTypes: ['MeasurementUnit'],
    endpoints: (builder) => ({
     
        getDetails: builder.query<MeasurementUnitApiModel, string>({
            query: (measurementUnit) => `details/${measurementUnit}`,
            providesTags: (result?: MeasurementUnitApiModel) => result ? [{ type: 'MeasurementUnit', id: result.Item.Id }] : []
        }),      
    }),
});


export const {
    useGetDetailsQuery,
} = measurementUnitApi;

export default measurementUnitApi;
