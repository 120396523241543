import Page from './Common/Page';

const NotFound = () => {
    return (
        <Page title="Not found">
            <h2>Not found</h2>
            <p>The page you are looking for does not exist. Please check the address and try again.</p>
        </Page>
    )
}

export default NotFound;