import { createApi } from '@reduxjs/toolkit/query/react'
import {
    CollectionIndicatorTableModel,
    DatasetIndicatorTableModel, ReportTagTableModel,
    SourceIndicatorTableModel,
    TaskIndicatorTableModel
} from './Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const taskTableApi = createApi({
    reducerPath: 'taskTable',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/taskTable/'
    }),
    tagTypes: [ 'TaskTable', 'Tasks' ],
    endpoints: (builder) => ({
        getAllTasks: builder.query<TaskIndicatorTableModel, void>({
            query: () => '',
            providesTags: source => source ?[ { type: 'Tasks', id: 'List' } ] : []

        }),
        getLatestIndicatorTableForSource: builder.query<SourceIndicatorTableModel, number>({
            query: (source) => ({
                url: `LatestIndicatorTable`,
                params: {
                    sourceId: source
                }
            }),
            providesTags: source => [ { type: 'TaskTable', id: `source-${source}` } ]
        }),
        getLatestIndicatorTableForDataset: builder.query<DatasetIndicatorTableModel, number>({
            query: (datasetId) => ({
                url: `LatestIndicatorTable`,
                params: {
                    datasetId
                }
            }),
            providesTags: datasetId => [ { type: 'TaskTable', id: `dataset-${datasetId}` } ]
        }),
        getLatestIndicatorTableForCollection: builder.query<CollectionIndicatorTableModel, number>({
            query: (collectionId) => ({
                url: `LatestIndicatorTable`,
                params: {
                    collectionId
                }
            }),
            providesTags: collectionId => [ { type: 'TaskTable', id: `collection-${collectionId}` } ]

        }),
        getLatestIndicatorTableForReportTags: builder.query<ReportTagTableModel, number>({
            query: (tagId) => ({
                url: `ReportTagIndicatorTable`,
                params: {
                    tagId
                }
            }),
            providesTags: tagId => [ { type: 'TaskTable', id: `reportTag-${tagId}` } ]

        }),
    }),
})

export const {
    useGetAllTasksQuery, useGetLatestIndicatorTableForSourceQuery,
    useGetLatestIndicatorTableForCollectionQuery, useGetLatestIndicatorTableForDatasetQuery,
    useGetLatestIndicatorTableForReportTagsQuery
} = taskTableApi;

export default taskTableApi;