import { FC } from 'react';
import { DropdownMenu, DropdownToggle, FormGroup, Input, Label, UncontrolledDropdown, Button } from 'reactstrap';
import { Row, Table } from '@tanstack/react-table';
import { ColumnVisibilityConfiguration, TaskTableScope } from '../Store/TaskTable/Types';
import { CsvFile, Funnel, Gear, IconWithTooltip } from '../Common/Icons';
import { TaskGroup } from '../Store/Tasks/Types';
import { mkConfig, generateCsv, download } from 'export-to-csv'
import { isParentTask } from './GroupTasks';


interface ToolbarHeaderProps {
    identifier?: number;
    table: Table<TaskGroup>;
    columns: ColumnVisibilityConfiguration[];
    scope: TaskTableScope;
}
const keys = {
    'Task identifier': 'UpdateMethod',
    'Source': 'Source',
    'Source identifier': 'Source',
    'Collection': 'Collection',
    'Collection identifier': 'Collection',
    'Dataset': 'Dataset',
    'Dataset identifier': 'Dataset',
    'Metric type': 'MetricType',
    'Metric type identifier': 'MetricType',
    'Area type': 'AreaType',
    'Area type identifier': 'AreaType',
    'Update method': 'UpdateMethod',
    Discontinued: 'Discontinued',
    'Is draft': 'IsDraft',
    'Last time period': 'LastTimePeriod',
    'Last time period identifier': 'LastTimePeriod',
    'Last loaded': 'LastLoaded',
    'Published date':  'PublishedDate',
    'Next time period': 'NextTimePeriod',
    'Next time period identifier': 'NextTimePeriod',
    'Next to be loaded': 'NextToBeLoaded'
} as const;


type TaskCsvRow = {
    [K in keyof typeof keys]?: string | number;
}

const taskToRowData = (taskGroup: TaskGroup, scope: TaskTableScope, columns: ColumnVisibilityConfiguration[]): TaskCsvRow[] => {


    let res: TaskCsvRow[] = [];
    if (isParentTask(taskGroup)) {
        res = taskGroup.subtasks.flatMap(t => taskToRowData(t, scope, columns));
    }
    const fullResult: TaskCsvRow = {
        'Task identifier': taskGroup.Id,
        Source: taskGroup.Collection.Source.Label,
        'Source identifier': taskGroup.Collection.Source.Number,
        Collection: taskGroup.Collection.Label,
        "Collection identifier": taskGroup.Collection.Number,
        Dataset: taskGroup.Dataset?.Label ?? '',
        "Dataset identifier": taskGroup.Dataset?.Number ?? '',
        "Metric type": taskGroup.Indicator?.Label ?? '',
        'Metric type identifier': taskGroup.Indicator?.Number ?? '',
        "Area type": scope === 'Task' ? (taskGroup.GeoAreaLevel?.Name ?? '') : (taskGroup.AreaTypes?.join('|') ?? ''),
        ...(scope !== 'Task' ? { 'Area type identifier': (taskGroup.GeoAreaLevel?.Identifier ?? '') } : {}),
        'Update method': taskGroup.UpdateMethod,
        Discontinued: taskGroup.Discontinued ? 'Yes' : 'No',
        'Is draft': taskGroup.IsDraft ? 'Yes' : 'No',
        'Last time period': taskGroup.LastTimePeriod?.DisplayName ?? '',
        'Last time period identifier': taskGroup.LastTimePeriod?.Identifier ?? '',
        "Last loaded": taskGroup.LastCollected ?? '',
        'Published date': taskGroup.Published??'',
        'Next time period': taskGroup.NextTimePeriod?.DisplayName??'',
        'Next time period identifier': taskGroup.NextTimePeriod?.Identifier,
        'Next to be loaded': taskGroup.NextCollected??''
    };
    
    return [Object.entries(fullResult).reduce<TaskCsvRow>((acc, entry) => ({
      ...acc,
      ...(columns.some(c => (scope === 'Task' && (keys[entry[0] as keyof typeof keys]??undefined) === 'UpdateMethod') || (c.id === keys[entry[0] as keyof typeof keys]??undefined)) ? { [entry[0]]: entry[1] } : {}) 
    }), {})].concat(res);
}

const ToolbarHeader: FC<ToolbarHeaderProps> = (props: ToolbarHeaderProps) => {
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        filename: `${props.scope}${props.identifier ? `-(id - ${props.identifier})` : ''}`,
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });
    const csvMaker = generateCsv(csvConfig);
    const csvDownload = download(csvConfig);

    const visibilityState = props.table.getState().columnVisibility;
    const onVisibilityToggle = (columnId: string, state: boolean) => props.table.getColumn(columnId)?.toggleVisibility(state);
    const exportExcel = () => {
        const rows = props.table.getFilteredRowModel().rows;
        const rowData = rows.flatMap((row) => taskToRowData(row.original, props.scope, props.columns))
        const csv = csvMaker(rowData);
        csvDownload(csv);
    }

    return (
        <span className="d-flex">
            <UncontrolledDropdown>
                <DropdownToggle color="link" className="text-primary"><Funnel /></DropdownToggle>
                <DropdownMenu className="p-2">
                    {props.columns.map(config => config.enableHiding ? (
                        <FormGroup key={config.id} check inline>
                            <Label check>
                            <Input
                                type="checkbox"
                                checked={visibilityState[config.id!]??true}
                                onChange={
                                    e => onVisibilityToggle(config.id!, e.currentTarget.checked)
                                }
                            />
                                {config.header}
                            </Label>
                        </FormGroup>
                    ) : null)}
                </DropdownMenu>
            </UncontrolledDropdown>
            <Button color="link" className="text-primary" onClick={exportExcel}><IconWithTooltip id="download-tasks" tooltip="Download a CSV of this table" icon={CsvFile} /></Button>
        </span>
    );
}

export default ToolbarHeader;