import { ChangeEventHandler, FC } from 'react';
import { Dimension, MetricTypeDimensionsModel } from '../../Store/Dimension/Types';
import { useGetDimensionsQuery } from '../../Store/Lists/listApi';
import { Input } from 'reactstrap';
import { useFormikContext } from 'formik';
import Select, { SingleValue } from 'react-select';
import { defaultStyles } from 'react-select/dist/declarations/src/styles';
import DropdownSelector from '../../Common/DetailsForm/DropdownSelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store/store';

interface DimensionSelectorProps {
    index: number;
}

const DimensionSelector: FC<DimensionSelectorProps> = (props) => {
    const canUpdate = useSelector<RootState>(state => state.defaultModel.canUpdate) as boolean;
    const res = useGetDimensionsQuery(undefined);
    const form = useFormikContext<MetricTypeDimensionsModel>();
    const selected =  props.index < form.values.Dimensions.length ? form.values.Dimensions[props.index] : undefined;
    const isNew = !selected; 
    const handleChange = (newValue: SingleValue<Dimension>) => {
        if (isNew && newValue) {
            form.setFieldValue('Dimensions', [ ...form.values.Dimensions, newValue ]).then();
            return;
        }
        const oldValue = form.values.Dimensions[props.index];
        if (oldValue.Identifier !== newValue?.Identifier) {
            form.setFieldValue('Dimensions', [...form.values.Dimensions.slice(0, props.index), ...(newValue ? [newValue] : []), ...form.values.Dimensions.slice(props.index + 1)]).then();
            form.setFieldValue('Indicators', form.values.Indicators.map(i => ({
                ...i,
                DimensionMembers: i.DimensionMembers.filter(dm => dm.Dimension.Identifier !== oldValue.Identifier)
            }))).then();
        }
        
    }
    
    return (
        <DropdownSelector<Dimension, Dimension[]>
            hookResult={res}
            isOptionDisabled={opt => form.values.Dimensions.some(d => d.Identifier === opt.Identifier)}
            isSelectedOption={opt => opt.Identifier === selected?.Identifier}
            isClearable
            placeholder="None"
            onChange={handleChange}
            getDataFromListing={res => res}
            formatLabel={opt => opt.Label}
            isDisabled={!canUpdate}
        />
    );
    
};

export default DimensionSelector;