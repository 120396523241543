import { createApi } from '@reduxjs/toolkit/query/react'
import { Task, TaskJsonModel, TaskRefreshResult, User } from './Types';
import taskTableApi from '../TaskTable/taskTableApi';
import { AddResult, UpdateResult } from '../Types';
import fetchBaseQuery from '../baseQuery';


// Define a service using a base URL and expected endpoints
export const tasksApi = createApi({
    tagTypes: [ 'Tasks', 'TaskLoader' ],
    reducerPath: 'tasksApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/tasks/'
    }),
    endpoints: (builder) => ({
        getTaskDetails: builder.query<{ Task: Task; }, number>({
            query: (id: number) => `details/${id}`,
            providesTags: result => result ? [{type: 'Tasks', id: result.Task.Id}] : []
        }),
        getTaskLastLoadedBy: builder.query<User, number>({
            query: (id: number) => `loadedBy/${id}`,
            providesTags: (result,error, arg) => result ? [{type: 'TaskLoader', id: arg}] : []
        }),
        refreshTask: builder.mutation<TaskRefreshResult, number>({
            query: (id: number) => `refresh/${id}`,
            invalidatesTags: result => result ? [{ type: 'Tasks', id: result.taskId  }] : [],
            async onQueryStarted(arg, api): Promise<void> {
                try {
                    const { data } = await api.queryFulfilled;
                    api.dispatch(
                    taskTableApi.util.updateQueryData('getAllTasks', undefined, draft => {
                        draft.tasks = draft.tasks.map(t => t.Id === data.taskId ? {
                            ...t,
                            Published: data.published,
                            LastCollected: data.lastCollected,
                            LastTimePeriod: t.LastTimePeriod ? {
                                ...t.LastTimePeriod,
                                DisplayName: data.lastTimePeriod
                            } : null,
                            NextCollected: data.nextCollected,
                            NextTimePeriod: {
                                ...t.NextTimePeriod,
                                DisplayName: data.nextTimePeriod
                            }
                        } : t)
                    }));
                } catch {}
            }
        }),
        createTask: builder.mutation<AddResult, Omit<TaskJsonModel, 'Id'>>({
            query: payload => ({
                url: 'create',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: (result, error, param) => result ? [{type: 'Tasks', id: 'List' }] : []

        }),
        updateTask: builder.mutation<UpdateResult, TaskJsonModel>({
            query: payload => ({
                url: 'update',
                method: 'POST',
                body: payload
            }),
            invalidatesTags: (result, error, task) => result ? [{ type: 'Tasks', id: task.Id }] : []
        }),
    }),

})

export const {
    useGetTaskDetailsQuery,
    useRefreshTaskMutation,
    useCreateTaskMutation,
    useUpdateTaskMutation,
    useGetTaskLastLoadedByQuery
} = tasksApi;

export default tasksApi;