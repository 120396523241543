import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { UpdateResult, ValidationErrorResult } from './Store/Types';
import { toast } from 'react-toastify';
import { CollectionAddModel } from './Store/Collection/Types';
import { useCallback } from 'react';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import type { MaybePromise } from '@reduxjs/toolkit/src/query/tsHelpers';
import { useNavigate } from 'react-router';
import { MetricTypeFilterModel } from './Store/Indicators/Types';

export const resourcesLink =  (path: string, base?: string) => {
    return (new URL(path, base??'https://resources.esd.org.uk')).toString();
}

export const queryable = (id: string): string => {
    let newId = id;
    if (!id.match(/^[A-Za-z]/)) {
        newId = `id-${newId}`;
    }
    return newId.replace(/[^A-Za-z0-9-_]/g, '_');
}

export const formatErrorMessage = (error: Error|FetchBaseQueryError|string|unknown, defaultMessage = 'An error occurred') => {
    if (typeof error === 'string') {
        return error;
    }
    if ((error as FetchBaseQueryError).status) {
        if ((error as FetchBaseQueryError).status === 'TIMEOUT_ERROR') {
            return 'Request timed out. Please try again later.';
        } 
        if ([ 400, 404 ].includes((error as FetchBaseQueryError).status as number)) {            
            return ((error as FetchBaseQueryError).data as ValidationErrorResult).error;
        }
    }
    return defaultMessage;
}

export const downloadString = (content: string, type: string, filename: string) => {
    const blob = new Blob([content], { type: type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export const ucfirst = (content: string): string => {
    return content.charAt(0).toUpperCase() + content.slice(1);
}