import { FC } from 'react';
import { Breadcrumb } from 'reactstrap';
import { Link } from 'react-router-dom';

export interface BreadcrumbEntry {
    name: string;
    url: string;
    active?: boolean;
}

export interface ActiveBreadcrumbEntry {
    name: string;
    url?: string;
    active: true;
}


const BreadcrumbTrail: FC<{ trail: (BreadcrumbEntry|ActiveBreadcrumbEntry)[]; isLoading?: boolean }>  = ({ trail, isLoading }) => {
    return (
        <Breadcrumb id="page-breadcrumb" className={isLoading ? 'loading' : undefined}>
            { trail.map(item => (
                item.url ? <Link key={item.url} to={item.url} className={`breadcrumb-item${item.active?' active':''}`}>{item.name}</Link> : <span key={item.url} className={`breadcrumb-item${item.active?' active':''}`}>{item.name}</span>
            ))}
        </Breadcrumb>
    );
}

export default BreadcrumbTrail;